import React from 'react';
import FormEntryRadio from '../FormEntryRadio/FormEntryRadio';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: 5,
  },

  labelContent: {
    margin: '12px 0px 8px 0px',
    fontWeight: 'bold',
    color: '#505050',
  },

  form: {
    display: 'flex',
    alignItems: 'center',
    margin: '25px 0px 21px 0px',
  },
}));

const ChooseOption = ({
  title,
  name,
  onChange,
  toCheckData,
  firstOptionId,
  firstOptionValue,
  firstOptionLabel,
  secondOptionId,
  secondOptionValue,
  secondOptionLabel,
  disabled,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <label>
        <p className={classes.labelContent}>{title}</p>
      </label>
      <form className={classes.form}>
        <FormEntryRadio
          id={firstOptionId}
          name={name}
          checked={toCheckData === firstOptionValue}
          onChange={onChange}
          value={firstOptionValue}
          label={firstOptionLabel}
          disabled={disabled}
        />

        <FormEntryRadio
          id={secondOptionId}
          name={name}
          checked={toCheckData === secondOptionValue}
          onChange={onChange}
          value={secondOptionValue}
          label={secondOptionLabel}
          disabled={disabled}
          marginLeft={25}
        />
      </form>
    </div>
  );
}

export default ChooseOption;