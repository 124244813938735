import styled from "styled-components";

const ModalContainer = styled.div`
  width: 310px;
  top: 350px;
  left: 0px;
  position: absolute;
  margin: auto;
  background-color: #f2f8ff;
  border-radius: 10px;
  z-index: 2000;
`;

export {
  ModalContainer,
};