import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import {
  Container,
  Title,
  Body,
  FaqContainer,
  FaqTitle,
  FaqContent,
} from "./Components.js";
import SearchInput from "../../components/SearchInput/SearchInput";
import { Divider } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const Faq = (props) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation('faqsPage');

  const handleChange = (event) => {
    setSearch(event.target.value)
  }

  const renderFaqs = () => {
    const faqs = [];
    let index = 1;
    let finish = 18;

    while (index <= finish) {
      faqs.push(
        <Link
          to={`/secure/faq/question/${index}`}
          style={{ textDecoration: 'none' }}
          key={t(`QUESTION_${index}`)}
        >
          <FaqContainer>
            <FaqTitle>{t(`QUESTION_${index}`)}</FaqTitle>
            <FaqContent>{t(`ANSWER_${index}`)}</FaqContent>
            {
              index < finish &&
              <Divider />
            }
          </FaqContainer>
        </Link>
      )
      index++;
    }

    return faqs
  }

  const faqsToRender = renderFaqs();

  const faqsFiltered = useMemo(() => {
    const lowerSearch = search.toLowerCase();
    return faqsToRender
      .filter((faq) => faq.key.toLowerCase().includes(lowerSearch));
  }, [faqsToRender, search]);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('TITLE')}</Title>
      <SearchInput
        onChange={handleChange}
        value={search}
        placeholder={t('SEARCH_PLACEHOLDER')}
        endIcon
      />
      <Body>
        {
          faqsFiltered
        }
      </Body>
    </Container>
  );
}

export default Faq;
