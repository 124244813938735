import React, { useState } from 'react';
import moment from 'moment-timezone';
import {
  Container,
  Content,
  Text,
  Button,
  Img
} from './Components';
import { Box } from '@material-ui/core';
import Code from '../Code/Code';
import Timer from '../Timer/Timer';
import { request } from '../../utils/request';
import { getUser } from '../../utils/user';
import {
  getAccessCode,
  isAccessCodeValid,
  setAccessCode,
  removeAccessCode
} from '../../utils/accessCode';
import Dialog from "../Dialog/Dialog";
import { useIdleTimer } from 'react-idle-timer';
import QRCode from 'qrcode';

const calculateFinishMoment = ({ createdAt, expireAfterSeconds }) => {
  const finishMoment = moment.tz(createdAt, 'UTC').add(expireAfterSeconds, 'seconds');
  return finishMoment;
}

const calculateRemainingTime = (finishMoment) => {
  const now = moment.utc();
  const remainingTime = finishMoment.diff(now, 'seconds');
  return remainingTime > 0 ? remainingTime : 0;
}

const CodeContainer = ({
  documentMetadata,
  explanatoryTextOffCode,
  explanatoryTextOnCode,
  generateCodeText,
  terminateCodeText,
  btnColor,
  onCodeTextColor,
  marginTop,
  translations,
}) => {

  // Perform initial calculation for stored code
  const user = getUser();
  const storedCodeDetails = getAccessCode();
  const isStoredCodeValid = isAccessCodeValid(storedCodeDetails)
  const initialCodeDetails = isStoredCodeValid ? storedCodeDetails : {};
  const finishMoment = isStoredCodeValid ? calculateFinishMoment(storedCodeDetails) : null;
  const remainingTime = isStoredCodeValid ? calculateRemainingTime(finishMoment) : null;

  //QRCode
  const [qrCodeUrl, setQrCodeUrl] = useState(undefined);

  //Timer of the code
  const [accessCodeDetails, setNewCodeDetails] = useState(initialCodeDetails);
  const [expireMoment, setExpireMoment] = useState(finishMoment)
  const [time, setTime] = useState(remainingTime);
  const [initTimer, setInitTimer] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  // Timer to check if user is idle
  const [idleTimeout, setIdleTimeout] = useState(isStoredCodeValid ? 1 * 60 * 1000 : 100 * 60 * 1000);
  const [showDialog, setShowDialog] = useState(false);

  const handleOnAction = (e) => {
    //reset idle timer
    showDialog && window.location.reload(false)
    reset();
  }

  const handleOnIdle = event => {
    setShowDialog(true)
  }

  const { reset } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  })

  const generateQRCode = (data) => {
    QRCode.toDataURL(data, { version: 5 }, (err, url) => {
      if (err) {
        console.error(err);
        return;
      }

      setQrCodeUrl(url);
    })
  };

  const handleCode = async () => {
    try {
      setIsLoading(true)
      if (accessCodeDetails.code === undefined) {
        const { data: newCode } = await request.post('/client-app/generate-code', { userId: user._id, documentMetadata });

        generateQRCode(newCode.code);
        setAccessCode(newCode);
        const expireMoment = calculateFinishMoment(newCode);
        const remainingTime = calculateRemainingTime(expireMoment)
        setExpireMoment(expireMoment)
        setTime(remainingTime);
        setInitTimer(true)
        setNewCodeDetails(newCode);
        setIdleTimeout(1 * 60 * 1000)
      } else {
        await request.delete(`/client-app/delete-code/${accessCodeDetails._id}`);
        setExpireMoment(null)
        removeAccessCode()
        setTime(null);
        setInitTimer(false)
        setNewCodeDetails({});
        setQrCodeUrl(undefined);
        setIdleTimeout(100 * 60 * 1000)
      }
      setIsLoading(false)
    } catch (e) {
      setExpireMoment(null)
      removeAccessCode();
      setTime(null);
      setInitTimer(false)
      setNewCodeDetails({});
      setIsLoading(false);
      setIdleTimeout(100 * 60 * 1000)
    }
  }

  const handleTime = (initTimer, time) => {
    if (time < 0) {
      setTime(0);
      return;
    };
    if (!initTimer) {
      setTime(null);
      return;
    };
    setTime(time)
  }

  return (
    <Container style={{ marginTop: marginTop ? marginTop : 20 }}>
      <Content>

        {qrCodeUrl && (
          <Box mx="auto" width={120} mb="15px">
            <img
              width={120}
              height={120}
              src={qrCodeUrl}
              alt="QRCode"
            />
          </Box>
        )}

        <Code code={accessCodeDetails.code} onCodeColor={onCodeTextColor} />
        {
          !accessCodeDetails.code ?
            <Text>
              {explanatoryTextOffCode}
            </Text>
            :
            <Text style={{ color: onCodeTextColor ? onCodeTextColor : '#6847D8' }}>
              {explanatoryTextOnCode}
            </Text>
        }
        <Timer
          time={time}
          expireMoment={expireMoment}
          getRemainingTime={calculateRemainingTime}
          setTime={(time) => handleTime(initTimer, time)}
          init={initTimer}
          translations={translations}
        />
        <Button
          onClick={handleCode}
          disabled={isLoading}
          mainColor={btnColor}
        >
          {!accessCodeDetails.code ?
            generateCodeText :
            terminateCodeText}
        </Button>
        <Dialog open={showDialog} hideClose onClickModal={() => {
          window.location.reload(false)
        }}>
          <Img src={"/assets/sleeping.jpg"} />
        </Dialog>
      </Content>
    </Container>
  )

}

export default CodeContainer;