import React from 'react';
import {
  Backdrop,
  Checkbox,
  Fade,
  FormControlLabel,
  Modal,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import { Button } from './Components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },


  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },


  modalHeader: {
    textAlign: "center",
    width: 290,
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
    color: '#00A859',
  },

  tocModalContent: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },

  tocModalFormControlLabel: {
    marginBottom: 20,
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
    },
  },

  modalButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },

  modalBackButton: {
    backgroundColor: '#2D80C6',
    color: '#FFFFFF',
    transition: '.2s',
    '&:hover': {
      filter: 'brightness(.9)',
    }
  },
}));

export const TOCModal = ({
  id,
  open,
  onClick,
  onChange,
  checked,
  content,
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby={`${id}-modal-title`}
      aria-describedby={`${id}-modal-description`}
      className={classes.modal}
      open={open}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.modalPaper}>
          <Typography
            className={classes.modalHeader}
            variant='h6'
          >
            {content.title}
          </Typography>
          <Typography className={classes.tocModalContent}>
            {content.description}
          </Typography>
          <FormControlLabel
            className={classes.tocModalFormControlLabel}
            control={
              <Checkbox
                checked={checked}
                onChange={onChange}
                name="toc-checkbox"
              />
            }
            label={content.checkboxLabel}
          />
          <Box className={classes.modalButtonContainer}>
            <Button
              className={classes.modalBackButton}
              onClick={() => {
                onClick();
                window.location.replace("/secure");
              }}
            >
              {content.backButtonTitle}
            </Button>
            <Button
              style={{
                color: "#FFFFFF",
                backgroundColor:
                  !checked
                    ? "#8D8D8D"
                    : "#FF0066",
                cursor:
                  !checked
                    ? 'not-allowed'
                    : 'pointer'
              }}
              disabled={!checked}
              onClick={onClick}
            >
              {content.confirmButtonTitle}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
};