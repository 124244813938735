import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 5,
  },

  label: {
    cursor: 'pointer',
  },

  labelContent: {
    margin: '12px 0px 8px 0px',
    fontWeight: 'bold',
    color: '#505050',
  },

  textarea: {
    width: '100%',
    minHeight: '160px',
    color: '#505050',
    border: '0px',
    padding: '8px',
    borderRadius: '5px',
    resize: 'none',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0px 10px 0px rgb(16 36 94 / 7%)',
  },
}));

const FormEntryTextarea = ({
  id,
  label,
  value,
  onChange,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <label htmlFor={id} className={classes.label}>
        <p className={classes.labelContent}>{label}</p>
      </label>
      <textarea
        id={id}
        onChange={onChange}
        value={value}
        type="text"
        disabled={disabled}
        className={classes.textarea}
      />
    </div>
  );
}

export default FormEntryTextarea;