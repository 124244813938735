import React, {
  useEffect,
  useState,
} from "react";
import { useTranslation } from 'react-i18next';
import { ImageContainer } from './Components';
import Header from "../../components/Header/Header";
import { ResetLoginDataForm } from "../../components/ResetLoginDataForm";
import { useLocation } from "react-router-dom";
import JivoButton from "../../components/JivoButton";

const ResetLoginData = ({ setAuth }) => {
  const { t } = useTranslation('resetLoginDataPage');
  const location = useLocation();
  const [showJivoButton, setShowJivoButton] = useState(true);

  const handleHideJivoButton = () => {
    setShowJivoButton(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <ResetLoginDataForm
        setAuth={setAuth}
        temporaryJwt={location.state?.temporaryJwt}
      />
      <ImageContainer>
        <img
          src="/assets/logo-passe.svg"
          alt="logo passe verde"
          width="200"
        />
      </ImageContainer>

      {showJivoButton && <JivoButton onClickCloseButton={handleHideJivoButton} />}
    </>
  );
}

export default ResetLoginData;
