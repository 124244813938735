import React from 'react';
import {
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 5,
  },

  label: {
    cursor: 'pointer',
  },

  labelContent: {
    margin: '12px 0px 8px 0px',
    fontWeight: 'bold',
    color: '#505050',
  },

  formControl: {
    margin: theme.spacing(2),
    marginTop: 0
  },

  subtext: {
    color: "grey",
    textAlign: "center",
    fontSize: "12px"
  }
}));

const FormEntryCheckbox = ({
  id,
  label,
  subtext,
  options,
  selectedOptions,
  onChange,
  disabled,
  translations,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <label htmlFor={id} className={classes.label}>
        <p className={classes.labelContent}>{label}</p>
      </label>
      <Typography className={classes.subtext}>
        {subtext}
      </Typography>
      <FormControl className={classes.formControl} id={id}>
        <FormGroup>
          {
            translations ?
              options.map(option => (
                <FormControlLabel
                  key={option._id}
                  control={
                    <Checkbox
                      onChange={onChange}
                      name={option._id}
                      checked={selectedOptions.includes(option._id)}
                      disabled={disabled}
                    />
                  }
                  label={translations(option.name)}
                />
              ))
              :
              options.map(option => (
                <FormControlLabel
                  key={option._id}
                  control={
                    <Checkbox
                      onChange={onChange}
                      name={option._id}
                      checked={selectedOptions.includes(option._id)}
                      disabled={disabled}
                    />}
                  label={option.name}
                />
              ))
          }
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default FormEntryCheckbox;