import styled from 'styled-components';
import { Link } from "react-router-dom";

const MenuHeader = styled.div`
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

export {
  MenuHeader,
  StyledLink,
}