import React, { useEffect, useState } from "react";
import SelectLangs from "../../components/SelectLangs/SelectLangs";
import { makeStyles } from "@material-ui/core/styles";
import { Container, AvatarBorder } from "./Components";
import SideMenu from "../SideMenu/SideMenu";
import { Avatar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  topContainer: {
    paddingTop: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },

  bottomContainer: {
    padding: 5,
    marginTop: 10,
    width: '90%',
    display: 'flex',
    alignItems: 'center',
  },

  profilePhotoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: '50%',
  },

  textContainer: {
    paddingLeft: 15,
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },

  title: {
    fontSize: 23,
    color: '#FFF',
    fontWeight: 'bold'
  },

  subtitle: {
    color: '#FFF'
  }
}));

const Header = ({ backgroundStyle, user, isHome, greeting, subtitle }) => {
  const [firstName, setFirstName] = useState(null);
  const [background, setBackground] = useState('/assets/rectangle-home.svg');
  const classes = useStyles();

  useEffect(() => {
    if (backgroundStyle) {
      if (backgroundStyle === 'BLUE') {
        setBackground('/assets/rectangle-blue.svg')
      }
      if (backgroundStyle === 'GREEN') {
        setBackground('/assets/rectangle-green.svg')
      }
      if (backgroundStyle === 'PURPLE') {
        setBackground('/assets/rectangle-purple.svg')
      }
      if (backgroundStyle === 'ORANGE') {
        setBackground('/assets/rectangle-orange.svg')
      }
      if (backgroundStyle === 'PINK') {
        setBackground('/assets/rectangle-pink.svg')
      }
    }
  }, [backgroundStyle])


  useEffect(() => {
    if (isHome && user) {
      setFirstName(user.name.split(' ')[0])
    }
  }, [isHome, user])

  return (
    <Container image={background} home={isHome}>
      <div className={classes.topContainer}>
        <SideMenu />
        <Link to="/secure">
          <img
            src={isHome ? '/assets/logo-immunie2.svg' : '/assets/logo-white-immunie.svg'}
            alt="Logo Immunie"
            height="40"
          />
        </Link>
        <SelectLangs notAbsoluteContainer whiteColor />
      </div>
      {isHome && <div className={classes.bottomContainer}>
        {
          user?.approvedSelfie ?
            <AvatarBorder>
              <Avatar
                src={user?.selfieFile?.url}
                alt="Profile"
                style={{ width: 49.594, height: 49.594 }}
              />
            </AvatarBorder>
            :
            <Avatar
              src={"/assets/profile-default.svg"}
              alt="Profile"
              style={{ width: 49.594, height: 49.594 }}
            />
        }
        {user ?
          <div className={classes.textContainer}>
            <Typography variant="h3" className={classes.title}>
              {greeting}, {firstName}!
            </Typography>
            <Typography className={classes.subtitle}>
              {subtitle}
            </Typography>
          </div>
          :
          <div className={classes.textContainer}>
            <Typography variant="h3" className={classes.title}>{greeting}</Typography>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </div>
        }

      </div>}
    </Container>
  );
};

export default Header;
