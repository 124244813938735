import React, { useEffect } from "react";
import {
  ImageContainer
} from "./Components";
import Header from "../../components/Header/Header";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";
import { useTranslation } from 'react-i18next';

const Index = ({ setAuth }) => {
  const { t } = useTranslation('confirmPasswordPage');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <NewPasswordForm setAuth={setAuth} />
      <ImageContainer>
        <img
          src="/assets/logo-passe.svg"
          alt="logo passe verde"
          width="200"
        />
      </ImageContainer>
    </div>
  );
}

export default Index;
