import React, { useEffect, useState } from "react";
import { getUser } from "../../utils/user";
import { setHasPasseVerde } from "../../utils/hasPasseVerde";
import { request } from "../../utils/request";
import { dateConfig } from "../../utils/dateConfig";
import {
  CircularProgress,
  Typography,
  makeStyles,
  LinearProgress,
  Modal,
  Fade,
  Backdrop,
  Avatar,
} from "@material-ui/core";
import {
  Container,
  Title,
  FormContainer,
  AccordionContainer,
  CenterColumn,
  ButtonContainer,
  MainButton,
  LoadingContainer,
  AvatarBorder,
} from "./Components";
import Header from "../../components/Header/Header";
import Dialog from "../../components/Dialog/Dialog";
import AccordionSummary from "../../components/AccordionSummary/AccordionSummary";
import AccordionDetails from "../../components/AccordionDetails/AccordionDetails";
import FormEntryText from "../../components/FormEntryText/FormEntryText";
import FormEntrySelect from "../../components/FormEntrySelect/FormEntrySelect";
import FormEntryDate from "../../components/FormEntryDate/FormEntryDate";
import { FormEntryOptions } from "../../components/FormEntryOptions";
import { RedirectButton } from "../../components/RedirectButton";
import DatePicker from "react-mobile-datepicker";
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE_OPTIONS, GENDER_OPTIONS } from "./data";

import moment from "moment-timezone";
import axios from "axios";

const useStyles = makeStyles(() => ({
  userProfileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  avatar: {
    width: 130,
    height: 130,
  },

  userName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#808080',
    marginTop: 10,
  },

  userInfo: {
    fontSize: 14,
    color: '#808080',
  },

  datepickerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  datepicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    '& .datepicker': {
      position: 'static',
      borderRadius: 10,
      padding: 15,
    },
    '& .datepicker.default .datepicker-header': {
      fontSize: 20
    },
    '& .datepicker.default .datepicker-navbar-btn': {
      fontSize: 18
    }
  },
}));

const Profile = () => {
  const { t } = useTranslation(['profilePage', 'common']);
  const classes = useStyles();
  const user = getUser(); // user with documentType == CNS => morador de noronha

  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [expandedPanel, setExpandedPanel] = useState(undefined);
  const [isPasseVerdeUser, setIsPasseVerdeUser] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleClick = (panel) => {
    if (panel === expandedPanel) {
      setExpandedPanel(undefined);
    } else {
      setExpandedPanel(panel);
    }
  };

  const updateData = (field, value) => {
    if (isPasseVerdeUser && field !== "name") {
      setFeedback(t('NO_EDIT_WARNING'));
      setDialogOpen(true);
      return;
    }

    const newData = { ...data };
    newData[field] = value;
    setData(newData);
  };

  const confirmHandler = async () => {
    try {
      if (data.birthday === "") {
        setFeedback(t('BIRTHDATE_NOT_VALID'));
        setDialogOpen(true);
        return;
      }

      setIsSubmitting(true);

      const dataFormatted = { ...data };
      dataFormatted['birthday'] = moment(dataFormatted.birthday).format("DD/MM/YYYY");

      await request.post(`/client-app/update-profile`, dataFormatted);

      setFeedback(t('SUBMIT_SUCCESS_RESPONSE'));
      setDialogOpen(true);

    } catch (error) {
      console.log(`[Profile getUserProfile Error]: ${error}`);
      let message = t('SUBMIT_ERROR_RESPONSE');

      const { isAxiosError } = error;
      if (isAxiosError) {
        message = error.response.data.message;
      }

      setFeedback(message);
      setDialogOpen(true);

    } finally {
      setIsSubmitting(false);
    }
  };

  const order = (a, b) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();

    return x > y ? 1
      : x < y ? -1
        : 0;
  }

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const { data: result } = await request.get(
          `/client-app/profile?id=${user.id}`
        );

        if (user?.travelHistory && user.travelHistory.length > 0) {
          setIsPasseVerdeUser(true);
          setHasPasseVerde(true);
        } else {
          setIsPasseVerdeUser(false);
          setHasPasseVerde(false);
        }

        setData({
          id: user.id,
          username: result.username ? result.username : "",
          name: result.name ? result.name : "",
          surname: result.surname ? result.surname : "",
          email: result.email ? result.email : "",
          mobile: result.mobile ? result.mobile : "",
          telephone: result.telephone ? result.telephone : "",
          country: result.country ? result.country._id : null,
          nationality: result.nationality ? result.nationality : "",
          gender: result.gender ? result.gender : "",
          prefix: result.prefix ? result.prefix : "",
          url: result.url ? result.url : "",
          birthday: result.birthday ? moment(result.birthday, 'DD/MM/YYYY')._d : "",
          documentType: result.documentType ? result.documentType : "",
          documentNumber: result.documentNumber ? result.documentNumber : "",
          documentIssuedBy: result.documentIssuedBy
            ? result.documentIssuedBy
            : "",
          documentFile: result.documentFile || "",
          vaccinationCard: result.vaccinationCard || "",
          "address.addressLineOne":
            (result.address && result.address.addressLineOne) || "",
          "address.addressLineTwo":
            (result.address && result.address.addressLineTwo) || "",
          "address.borough": (result.address && result.address.borough) || "",
          "address.town": (result.address && result.address.town) || "",
          "address.state": (result.address && result.address.state) || "",
          "address.postalCode":
            (result.address && result.address.postalCode) || "",
        });
      } catch (error) {
        console.log(`[Profile getUserProfile Error]: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    const getCountriesList = async () => {
      try {
        const { data: result } = await axios.get(
          `${process.env.REACT_APP_API_URL}/find-countries-list`
        );

        const orderedCountries = result.sort(order);
        setCountriesList(orderedCountries);
      } catch (error) {
        console.log(`[Profile getCountriesList Error]: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    if (data === null) {
      setLoading(true);
      getCountriesList();
      getUserProfile();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('TITLE')}</Title>

      {loading ? (
        <CenterColumn style={{ marginTop: 50 }}>
          <CircularProgress />
        </CenterColumn>
      ) : (
        <>
          <CenterColumn style={{ marginBottom: 20 }}>
            <div className={classes.userProfileContainer}>
              {
                user?.approvedSelfie ?
                  <AvatarBorder>
                    <Avatar
                      src={user?.selfieFile?.url}
                      alt="Avatar"
                      className={classes.avatar}
                    />
                  </AvatarBorder>
                  :
                  <Avatar
                    src={"/assets/profile-default.svg"}
                    alt="Avatar"
                    className={classes.avatar}
                  />
              }
              <div>
                <Typography className={classes.userName}>
                  {user?.name}
                </Typography>
                <Typography className={classes.userInfo}>
                  {t('SUBTITLE')}
                </Typography>
              </div>
            </div>
          </CenterColumn>
          <FormContainer>
            <AccordionContainer
              id="myInformationPanel"
              marginBottom="20px"
            >
              <AccordionSummary
                id="panel1a-header"
                onClick={() => handleClick('panel1')}
                title={t('GENERAL_INFO')}
                expandedPanel={expandedPanel}
                panel='panel1'
              />
              <AccordionDetails
                id="panel1a-content"
                expandedPanel={expandedPanel}
                panel='panel1'
                height={1640}
                transitionDuration="1s"
              >
                <FormEntryText
                  id="name"
                  label={t('NAME')}
                  onChange={(event) => updateData("name", event.target.value)}
                  value={data?.name}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="surname"
                  label={t('SURNAME')}
                  onChange={(event) => updateData("surname", event.target.value)}
                  value={data?.surname}
                  disabled={isSubmitting}
                />

                <FormEntryDate
                  id="birthday"
                  label={t('BIRTHDATE')}
                  value={data?.birthday}
                  onClick={() => setOpenDatePicker(true)}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="mobile"
                  label={t('MOBILE')}
                  onChange={(event) => updateData("mobile", event.target.value)}
                  value={data?.mobile}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="telephone"
                  label={t('TELEPHONE')}
                  onChange={(event) => updateData("telephone", event.target.value)}
                  value={data?.telephone}
                  disabled={isSubmitting}
                />

                <FormEntrySelect
                  id="nationality"
                  onChange={(event) => updateData("nationality", event.target.value)}
                  label={t('NATIONALITY')}
                  value={data?.nationality}
                  disabled={isSubmitting}
                >
                  <option value="">
                    {t('SELECT_AN_OPTION', { ns: 'common' })}
                  </option>
                  {countriesList?.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </FormEntrySelect>

                <FormEntryOptions
                  name="gender"
                  label={t('GENDER')}
                  onChange={(event) => updateData("gender", event.target.value)}
                  value={data?.gender}
                  disabled={isSubmitting}
                  options={GENDER_OPTIONS.map(option => ({
                    label: t(option.label, { ns: 'common' }),
                    value: option.value
                  }))}
                />

                <FormEntrySelect
                  id="documentType"
                  label={t('DOCUMENT_TYPE')}
                  onChange={(event) => updateData("documentType", event.target.value)}
                  value={data?.documentType}
                  disabled={isSubmitting}
                >
                  <option value="">
                    {t('SELECT_AN_OPTION', { ns: 'common' })}
                  </option>
                  {DOCUMENT_TYPE_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {t(option.name, { ns: 'common' })}
                    </option>
                  ))}
                </FormEntrySelect>

                <FormEntryText
                  id="document-number"
                  label={t('DOCUMENT_NUMBER')}
                  onChange={(event) => updateData("documentNumber", event.target.value)}
                  value={data?.documentNumber}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="document-issued"
                  label={t('DOCUMENT_ISSUED_BY')}
                  onChange={(event) => updateData("documentIssuedBy", event.target.value)}
                  value={data?.documentIssuedBy}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="address-line-one"
                  label={t('ADDRESS_LINE_ONE')}
                  onChange={(event) => updateData("address.addressLineOne", event.target.value)}
                  value={data?.["address.addressLineOne"] || ""}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="address-line-two"
                  label={t('ADDRESS_LINE_TWO')}
                  onChange={(event) => updateData("address.addressLineTwo", event.target.value)}
                  value={data?.["address.addressLineTwo"] || ""}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="borough"
                  label={t('ADDRESS_BOROUGH')}
                  onChange={(event) => updateData("address.borough", event.target.value)}
                  value={data?.["address.borough"] || ""}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="town"
                  label={t('ADDRESS_TOWN')}
                  onChange={(event) => updateData("address.town", event.target.value)}
                  value={data?.["address.town"] || ""}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="state"
                  label={t('ADDRESS_STATE')}
                  onChange={(event) => updateData("address.state", event.target.value)}
                  value={data?.["address.state"] || ""}
                  disabled={isSubmitting}
                />

                <FormEntryText
                  id="postalCode"
                  label={t('ADDRESS_POSTAL_CODE')}
                  onChange={(event) => updateData("address.postalCode", event.target.value)}
                  value={data?.["address.postalCode"] || ""}
                  disabled={isSubmitting}
                />

                <ButtonContainer marginTop="20px">
                  {
                    isSubmitting ?
                      <LoadingContainer>
                        <LinearProgress />
                      </LoadingContainer>
                      :
                      <MainButton onClick={confirmHandler} disabled={isSubmitting}>
                        {t('SUBMIT_BUTTON')}
                      </MainButton>
                  }
                </ButtonContainer>
              </AccordionDetails>
            </AccordionContainer>

            <RedirectButton
              disabled={isSubmitting}
              backgroundColor={isSubmitting ? "#E7E4E4" : "#FFF"}
              title={t('PRIVACY_AND_SECURITY')}
              href="/secure/privacy-and-security"
            />

            <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
              {feedback}
            </Dialog>

          </FormContainer>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.datepickerModal}
            open={openDatePicker}
            onClose={() => setOpenDatePicker(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openDatePicker}>
              <div className={classes.datepicker}>
                <DatePicker
                  value={data?.birthday}
                  onSelect={(time) => {
                    updateData("birthday", time);
                    setOpenDatePicker(false);
                  }}
                  onCancel={() => setOpenDatePicker(false)}
                  isPopup={false}
                  showHeader={true}
                  headerFormat="DD/MM/YYYY"
                  dateConfig={dateConfig}
                  min={new Date(1910, 0, 1)}
                  max={moment()._d}
                  confirmText={t('BIRTHDATE_MODAL_CONFIRM_BUTTON')}
                  cancelText={t('BIRTHDATE_MODAL_CANCEL_BUTTON')}
                />
              </div>
            </Fade>
          </Modal>
        </>
      )
      }
    </Container >
  );
};

export default Profile;
