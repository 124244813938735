import React, {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '../../utils/request';
import { getUser } from '../../utils/user';
import { Box, CircularProgress } from '@material-ui/core';
import {
  Container,
  Title,
  CenterColumn,
  FormContainer,
  AccordionContainer,
} from './Components';
import Header from '../../components/Header/Header';
import AccordionSummary from '../../components/AccordionSummary/AccordionSummary';
import AccordionDetails from '../../components/AccordionDetails/AccordionDetails';
import HealthForm from '../../components/HealthForm/HealthForm';
import HealthEmergencyInfoForm from '../../components/HealthEmergencyInfoForm/HealthEmergencyInfoForm';
import { RedirectButton } from '../../components/RedirectButton';
import CodeContainer from '../../components/CodeContainer/CodeContainer';

const MyHealth = () => {
  const { t } = useTranslation('healthPage');
  const user = getUser();

  const [loading, setLoading] = useState(false);
  const [countryDiseases, setCountryDiseases] = useState([]);
  const [userHealthForm, setUserHealthForm] = useState();
  const [expandedPanel, setExpandedPanel] = useState(undefined);

  const handleClick = (panel) => {
    if (panel === expandedPanel) {
      setExpandedPanel(undefined);
    } else {
      setExpandedPanel(panel);
    }
  };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        setLoading(true);
        const { data: result } = await request.get(
          `/client-app/profile?id=${user.id}`
        );

        setUserHealthForm(result.healthForm);
        setCountryDiseases(result.country?.diseases || []);
      } catch (error) {
        console.log(`[MyHealth getUserProfile Error]: ${error}`);
      } finally {
        setLoading(false);
      }
    }

    getUserProfile();
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('TITLE')}</Title>

      {
        loading ?
          <CenterColumn style={{ marginTop: 50 }}>
            <CircularProgress />
          </CenterColumn>
          :
          <FormContainer>

            <RedirectButton
              title={t('MY_VACCINATIONS_CARD')}
              href="/secure/my-health/my-vaccinations-card"
              marginBottom="20px"
            />

            <AccordionContainer marginBottom="20px">
              <AccordionSummary
                id="panel2a-header"
                onClick={() => handleClick('panel2')}
                title={t('HEALTH_DATA')}
                expandedPanel={expandedPanel}
                panel="panel2"
              />

              <AccordionDetails
                id="panel2a-content"
                expandedPanel={expandedPanel}
                panel="panel2"
                height={"100%"}
                paddingBottom={70}
              >
                <HealthForm
                  userId={user.id}
                  userHealthForm={userHealthForm}
                  countryDiseases={countryDiseases}
                />
              </AccordionDetails>
            </AccordionContainer>

            <AccordionContainer marginBottom="20px">
              <AccordionSummary
                id="panel3a-header"
                onClick={() => handleClick('panel3')}
                title={t('EMERGENCY_INFORMATION')}
                expandedPanel={expandedPanel}
                panel="panel3"
              />

              <AccordionDetails
                id="panel3a-content"
                expandedPanel={expandedPanel}
                panel="panel3"
                height={800}
              >
                <HealthEmergencyInfoForm
                  userId={user.id}
                  userHealthForm={userHealthForm}
                />
              </AccordionDetails>
            </AccordionContainer>

            <AccordionContainer marginBottom="20px">
              <AccordionSummary
                id="panel4a-header"
                onClick={() => handleClick('panel4')}
                title={"Código de Compartilhamento"}
                expandedPanel={expandedPanel}
                panel="panel4"
              />
              <AccordionDetails
                id="panel4a-content"
                expandedPanel={expandedPanel}
                panel="panel4"
                height={300}
              >
                <Box style={{ display: 'flex', alignText: 'center', justifyContent: 'center' }}>
                  <CodeContainer
                    documentMetadata={{ codeType: 'hcp' }}
                    explanatoryTextOffCode={t('EXPLANATORY_OFF_CODE_TEXT')}
                    explanatoryTextOnCode={t('EXPLANATORY_ON_CODE_TEXT')}
                    generateCodeText={t('GENERATE_CODE')}
                    terminateCodeText={t('TERMINATE_CODE')}
                    marginTop={0}
                    btnColor="#FF0066"
                    onCodeTextColor="#808080"
                    translations={t}
                  />
                </Box>
              </AccordionDetails>
            </AccordionContainer>

            <RedirectButton
              title={t('MEDICAL_CERTIFICATE')}
              href="/secure/my-health/medical-certificate"
            />
          </FormContainer>
      }


    </Container >
  );
}

export default MyHealth;