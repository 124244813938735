import axios from 'axios';

const getJwtToken = () => {
  const jwt = localStorage.getItem('__session');
  return (jwt || '').replace(/['"]+/g, '');
};

const generateHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getJwtToken()}`,
  };
};

class Request {
  get = (path = '/', options = {}) =>
    axios(`${process.env.REACT_APP_API_URL}${path}`, {
      headers: generateHeaders(),
      ...options,
    });
  post = (path = '/', body = {}, options = {}) =>
    axios.post(`${process.env.REACT_APP_API_URL}${path}`, body, {
      headers: generateHeaders(),
      ...options,
    });
  put = (path = '/', body = {}, options = {}) =>
    axios.put(`${process.env.REACT_APP_API_URL}${path}`, body, {
      headers: generateHeaders(),
      ...options,
    });
  patch = (path = '/', body = {}, options = {}) =>
    axios.patch(`${process.env.REACT_APP_API_URL}${path}`, body, {
      headers: generateHeaders(),
      ...options,
    });
  delete = (path = '/', options = {}) =>
    axios.delete(`${process.env.REACT_APP_API_URL}${path}`, {
      headers: generateHeaders(),
      ...options,
    });
}

const request = new Request();

export { request };
