import React, {
  useState
} from "react";
import {
  Typography,
  IconButton,
  makeStyles,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Box
} from "@material-ui/core";
import {
  FileCopy as FileIcon,
  CheckCircle as CheckIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Warning as WarningIcon,
  KeyboardArrowDown as ChevronDownIcon,
} from "@material-ui/icons";
import {
  DocumentDescription,
  ButtonWrapper
} from "./Components";
import axios from "axios";
import FileDownload from "js-file-download";

const useStyles = makeStyles(() => ({
  accordion: {
    '&.MuiAccordion-root': {
      marginTop: 20,
      borderRadius: 8,
      flexGrow: 0,
    },

    '& .MuiAccordionSummary-content': {
      width: '80%',
    },

    '&.MuiAccordion-root:before': {
      display: 'none',
    },

    '&.MuiAccordion-root.Mui-expanded:first-child': {
      marginTop: 20,
    }
  },

  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  fileIcon: {
    fontSize: 38,
    marginRight: 10,
    color: '#2D80C6',
  },

  docName: {
    fontSize: 15,
    color: "#000000",
    fontWeight: "bold",
  },

  docInfo: {
    fontSize: 12,
    color: "#A4A4A4",
    marginTop: ".2rem",
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  statusText: {
    fontSize: 15,
    fontWeight: "bold",
  },

  statusIcon: {
    width: "16px",
    marginLeft: 5
  },

  observationTitle: {
    fontSize: '.8rem',
    fontWeight: 'bold',
    color: "#808080",
  },

  observationText: {
    fontSize: '.8rem',
    color: "#808080",
  },
}));

const SickNoteAccordion = ({
  doctorName,
  enterpriseName,
  enterpriseNameLabel,
  certificateDate,
  certificateDateLabel,
  status,
  statusText,
  daysOff,
  verifiedDaysOff,
  analystObservationLabel,
  analystObservation,
  url,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const colormap = {
    VERIFIED: "#00A859",
    PENDING: "#F69103",
    REDO: "#F69103",
    INCONSISTENT: "#F69103",
    DENIED: "#F60E31",
    ALERT: "#F60E31",
  };

  const handleDownloadDocument = async (url, doctorName) => {
    setIsLoading(true);
    try {
      const documentInfoArray = url.split('.');
      const documentExtension = documentInfoArray[documentInfoArray.length - 1];

      const documentName = doctorName.split(' ').join('_').replace('.', '');

      axios.get(url, {
        responseType: 'blob',
      }).then((res) => {
        FileDownload(res.data, `${documentName}.${documentExtension}`)
      })
    } catch (err) {
      console.log(`[SickNote handleDownloadDocument] ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
      >
        <Box className={classes.accordionSummaryContent}>
          <FileIcon className={classes.fileIcon} />
          <DocumentDescription>
            <Typography className={classes.docName}>
              {doctorName}
            </Typography>
            <Typography className={classes.docInfo}>
              {enterpriseNameLabel}: {enterpriseName}
            </Typography>
            <Typography className={classes.docInfo}>
              {certificateDateLabel}: {certificateDate}
            </Typography>
            <Typography className={classes.docInfo}>
              {`Dias: ${daysOff}`}
            </Typography>
            <Typography className={classes.docInfo}>
              {`Dias Verificados: ${verifiedDaysOff}`}
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.statusText}
                style={{ color: colormap[status] }}
              >
                {statusText}
              </Typography>

              {
                status === 'PENDING' ?
                  <HourglassEmptyIcon
                    className={classes.statusIcon}
                    htmlColor={colormap[status]}
                  />
                  : status === 'VERIFIED' ?
                    <CheckIcon
                      className={classes.statusIcon}
                      htmlColor={colormap[status]}
                    />
                    : <WarningIcon
                      className={classes.statusIcon}
                      htmlColor={colormap[status]}
                    />
              }
            </div>
          </DocumentDescription>

          <ButtonWrapper>
            <IconButton
              aria-label="download"
              color="primary"
              disabled={isLoading}
              onClick={() => handleDownloadDocument(url, doctorName)}
            >
              <img src="/assets/pdf-blue.svg" alt="Blue PDF icon" />
            </IconButton>
          </ButtonWrapper>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography className={classes.observationTitle}>
            {analystObservationLabel}:
            {' '}
            <Typography
              component="span"
              className={classes.observationText}
            >
              {analystObservation}
            </Typography>
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SickNoteAccordion;
