import styled from "styled-components";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-top: 15px;
  box-sizing: border-box;
`;

export {
  ImageContainer,
};
