import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
 width: calc(100% - 40px);
 height: auto;
 border-radius: 8px;
 box-sizing: border-box;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const Text = styled.p`
  color: #808080;
`

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  margin-top: 50px;
`;

export {
  Container,
  Content,
  CenterColumn,
  Title,
  Text,
};