import React, {
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { useTranslation } from 'react-i18next';
import {
  Container,
  Content,
  CenterColumn,
  Title,
  Text,
} from "./Components.js";
import Header from "../../components/Header/Header";
import SearchInput from "../../components/SearchInput/SearchInput";
import VaccinationCardAccordion from "../../components/VaccinationCardAccordion/VaccinationCardAccordion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { request } from '../../utils/request';

const ConsultMedicalCertificate = () => {
  const { t } = useTranslation('vaccinationsCardPage');

  const [isLoadingUserVaccinationsCard, setIsLoadingUserVaccinationsCard] = useState(true);
  const [userVaccinationsCard, setUserVaccinationsCard] = useState([]);
  const [search, setSearch] = useState('');

  const orderByLatestUploadedFile = (a, b) => {
    return new Date(a.uploadDatetime).getTime() > new Date(b.uploadDatetime).getTime()
      ? - 1
      : new Date(a.uploadDatetime).getTime() < new Date(b.uploadDatetime).getTime()
        ? 1
        : 0
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const userAvailableVaccinationsCard = useCallback(() => {
    const vaccinationsCardList = [];

    if (!userVaccinationsCard?.length > 0) {
      return vaccinationsCardList;
    };

    userVaccinationsCard.forEach((vaccinationCard, index) => {
      const newIndex = index + 1;
      let document = `${t('CONSULT_VACCINATIONS_CARD_VACCINATION_CARD')} 0${newIndex}`;

      if (newIndex > 9) {
        document = `${t('CONSULT_VACCINATIONS_CARD_VACCINATION_CARD')} ${newIndex}`
      };

      vaccinationsCardList.push(
        <VaccinationCardAccordion
          key={document}
          filesTitle={t('CONSULT_VACCINATIONS_CARD_UPLOADED_FILES')}
          files={vaccinationCard?.files}
          documentName={document}
          uploadDate={vaccinationCard.uploadDatetime}
          uploadDateLabel={t('CONSULT_VACCINATIONS_CARD_DATE')}
          status={vaccinationCard.status}
          statusText={t(`CONSULT_VACCINATIONS_CARD_STATUS_${vaccinationCard.status}`)}
          analystObservationLabel={t('CONSULT_VACCINATIONS_CARD_ANALYST_MESSAGE')}
          analystObservation={vaccinationCard?.observation}
        />
      )
    });

    return vaccinationsCardList;
  }, [userVaccinationsCard, t])

  const vaccinationsCardToRender = userAvailableVaccinationsCard();
  const vaccinationsCardFiltered = useMemo(() => {
    if (vaccinationsCardToRender.length > 0) {
      const lowerSearch = search.toLowerCase();
      return vaccinationsCardToRender
        .filter((document) => document.key.toLowerCase().includes(lowerSearch))
    }
  }, [vaccinationsCardToRender, search]);

  useEffect(() => {
    const getUserVaccinationsCard = async () => {
      try {
        const { data } = await request.get(
          `/client-app/vaccination-card-registries`
        );

        const sortedVaccinationsCard = data.sort(orderByLatestUploadedFile);
        setUserVaccinationsCard(sortedVaccinationsCard);
      } catch (error) {
        console.error(`[ConsultVaccinationsCardStatus getUserVaccinationsCard] ${error}`);
      } finally {
        setIsLoadingUserVaccinationsCard(false);
      }
    }
    getUserVaccinationsCard();
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>
        {t('CONSULT_VACCINATIONS_CARD')}
      </Title>
      <SearchInput
        placeholder={t('CONSULT_VACCINATIONS_CARD_SEARCH')}
        onChange={handleChange}
        value={search}
        endIcon
      />
      {isLoadingUserVaccinationsCard ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        vaccinationsCardFiltered &&
          vaccinationsCardFiltered.length > 0 ?
          <Content>
            {vaccinationsCardFiltered}
          </Content>
          :
          <CenterColumn>
            <Text>
              {t('CONSULT_VACCINATIONS_CARD_CERTIFICATE_NOT_FOUND')}
            </Text>
          </CenterColumn>
      )}
    </Container>
  );
};

export default ConsultMedicalCertificate;
