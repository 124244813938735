import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./index.css";
import Website from "./Website";
import * as serviceWorker from "./serviceWorker";
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

try {
  require("dotenv").config();
} catch (_) {
  // Really, a try/catch without catch ????
}

const root = document.getElementById("root");

const index = (
  <Elements stripe={stripePromise}>
    <Router>
      <Route path={"/"} render={(props) => <Website {...props} />} />
    </Router>
  </Elements>
);

ReactDOM.render(index, root);

serviceWorker.register();
