import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #FE9201;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;

const Subtitle = styled.h4`
  color: #FE9201;
  font-size: 19px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
`;

const AvatarBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:131px;
  height:131px;
  position: relative;
  margin-bottom: 10px;
  &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:50%; 
    padding:5px; 
    background: linear-gradient(135deg, #FEB731, #FE9201); 
    background: -webkit-linear-gradient(310deg, #FEB731, #FE9201); 
    -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
  }
`
export {
  Container,
  Title,
  CenterColumn,
  Subtitle,
  AvatarBorder,
};
