import styled from "styled-components";

const DocumentDescription = styled.div`
  display: flex;
  margin-left: 0;
  max-width: 68%;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  margin: 0;
  margin-right: -12px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`;

export {
  DocumentDescription,
  ButtonWrapper
};