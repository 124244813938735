import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    color: '#505050',
    cursor: 'pointer',
  },
}));

const FormEntryRadio = ({
  id,
  name,
  checked,
  onChange,
  value,
  label,
  disabled,
  marginLeft,
}) => {

  const classes = useStyles();

  return (
    <div style={{ marginLeft: marginLeft ? marginLeft : 0 }}>
      <input
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        disabled={disabled}
        type="radio"
      />
      <label htmlFor={id} className={classes.label}>
        {label}
      </label>
    </div>
  );
}

export default FormEntryRadio;