import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  sliderContainer: {
    width: 'calc(100% - 100px)',
    height: '4px',
    borderRadius: '4px',
    background: '#808080',
    margin: '0 1.5rem',
    position: 'relative',
  },

  slider: {
    height: '4px',
    borderRadius: '4px',
    background: '#00A859',
  },

  sliderIconsContainer: {
    width: '110%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transform: 'translateX(-5%)',
    left: 0,
    right: 0,
    top: -20,
  },

  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imgText: {
    fontSize: 8,
    color: '#808080',
    marginTop: 5,
    maxWidth: 50,
    textAlign: 'center',
    height: 30
  },

  imgTextGreen: {
    fontSize: 8,
    color: '#00A859',
    marginTop: 5,
    maxWidth: 50,
    textAlign: 'center',
    height: 30,
  },
}));

const SliderNoronhaWithExam = ({ position }) => {
  const { t } = useTranslation('passeVerdePages');
  const classes = useStyles();

  const move = () => {
    if (position === 2) {
      return 82
    }

    if (position >= 3) {
      return 100
    }

    return 52
  }

  return (
    <div className={classes.sliderContainer}>
      <div className={classes.slider} style={{ width: `${move()}%` }} />
      <div className={classes.sliderIconsContainer}>
        <div className={classes.imgContainer}>
          <img src="/assets/circle-hand.svg" alt="Circle with Hand" />
          <span className={classes.imgTextGreen}>
            {t('UPLOAD_PASSE_VERDE_DOCS_TOP_SLIDER_1')}
          </span>
        </div>
        <div className={classes.imgContainer}>
          <img
            src={
              position === 1 ?
                "/assets/document-green.svg"
                : position >= 2 ?
                  "/assets/document-pos-uploaded.svg"
                  : "/assets/document-pre-upload.svg"
            }
            alt="PDF Document" />
          <span className={position >= 2 ? classes.imgTextGreen : classes.imgText}>
            {t('UPLOAD_PASSE_VERDE_DOCS_TOP_SLIDER_2')}
          </span>
        </div>
        <div className={classes.imgContainer}>
          <img
            src={
              position === 2 ?
                "/assets/document-green.svg"
                : position >= 3 ?
                  "/assets/document-pos-uploaded.svg"
                  : "/assets/document-pre-upload.svg"
            }
            alt="PDF Document" />
          <span className={position >= 3 ? classes.imgTextGreen : classes.imgText}>
            {t('UPLOAD_PASSE_VERDE_DOCS_TOP_SLIDER_3')}
          </span>
        </div>
        <div className={classes.imgContainer}>
          <img
            src={
              position >= 3 ?
                "/assets/self-green.svg"
                : "/assets/self-pre-upload.svg"
            }
            alt="Selfie Upload" />
          <span className={position === 4 ? classes.imgTextGreen : classes.imgText}>
            {t('UPLOAD_PASSE_VERDE_DOCS_TOP_SLIDER_4')}
          </span>
        </div>
      </div>
    </div >
  )
}

export default SliderNoronhaWithExam;