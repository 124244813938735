import React from 'react';
import {
  makeStyles,
  Backdrop,
  Fade,
  Modal,
  Typography
} from "@material-ui/core";
import { Button } from './Components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxWidth: '420px',
    margin: '0 auto',
    boxSizing: 'border-box',
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalTitle: {
    textAlign: "center",
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
  },

  modalDescription: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'justify'
  },

  modalButtonContainer: {
    marginTop: "-50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
}));

export const UserConfirmationModal = ({
  id,
  title,
  description,
  button,
  open,
  disabled = false,
  onClick,
  onClickExtraBtn,
  extraButton = "",
  userName,
  userBirthdate,
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby={`${id}-modal-title`}
      aria-describedby={`${id}-modal-description`}
      className={classes.modal}
      open={open}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <Typography
            id={`${id}-modal-title`}
            style={{
              color: title?.color ?? '#00A859',
            }}
            className={classes.modalTitle}
            variant='h6'
          >
            {title?.text}
          </Typography>
          <Typography
            id={`${id}-modal-description`}
            style={{
              color: description?.color ?? '#808080',
            }}
            className={classes.modalDescription}
          >
            {description?.text}
          </Typography>
          <div>
            <Typography style={{ fontWeight: "bold", textAlign: "center", marginTop: 10 }}>{userName}</Typography>
            <Typography style={{ fontWeight: "bold", textAlign: "center" }}>{userBirthdate}</Typography>
          </div>
          <div className={classes.modalButtonContainer}>
            <Button
              style={{
                backgroundColor: "#2E75B6"
              }}
              disabled={disabled}
              onClick={onClickExtraBtn}
            >
              {extraButton}
            </Button>
            {
              extraButton ?

                <Button
                  style={{
                    backgroundColor: "#00B050" ?? '#FF0066',
                  }}
                  disabled={disabled}
                  onClick={onClick}
                >
                  {button?.text}
                </Button>
                : null
            }
          </div>
        </div>
      </Fade>
    </Modal>
  )
};