import styled from "styled-components";

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
`;

const MainButton = styled.button`
  color: white;
  font-weight: bold;
  background-color: #2D80C6;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: #3297DB;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  margin: 10px 0px; 
`

export {
  ButtonContainer,
  MainButton,
  LoadingContainer
};