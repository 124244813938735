import React from 'react';
import { makeStyles, List, ListItem, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ArrowForwardIos,
  Share,
  Backup,
  Policy,
  AccountCircle,
  Lock,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    width: '85%',
    padding: 20,
    border: '1px solid #A2A2A2',
    borderRadius: 10,
    boxSizing: 'border-box',
    backgroundImage: 'linear-gradient(#FFFFFF, rgba(255, 255, 255, .77))',
    marginTop: '7.5%',
  },

  link: {
    textDecoration: 'none',
    color: '#000000',
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  subtitle: {
    display: 'flex',
    alignItems: 'center',
  },

  img: {
    width: 21,
    height: 21,
    marginRight: 3,
  },

  svg: {
    color: '#2985db',
    width: 15,
    transform: 'translateY(5%)',
  },

  icon: {
    color: '#A2A2A2',
  }
});

const ItemList = ({ title, data }) => {
  const { t } = useTranslation('siteMapPage');
  const classes = useStyles();

  const icons = {
    'Share': <Share className={classes.icon} />,
    'Backup': <Backup className={classes.icon} />,
    'Policy': <Policy className={classes.icon} />,
    'Avatar': <AccountCircle className={classes.icon} />,
    'Privacy': <Lock className={classes.icon} />,
  }

  return (
    <List className={classes.container}>
      <h6 style={{ color: '#808080', margin: '0px', fontSize: '12px' }}>
        {t(title).toUpperCase()}
      </h6>
      {
        data.map((item) => {
          return (
            <Link to={item.url} key={item.name} className={classes.link}>
              <ListItem className={classes.listItem}>
                <div className={classes.subtitle}>
                  {
                    item.source !== '' ?
                      <img
                        src={item.source}
                        alt={`${item.name} icon`}
                        className={classes.img} />
                      :
                      icons[item.icon]
                  }
                  <Typography style={{ marginLeft: 5, color: '#2985db' }}>
                    {t(item.name)}
                  </Typography>
                </div>
                <ArrowForwardIos className={classes.svg} />
              </ListItem>
            </Link>
          )
        })
      }
    </List>
  )
}

export default ItemList;