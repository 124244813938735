import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  Box,
  Checkbox,
  Fade,
  Modal,
  makeStyles,
} from '@material-ui/core';
import { Button } from './Components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  checkboxContainer: {
    display: 'flex',
  },
}));

export const AcceptanceModal = ({
  id,
  onClick,
  open,
  isSubmitting,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const disabled = !privacyPolicyChecked || !termsAndConditionsChecked || isSubmitting;

  return (
    <Modal
      aria-labelledby={`${id}-modal-title`}
      aria-describedby={`${id}-modal-description`}
      className={classes.modal}
      open={open}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <Box>
            <img
              width={60}
              src="/assets/logo_180x180.png"
              alt="immunie logo"
            />
          </Box>

          <Box className={classes.checkboxContainer}>
            <Checkbox
              labelId="terms-and-conditions-acceptance"
              checked={termsAndConditionsChecked}
              onChange={() => setTermsAndConditionsChecked(!termsAndConditionsChecked)}
              name="terms-and-conditions-acceptance"
            />

            <label htmlFor="terms-and-conditions-acceptance">
              {t('ACCEPTANCE_MODAL_TERMS_OF_CONDITIONS_1')}
              {' '}
              <Link to="/secure/terms-and-conditions">
                <strong>
                  {t('ACCEPTANCE_MODAL_TERMS_OF_CONDITIONS_2')}
                </strong>
              </Link>
            </label>
          </Box>

          <Box className={classes.checkboxContainer}>
            <Checkbox
              labelId="privacy-policy-acceptance"
              checked={privacyPolicyChecked}
              onChange={() => setPrivacyPolicyChecked(!privacyPolicyChecked)}
              name="privacy-policy-acceptance"
            />

            <label htmlFor="terms-and-conditions-acceptance">
              {t('ACCEPTANCE_MODAL_PRIVACY_POLICY_1')}
              {' '}
              <Link to="/secure/privacy-policy">
                <strong>
                  {t('ACCEPTANCE_MODAL_PRIVACY_POLICY_2')}
                </strong>
              </Link>
            </label>
          </Box>

          <Button
            style={{
              color: "#FFFFFF",
              backgroundColor:
                disabled
                  ? "#8D8D8D"
                  : "#00A859",
              cursor:
                disabled
                  ? 'not-allowed'
                  : 'pointer'
            }}
            disabled={disabled}
            onClick={onClick}
          >
            {
              isSubmitting
                ? t('ACCEPTANCE_MODAL_SUBMITTING_TEXT')
                : t('ACCEPTANCE_MODAL_CONFIRM_BUTTON')
            }
          </Button>
        </Box>
      </Fade>
    </Modal>
  )
};