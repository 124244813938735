import styled from "styled-components";

const Container = styled.div`
 background-color: #FFFFFF;
 width: calc(100% - 40px);
 height: auto;
 padding: 10px;
 margin: 20px 0px;
 border-radius: 8px;
 box-sizing: border-box;
 box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const Subtitle = styled.h3`
  width: calc(100% - 40px);
  font-size: 20px;
  font-weight: bold;
  color: #FE9201;
  margin-top: 20px;
  margin-bottom: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  align-items: center;
  width: 100%;
`;

const VaccineDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const VaccineTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 14px;
`;

const VaccineNotAdministrateTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #A4A4A4;
`;

const VaccineSubtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 11px;
  color: #A4A4A4;
`;

const VaccineDate = styled.div`
  width: 100%;
`;

const VaccineIcon = styled.div`
  margin-right: 10px;
`;

const SyringeIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.status};
  background-position: center;
  background-size: 55%;
  background-repeat: no-repeat;
  background-image: url('/assets/syringe_white.svg');
`

export {
  Container,
  Subtitle,
  Row,
  VaccineDetails,
  VaccineTitle,
  VaccineNotAdministrateTitle,
  VaccineSubtitle,
  VaccineDate,
  VaccineIcon,
  SyringeIcon
};