
import styled from "styled-components";

const Container = styled.div`
  display: flex; 
  box-sizing: border-box;
`;

const Label = styled.label`
  width: 120%;
  cursor: pointer;
  box-sizing: border-box;
`;

const Title = styled.span`
  display: block;
  font-weight: bold; 
  font-size: 14px;
  box-sizing: border-box;
`;

const Text = styled.span`
  display: block;
  color: #808080; 
  font-size: 13px;
  box-sizing: border-box;
`;

const ProtocolContainer = styled.div`
  width: 77%;
  display: flex;
  align-items: center;
`

const ProtocolText = styled.span`
  display: block;
  color: #808080; 
  font-size: 13px;
  box-sizing: border-box;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export {
  Container,
  Label,
  Title,
  Text,
  ProtocolContainer,
  ProtocolText,
};
