import React, {
  useState
} from "react";
import {
  Typography,
  IconButton,
  makeStyles,
  Box
} from "@material-ui/core";
import {
  FileCopy as FileIcon,
  CheckCircle as CheckIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import {
  CardWrapper,
  DocumentDescription,
  ButtonWrapper
} from "./Components";
import axios from "axios";
import FileDownload from "js-file-download";

const useStyles = makeStyles(() => ({
  content: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
  },

  fileIcon: {
    fontSize: 38,
    marginRight: 10,
    color: '#2D80C6',
  },

  docName: {
    fontSize: 15,
    color: "#000000",
    fontWeight: "bold",
  },

  docInfo: {
    fontSize: 12,
    color: "#A4A4A4",
    marginTop: ".2rem",
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  statusText: {
    fontSize: 15,
    fontWeight: "bold",
  },

  statusIcon: {
    width: "16px",
    marginLeft: 5
  },
}));

const SickNote = ({
  doctorName,
  enterpriseName,
  enterpriseNameLabel,
  certificateDate,
  certificateDateLabel,
  status,
  statusText,
  url,
  daysOff,
  verifiedDaysOff,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const colormap = {
    VERIFIED: "#00A859",
    PENDING: "#F69103",
    REDO: "#F69103",
    INCONSISTENT: "#F69103",
    DENIED: "#F60E31",
    ALERT: "#F60E31",
  };

  const handleDownloadDocument = async (url, doctorName) => {
    setIsLoading(true);
    try {
      const documentInfoArray = url.split('.');
      const documentExtension = documentInfoArray[documentInfoArray.length - 1];

      const documentName = doctorName.split(' ').join('_').replace('.', '');

      axios.get(url, {
        responseType: 'blob',
      }).then((res) => {
        FileDownload(res.data, `${documentName}.${documentExtension}`)
      })
    } catch (err) {
      console.log(`[SickNote handleDownloadDocument] ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CardWrapper>
      <Box className={classes.content}>
        <FileIcon className={classes.fileIcon} />
        <DocumentDescription>
          <Typography className={classes.docName}>
            {doctorName}
          </Typography>
          <Typography className={classes.docInfo}>
            {enterpriseNameLabel}: {enterpriseName}
          </Typography>
          <Typography className={classes.docInfo}>
            Data informada: {certificateDate}
          </Typography>
          <Typography className={classes.docInfo}>
            {`Dias Informados: ${daysOff || 'N/A'}`}
          </Typography>
          <Typography className={classes.docInfo}>
            {`Dias Verificados: ${verifiedDaysOff || 'N/A'}`}
          </Typography>
          <div style={{ display: 'flex' }}>
            <Typography
              className={classes.statusText}
              style={{ color: colormap[status] }}
            >
              {statusText}
            </Typography>

            {
              status === 'PENDING' ?
                <HourglassEmptyIcon
                  className={classes.statusIcon}
                  htmlColor={colormap[status]}
                />
                : status === 'VERIFIED' ?
                  <CheckIcon
                    className={classes.statusIcon}
                    htmlColor={colormap[status]}
                  />
                  : <WarningIcon
                    className={classes.statusIcon}
                    htmlColor={colormap[status]}
                  />
            }
          </div>
        </DocumentDescription>

        <ButtonWrapper style={{marginLeft: "30px"}}>
          <IconButton
            aria-label="download"
            color="primary"
            disabled={isLoading}
            onClick={() => handleDownloadDocument(url, doctorName)}
          >
            <img src="/assets/pdf-blue.svg" alt="Blue PDF icon" />
          </IconButton>
        </ButtonWrapper>
      </Box>
    </CardWrapper>
  );
};

export default SickNote;
