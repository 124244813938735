import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 55px;
`;

const Image = styled.div`
  background-image: url("/assets/online_payment.svg");
  height: 200px;
  background-size:auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -50px;
  position: relative;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 0px;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;

const Subtitle = styled.h4`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export {
  Container,
  Image,
  Title,
  CenterColumn,
  Subtitle,
};