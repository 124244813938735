import React from 'react';
import {
  makeStyles,
  Backdrop,
  Fade,
  Modal as MUIModal,
  Typography
} from "@material-ui/core";
import { Button } from './Components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxWidth: '420px',
    margin: '0 auto',
    boxSizing: 'border-box',
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalTitle: {
    textAlign: "center",
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
    color: '#00A859',
  },

  modalDescription: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },

  modalButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },

  modalBackButton: {
    backgroundColor: '#2D80C6',
    color: '#FFFFFF',
    transition: '.2s',
    '&:hover': {
      filter: 'brightness(.9)',
    }
  },
}));

export const Modal = ({
  id,
  title,
  description,
  open,
  disabled = false,
  leftButtonTitle,
  rightButtonTitle,
  onClickLeftButton,
  onClickRightButton,
}) => {
  const classes = useStyles();

  return (
    <MUIModal
      aria-labelledby={`${id}-modal-title`}
      aria-describedby={`${id}-modal-description`}
      className={classes.modal}
      open={open}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <Typography
            id={`${id}-modal-title`}
            className={classes.modalTitle}
            variant='h6'
          >
            {title}
          </Typography>
          <Typography
            id={`${id}-modal-description`}
            className={classes.modalDescription}
          >
            {description}
          </Typography>
          <div className={classes.modalButtonContainer}>
            <Button
              className={classes.modalBackButton}
              onClick={onClickLeftButton}
            >
              {leftButtonTitle}
            </Button>
            <Button
              style={{
                backgroundColor: disabled
                  ? "#8D8D8D"
                  : "#FF0066",
                color: "#FFFFFF",
              }}
              disabled={disabled}
              onClick={onClickRightButton}
            >
              {rightButtonTitle}
            </Button>
          </div>
        </div>
      </Fade>
    </MUIModal>
  )
};