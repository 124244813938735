import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { request } from '../../utils/request';
import { getUser } from '../../utils/user';
import { getEvent } from '../../utils/event';
import {
  makeStyles,
  Typography,
  Checkbox,
  Fade,
  Backdrop,
  FormControlLabel,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import {
  CheckCircle as CheckIcon,
} from "@material-ui/icons";
import {
  Container,
  TitleContainer,
  Title,
  CenterColumn,
  DottedDivider,
  BoxUploadContainer,
  Button,
  ApprovedDocContainer,
  ApprovedDocTitle,
} from './Components';
import Header from '../../components/Header/Header';
import SliderPasseVerde from '../../components/SliderPasseVerde/SliderPasseVerde';
import SelectInput from '../../components/SelectInput/SelectInput';
import DateInput from '../../components/DateInput/DateInput';
import BoxUploadFile from '../../components/BoxUploadFile/BoxUploadFile';
import { FeedbackModal } from '../../components/FeedbackModal/FeedbackModal';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    width: '22px',
    color: '#00A859',
    transform: 'translateY(7%)',
    marginLeft: 5
  },

  button: {
    '&:hover': {
      backgroundColor: '#54b488',
    }
  },

  buttonDisabled: {
    cursor: 'not-allowed',
    backgroundColor: '#808080',
    '&:hover': {
      backgroundColor: '#808080',
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalHeader: {
    textAlign: "center",
    width: 290,
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
    color: '#00A859',
  },

  modalButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },

  modalBackButton: {
    backgroundColor: '#2D80C6',
    color: '#FFFFFF',
    transition: '.2s',
    '&:hover': {
      filter: 'brightness(.9)',
    }
  },

  tocModalContent: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },

  tocModalFormControlLabel: {
    marginBottom: 20,
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
    },
  },

  confirmationModalPaper: {
    backgroundColor: '#00A859',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  confirmationModalHeader: {
    textAlign: "center",
    width: 290,
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
    color: '#FFFFFF',
  },

  confirmationModalContent: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'justify'
  },

  confirmationModalFormControlLabel: {
    marginBottom: 20,
    '& .MuiTypography-body1': {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#FFFFFF'
    }
  },

  finalModalContent: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'center'
  },

  finalModalButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: 10,
  },
}));

const PasseVerdeUpload = () => {
  const classes = useStyles();
  const { t } = useTranslation('passeVerdePages');
  const user = getUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [event, setEvent] = useState({});
  const [requiredDocuments, setRequiredDocuments] = useState(undefined);
  const [noDocumentRequired, setNoDocumentsRequired] = useState(false);
  const [availableRules, setAvailableRules] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [selectedValidationRules, setSelectedValidationRules] = useState('');
  const [covidCertImages, setCovidCertImages] = useState({});
  const [uploadedRequiredFiles, setUploadedRequiredFiles] = useState({});
  const [examOrCertificationDate, setExamOrCertificationDate] = useState({});
  const [loadingCovidImage, setLoadingCovidImage] = useState(false);
  const [currentSliderPosition, setCurrentSliderPosition] = useState(1);
  const [openTCModal, setOpenTCModal] = useState(false);
  const [
    openCOVID19ModalDataVerification,
    setOpenCOVID19ModalDataVerification,
  ] = useState(false);
  const [openCOVID19ModalFinal, setOpenCOVID19ModalFinal] = useState(false);
  const [modalState, setModalState] = useState({
    termsAndConditions: false,
    firstChecked: false,
    secondChecked: false,
  });

  //Errors Modal
  const [isOpenSubmitErrorModal, setIsOpenSubmitErrorModal] = useState(false);
  const [isOpenSelfieErrorModal, setIsOpenSelfieErrorModal] = useState(false);

  const requiredExamOrCertificates = () => {
    const required = [];
    if (requiredDocuments?.required) {
      requiredDocuments.required.forEach(document => {
        if (document.name !== 'userSelfie'
          && document.name !== 'userDocId'
          && document.name !== 'locatorForm') {
          required.push(document);
        }
      });
      return required;
    }

    return undefined;
  }

  const required = requiredExamOrCertificates();
  const cantSubmit = !required ||
    Object.keys(uploadedRequiredFiles).length !== requiredDocuments?.required.length ||
    Object.keys(examOrCertificationDate).length !== required.length;

  const handleChange = async (value) => {
    try {
      setIsSubmitting(true);
      setIsLoading(true);

      setCovidCertImages({});
      setUploadedRequiredFiles({});
      setExamOrCertificationDate({});
      setProtocols([]);
      setSelectedValidationRules(value);

      if (value === "") {
        setRequiredDocuments(undefined);
        setNoDocumentsRequired(false);
      }

      const body = {
        eventId: event._id,
        validationRulesGroupId: value
      }

      const { data: result } = await request.post(`/client-app/verify-user-protocol`, body);

      const requiredDocs = [];
      const approvedDocs = [];

      result.forEach(doc => {
        let name = doc.protocolRule;

        if (doc.protocolRule === 'PROTOCOL REQUIRE APPROVED SELFIE') {
          name = 'userSelfie';
        }

        if (doc.protocolRule === 'PROTOCOL REQUIRE APPROVED ID DOC') {
          name = 'userDocId';
        }

        const document = {
          name: name,
          error: doc.error,
        }

        if (doc.approved === false) {
          requiredDocs.push(document);
        } else {
          approvedDocs.push(document);
        }
      });

      setRequiredDocuments({
        required: requiredDocs,
        approved: approvedDocs,
      });

      const rules = availableRules
        .find((availableRule) => availableRule.id === value)?.protocols
      setProtocols(rules);
      setCurrentSliderPosition(1);
    } catch (error) {
      console.error(`[PasseVerdeUpload handleChange]: ${error}`);
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleUpload = async (file, documentType) => {
    try {
      setLoadingCovidImage(true);
      const formData = new FormData();
      formData.append(`file.${documentType}`, file);
      const imageResult = await request.post(
        `/client-app/upload-covid-document?documentType=${documentType}`,
        formData
      );

      const newCertImages = { ...covidCertImages };
      newCertImages[documentType] = imageResult?.data?.fileId;
      setCovidCertImages(newCertImages);

      const isRequired = requiredDocuments?.required
        .find(document => document.name === documentType);

      if (isRequired) {
        const newUploadedRequiredFiles = { ...uploadedRequiredFiles };
        newUploadedRequiredFiles[documentType] = imageResult?.data?.fileId;
        setUploadedRequiredFiles(newUploadedRequiredFiles);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCovidImage(false);
    }
  };

  const handleChangeDate = (value, documentType) => {
    const newExamOrCertificationDate = { ...examOrCertificationDate };
    newExamOrCertificationDate[documentType] = moment(value).format();
    setExamOrCertificationDate(newExamOrCertificationDate);
  };

  const handleSubmit = async () => {
    if (cantSubmit) {
      alert(
        t('UPLOAD_PASSE_VERDE_SUBMIT_VALIDATION_MESSAGE_ERROR')
      );
    } else {
      try {
        setIsSubmitting(true);
        const rule = availableRules
          .find(rule => rule.id === selectedValidationRules);

        const body = {
          user: user.id,
          eventId: event.id,
          validationRulesGroupId: selectedValidationRules,
          covidCertImages: covidCertImages,
          sampleCollectionTime: examOrCertificationDate,
          protocolId: event.protocolId,
          ruleName: rule.name,
          locatorFormId: event?.locatorForm?.id,
        };

        await request.post(`/client-app/create-new-covid-cert-v2`, body);

        setOpenCOVID19ModalDataVerification(false);
        setOpenCOVID19ModalFinal(true);

      } catch (error) {
        setOpenCOVID19ModalDataVerification(false);

        const axiosError = error?.response?.data?.message;
        if (axiosError && axiosError === 'User with approved selfie cannot upload new PV with selfie') {
          setIsOpenSelfieErrorModal(true);
          return;
        }

        setIsOpenSubmitErrorModal(true);
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const moveSlider = () => {
    if (requiredDocuments?.required.length === 0) {
      setNoDocumentsRequired(true);
    } else {
      setNoDocumentsRequired(false);
    }

    if (selectedValidationRules !== '' &&
      requiredDocuments?.required.length > 0
      && !noDocumentRequired) {

      const required = requiredExamOrCertificates();
      const imagesToUpload = {};
      Object.assign(imagesToUpload, covidCertImages);
      Object.defineProperty(imagesToUpload, "userDocId", { enumerable: false });
      Object.defineProperty(imagesToUpload, "userSelfie", { enumerable: false });

      const needSelfie = requiredDocuments.required
        .find(document => document.name === 'PROTOCOL REQUIRE APPROVED SELFIE');

      const needIdentificationDocument = requiredDocuments.required
        .find(document => document.name === 'PROTOCOL REQUIRE APPROVED ID DOC');

      if (currentSliderPosition === 1 &&
        required.length === Object.keys(imagesToUpload).length) {
        setCurrentSliderPosition(2)
      }

      if (currentSliderPosition === 2
        && (event.requiredDocId || needIdentificationDocument)) {
        if (covidCertImages["userDocId"]) {
          setCurrentSliderPosition(3)
        }
      } else if (currentSliderPosition === 2 &&
        !event.requiredDocId &&
        !needIdentificationDocument) {
        setCurrentSliderPosition(3)
      }

      if (currentSliderPosition === 3
        && (event.requiredSelfie || needSelfie)) {
        if (covidCertImages["userSelfie"]) {
          setCurrentSliderPosition(4)
        }
      } else if (currentSliderPosition === 3 &&
        !event.requiredDocId &&
        !needSelfie) {
        setCurrentSliderPosition(4)
      }

    } else if (selectedValidationRules !== '' && requiredDocuments
      && noDocumentRequired) {
      if (currentSliderPosition === 1) {
        setCurrentSliderPosition(4);
      }
    }
  };

  const getStorageEvent = () => {
    const event = getEvent();
    if (event) {
      setEvent({
        id: event?.eventId,
        _id: event?.eventId,
        name: event?.eventName,
        contractType: event?.contract,
        date: event?.datetime,
        protocolId: event?.protocols?.protocolId,
        protocolName: event?.protocols?.protocolName,
        requiredSelfie: event?.protocols?.requiredSelfie,
        shouldRequestIDDocUpload: event?.protocols?.shouldRequestIDDocUpload,
        shouldRequestSelfieUpload: event?.protocols?.shouldRequestSelfieUpload,
        requiredDocId: event?.protocols?.requiredIDDoc,
        validationRules: event?.protocols?.validationRules,
        locatorForm: event?.locatorForm,
      });

      let availableRulesList = [];
      event.protocols.validationRules.forEach(rule => {
        const availableRule = {
          name: rule.ruleName,
          id: rule._id,
          protocols: rule.protocolRules
        }
        availableRulesList.push(availableRule);
      });

      const sortedRules = availableRulesList?.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      setAvailableRules(sortedRules);
    }
  }

  useEffect(() => {
    moveSlider();
  });

  useEffect(() => {
    getStorageEvent();

    if (!modalState.termsAndConditions) {
      setOpenTCModal(true);
      setModalState((oldModalState) => ({
        ...oldModalState,
        termsAndConditions: true,
      }));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header backgroundStyle="GREEN" />
      <SliderPasseVerde position={currentSliderPosition} />
      <TitleContainer>
        <Title>
          {event && event.name ? event.name : 'Evento'}
        </Title>
      </TitleContainer>

      <SelectInput
        id="vaccine"
        borderRadius='8px'
        color='#000000'
        options={availableRules}
        initialValue={t('UPLOAD_PASSE_VERDE_DOCS_SELECT_TEXT')}
        isSubmitting={isSubmitting}
        onChange={(event) => handleChange(event.target.value)}
        value={selectedValidationRules}
        marginBottom={'40px'}
      />

      {isLoading ?
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
        :
        <>
          {
            requiredDocuments !== undefined &&
            protocols.map((protocol, index) => {
              const doc = protocol.ruleDescription;
              const approved = requiredDocuments?.approved.find((document) => document.name === doc)

              if (!approved || approved.name === 'PROTOCOL REQUIRE APPROVED SELFIE' ||
                approved.name === 'PROTOCOL REQUIRE APPROVED ID DOC') {
                return null
              }

              return (
                <BoxUploadContainer key={index}>
                  <ApprovedDocContainer>
                    <ApprovedDocTitle>{doc}</ApprovedDocTitle>
                    <CheckIcon className={classes.checkIcon} />
                  </ApprovedDocContainer>
                  <DottedDivider />
                </BoxUploadContainer>
              )
            })
          }
          {
            requiredDocuments !== undefined &&
            protocols.map((protocol, index) => {
              const doc = protocol.ruleDescription;
              const requiredDocs = requiredExamOrCertificates();
              const required = requiredDocs?.find((document) => document.name === doc);

              if (!required) {
                return null
              }

              return (
                <BoxUploadContainer key={index}>
                  <BoxUploadFile
                    dateComponent={
                      <DateInput
                        value={
                          examOrCertificationDate?.[doc] ?
                            examOrCertificationDate?.[doc] :
                            null
                        }
                        isSubmitting={isSubmitting}
                        onChange={
                          (event) => handleChangeDate(event, doc)
                        }
                        label={
                          protocol.referenceType === 'VACCINE'
                            ? t('UPLOAD_PASSE_VERDE_DOCS_APPLICATION_DATE')
                            : protocol.referenceType === 'LOCATOR_FORM'
                              ? t('UPLOAD_PASSE_VERDE_DOCS_FILL_DATE')
                              : t('UPLOAD_PASSE_VERDE_DOCS_COLLECTION_DATE')
                        }
                      />
                    }
                    marginTop={10}
                    id={`document-file-uploader-${doc}`}
                    title={doc}
                    buttonText={t('UPLOAD_PASSE_VERDE_DOCS_BUTTON_UPLOAD')}
                    buttonTextWhenDocumentUploaded={t('UPLOAD_PASSE_VERDE_DOCS_UPLOADED_FILE_BUTTON_TEXT')}
                    documentUploadedText={t('UPLOAD_PASSE_VERDE_DOCS_UPLOADED_FILE_TEXT')}
                    isLoading={loadingCovidImage}
                    isSubmitting={isSubmitting}
                    displayOnly={covidCertImages[doc]}
                    onChange={(file) => handleUpload(file, doc)}
                  />
                  <DottedDivider />
                </BoxUploadContainer>
              )
            })
          }
          {
            requiredDocuments !== undefined ?
              !user.approvedID &&
              event.shouldRequestIDDocUpload &&
              <>
                <BoxUploadFile
                  marginTop={15}
                  title={t('UPLOAD_PASSE_VERDE_DOCS_DOCUMENT_UPLOAD_TITLE')}
                  componentType="photo"
                  buttonText={t('UPLOAD_PASSE_VERDE_DOCS_DOCUMENT_UPLOAD_BUTTON_TEXT')}
                  buttonTextWhenDocumentUploaded={t('UPLOAD_PASSE_VERDE_DOCS_UPLOADED_FILE_BUTTON_TEXT')}
                  documentUploadedText={t('UPLOAD_PASSE_VERDE_DOCS_UPLOADED_FILE_TEXT')}
                  isLoading={loadingCovidImage}
                  isSubmitting={isSubmitting}
                  displayOnly={covidCertImages["userDocId"]}
                  id={"document-file-uploader-userDocId"}
                  onChange={(file) =>
                    handleUpload(file, "userDocId")
                  }
                />
                <DottedDivider />
              </>
              : null
          }
          {
            requiredDocuments !== undefined ?
              user.approvedID &&
              event.shouldRequestIDDocUpload &&
              <BoxUploadContainer>
                <ApprovedDocContainer>
                  <ApprovedDocTitle>
                    {t('UPLOAD_PASSE_VERDE_DOCS_DOCUMENT_UPLOAD_TITLE')}
                  </ApprovedDocTitle>
                  <CheckIcon className={classes.checkIcon} />
                </ApprovedDocContainer>
                <DottedDivider />
              </BoxUploadContainer>
              : null
          }
          {
            requiredDocuments !== undefined ?
              !user.approvedSelfie &&
              event.shouldRequestSelfieUpload &&
              <>
                <BoxUploadFile
                  marginTop={15}
                  title={t('UPLOAD_PASSE_VERDE_DOCS_SELFIE_UPLOAD_TITLE')}
                  componentType="photo"
                  buttonText={t('UPLOAD_PASSE_VERDE_DOCS_SELFIE_UPLOAD_BUTTON_TEXT')}
                  buttonTextWhenDocumentUploaded={t('UPLOAD_PASSE_VERDE_DOCS_UPLOADED_FILE_BUTTON_TEXT')}
                  documentUploadedText={t('UPLOAD_PASSE_VERDE_DOCS_UPLOADED_FILE_TEXT')}
                  onlyImage={true}
                  isLoading={loadingCovidImage}
                  isSubmitting={isSubmitting}
                  displayOnly={covidCertImages["userSelfie"]}
                  id={"document-file-uploader-userSelfie"}
                  onChange={(file) =>
                    handleUpload(file, "userSelfie")
                  }
                />
                <DottedDivider />
              </>
              : null
          }
          {
            requiredDocuments !== undefined ?
              user.approvedSelfie &&
              event.shouldRequestSelfieUpload &&
              <BoxUploadContainer>
                <ApprovedDocContainer>
                  <ApprovedDocTitle>
                    {t('UPLOAD_PASSE_VERDE_DOCS_SELFIE_UPLOAD_TITLE')}
                  </ApprovedDocTitle>
                  <CheckIcon className={classes.checkIcon} />
                </ApprovedDocContainer>
                <DottedDivider />
              </BoxUploadContainer>
              : null
          }
          {
            cantSubmit ?
              <Button className={classes.buttonDisabled}>
                {t('UPLOAD_PASSE_VERDE_DOCS_BUTTON_CONTINUE')}
              </Button>
              :
              <Button
                className={classes.button}
                onClick={() => setOpenCOVID19ModalDataVerification(true)}>
                {t('UPLOAD_PASSE_VERDE_DOCS_BUTTON_CONTINUE')}
              </Button>
          }
        </>
      }

      <Modal
        aria-labelledby="term-of-responsibility-modal-title"
        aria-describedby="term-of-responsibility-modal-description"
        className={classes.modal}
        open={openTCModal}
        onClose={() => setOpenTCModal(false)}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openTCModal}>
          <div className={classes.modalPaper}>
            <Typography
              className={classes.modalHeader}
              variant='h6'
            >
              {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_1_TITLE')}
            </Typography>
            <Typography className={classes.tocModalContent}>
              {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_1_DESCRIPTION')}
            </Typography>
            <FormControlLabel
              className={classes.tocModalFormControlLabel}
              control={
                <Checkbox
                  checked={modalState.termsAndConditions}
                  onChange={() =>
                    setModalState((oldModalState) => ({
                      ...oldModalState,
                      termsAndConditions: !oldModalState.termsAndConditions,
                    }))
                  }
                  name="checkedFirst"
                />
              }
              label={t('UPLOAD_PASSE_VERDE_DOCS_MODAL_1_CONFIRMATION_TEXT')}
            />
            <div className={classes.modalButtonContainer}>
              <Button
                className={classes.modalBackButton}
                onClick={() => {
                  setOpenTCModal(false);
                  window.location.replace("/secure");
                }}
              >
                {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CANCEL')}
              </Button>
              <Button
                style={{
                  backgroundColor: !modalState.termsAndConditions
                    ? "#8D8D8D"
                    : "#FF0066",
                  color: "#FFFFFF",
                }}
                disabled={!modalState.termsAndConditions}
                onClick={() => {
                  setOpenTCModal(false);
                }}
              >
                {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CONFIRM')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
        className={classes.modal}
        open={openCOVID19ModalDataVerification}
        onClose={() => setOpenCOVID19ModalDataVerification(false)}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCOVID19ModalDataVerification}>
          <div className={classes.confirmationModalPaper}>
            <Typography
              className={classes.confirmationModalHeader}
              variant='h6'
            >
              {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_2_TITLE')}
            </Typography>
            <Typography className={classes.confirmationModalContent}>
              {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_2_DESCRIPTION')}
            </Typography>
            <FormControlLabel
              className={classes.confirmationModalFormControlLabel}
              control={
                <Checkbox
                  checked={modalState.secondChecked}
                  onChange={() =>
                    setModalState((oldModalState) => ({
                      ...oldModalState,
                      secondChecked: !oldModalState.secondChecked,
                    }))
                  }
                  name="checkedFirst"
                />
              }
              label={t('UPLOAD_PASSE_VERDE_DOCS_MODAL_2_CONFIRMATION_TEXT')}
            />
            <div className={classes.modalButtonContainer}>
              <Button
                className={classes.modalBackButton}
                onClick={() => {
                  setOpenCOVID19ModalDataVerification(false);
                }}
              >
                {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CANCEL')}
              </Button>
              <Button
                style={{
                  backgroundColor:
                    !modalState.secondChecked || isSubmitting
                      ? "#8D8D8D"
                      : "#FFFFFF",
                  color:
                    !modalState.secondChecked || isSubmitting
                      ? "#FFFFFF"
                      : "#00A859",
                }}
                disabled={!modalState.secondChecked || isSubmitting}
                onClick={handleSubmit}
              >
                {
                  isSubmitting ?
                    t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CONFIRM_SUBMITTING')
                    :
                    t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CONFIRM')
                }
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="documents-sent-modal-title"
        aria-describedby="documents-sent-modal-description"
        className={classes.modal}
        open={openCOVID19ModalFinal}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCOVID19ModalFinal}>
          <div className={classes.modalPaper}>
            <Typography
              className={classes.modalHeader}
              variant='h6'
            >
              {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_3_TITLE')}
            </Typography>
            <Typography className={classes.finalModalContent}>
              {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_3_DESCRIPTION')}
            </Typography>

            <div className={classes.finalModalButtonContainer}>
              <Button
                style={{
                  backgroundColor: "#FF0066",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  setOpenCOVID19ModalFinal(false);
                  window.location.replace("/secure/passe-verde");
                }}
              >
                {t('UPLOAD_PASSE_VERDE_DOCS_MODAL_3_BUTTON')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      <FeedbackModal
        id="submit-error"
        title={{
          text: t('UPLOAD_PASSE_VERDE_SUBMIT_ERROR_TITLE')
        }}
        description={{
          text: t('UPLOAD_PASSE_VERDE_SUBMIT_ERROR')
        }}
        button={{
          text: t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CANCEL')
        }}
        open={isOpenSubmitErrorModal}
        onClick={() => setIsOpenSubmitErrorModal(false)}
      />

      <FeedbackModal
        id="submit-error-selfie-error"
        title={{
          text: t('UPLOAD_PASSE_VERDE_SUBMIT_ERROR_TITLE')
        }}
        description={{
          text: t('UPLOAD_PASSE_VERDE_SUBMIT_SELFIE_ERROR')
        }}
        button={{
          text: t('UPLOAD_PASSE_VERDE_DOCS_MODAL_CANCEL')
        }}
        open={isOpenSelfieErrorModal}
        onClick={() => {
          setIsOpenSelfieErrorModal(false)
          window.location.replace("/secure/passe-verde");
        }}
      />
    </Container>
  );
}

export default PasseVerdeUpload;