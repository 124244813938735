import styled from 'styled-components';

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.h3`
  width: calc(100% - 40px);
  text-align: center;
  margin-bottom: 20px;
  margin-top: 90px;
`;

const Title = styled.h3`
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 23px;
  font-weight: bold;
  color: #00A859;
`;

const DottedDivider = styled.div`
  width: calc(100% - 40px);
  border-top: 1px dotted #808080;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const BoxUploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Button = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #00A859;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: .2s;
  cursor: pointer;
`;

const ApprovedDocContainer = styled.div`
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ApprovedDocTitle = styled.h6`
  font-size: 22px;
  color: #00A859;
  margin: 0px;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export {
  Container,
  TitleContainer,
  Title,
  CenterColumn,
  DottedDivider,
  BoxUploadContainer,
  Button,
  ApprovedDocContainer,
  ApprovedDocTitle,
}