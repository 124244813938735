import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 40px)',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '20px',
  },

  selectInput: {
    width: '100%',
    height: '32px',
    border: 'none',
    backgroundColor: '#FFFFFF !important',
  },
}));

const SelectInput = ({
  id,
  onChange,
  value,
  initialValue,
  isLoading,
  isSubmitting,
  options,
  marginTop,
  marginBottom,
  borderRadius,
  color
}) => {
  const classes = useStyles();
  const optionsToShow = options || [];

  return (
    <Paper
      component="form"
      className={classes.container}
      style={{
        marginTop: marginTop ? `${marginTop}` : 0,
        marginBottom: marginBottom ? `${marginBottom}` : 0,
        borderRadius: borderRadius ? `${borderRadius}` : '23px',
        cursor: isSubmitting ? 'default' : 'pointer'
      }}
    >
      <select
        id={id}
        className={classes.selectInput}
        value={value}
        disabled={isLoading || isSubmitting}
        onChange={onChange}
        style={{
          color: color ? `${color}` : '#808080',
          cursor: isSubmitting ? 'default' : 'pointer'
        }}
      >
        {initialValue && <option key={initialValue} value="">{initialValue}</option>}
        {optionsToShow.map((option, index) => {
          return <option key={index} value={option.id}>{option.name}</option>
        })}
      </select>
    </Paper >
  );
}

export default SelectInput;