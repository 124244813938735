import styled from "styled-components";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 15px 30px 15px;
  box-sizing: border-box;
`;

export {
  ImageContainer,
};
