import React from 'react';
import AccordionIcon from '../AccordionIcon/AccordionIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    borderRadius: '23px',
    padding: '10px 15px',
    boxShadow: '1px 5px 10px 0 rgb(16 36 94 / 8%)',
    cursor: 'pointer',
    minHeight: 45,
  },

  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#2D80C6',
    marginTop: '0px',
    marginBottom: ' 0px',
  }
}));

const AccordionSummary = ({
  id,
  onClick,
  title,
  expandedPanel,
  panel,
}) => {

  const classes = useStyles();

  return (
    <div
      id={id}
      onClick={onClick}
      className={classes.container}
    >
      <h4 className={classes.title}>{title}</h4>
      <AccordionIcon expandedPanel={expandedPanel} panel={panel} />
    </div>
  )
}

export default AccordionSummary;