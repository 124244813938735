export const availableRegions = [
  {
    name: 'FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1',
    value: 'region1',
  },
  {
    name: 'FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2',
    value: 'region2',
  },
];

export const tocRegion1 = [
  {
    title: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TITLE_1",
    content: [
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_1",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_1"],
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_2",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_2"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_3",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_3"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_4",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_4"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_5",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_5"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_6",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_6"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_7",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_7"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_8",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_8"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_9",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_9"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_10",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_10"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_11",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_11"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_12",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_12"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_13",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_13"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_14",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_14"],
          },
        ],
      },
    ],
  },
  {
    title: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TITLE_15",
    text: [
      "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_15",
      "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_16",
      "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_17",
    ],
    content: [
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_18",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_18"],
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_19",
            text: [
              "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_19",
              "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_20"
            ],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_21",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_21"],
          },
        ],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_22",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_22"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_23",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_23"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_24",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_24"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_25",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_25"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_26",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_26"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_27",
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_27",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_27"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_28",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_28"],
          },
        ],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_29",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_29"],
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_30",
            text: [
              "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_30",
              "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_31",
            ],
          },
        ],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_32",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_32"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_33",
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_33",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_33"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_34",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_34"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_35",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_35"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_36",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_36"],
          },
        ],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_37",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_37"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_38",
        text: [
          "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_38",
          "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_39"
        ],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_40",
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_40",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_40"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_41",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_41"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_42",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_42"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_43",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_43"],
          },
        ]
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_44",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_44"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_45",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_45"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_46",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_46"],
      },
    ],
  },
  {
    title: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TITLE_47",
    content: [
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_47",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_47"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_48",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_48"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_49",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_49"],
        headerContent: [
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_50",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_50"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_51",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_51"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_52",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_52"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_53",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_53"],
          },
          {
            subheader: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_SUBHEADER_54",
            text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_54"],
          },
        ],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_55",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_55"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_HEADER_56",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_1_TXT_56"],
      },
    ],
  },
];

export const tocRegion2 = [
  {
    title: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TITLE_1",
    content: [
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_1",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_1"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_2",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_2"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_3",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_3"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_4",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_4"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_5",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_5"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_6",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_6"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_7",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_7"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_8",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_8"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_9",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_9"],
      },
      {
        header: "FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_HEADER_10",
        text: ["FRONT_END_TERMS_AND_CONDITIONS_PAGE_REGION_2_TXT_10"],
      },
    ],
  },
];