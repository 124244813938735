import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: 5,
    marginTop: 12,
  },

  label: {
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#505050',
  },

  radioGroup: {
    marginLeft: 5,
    '& .MuiRadio-root': {
      width: 20,
      height: 20,
    },

    '& .MuiFormControlLabel-label': {
      color: '#505050',
      fontSize: 15,
    },
  },
}));

export const FormEntryOptions = ({
  name,
  label,
  options,
  value,
  onChange,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.container}>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        className={classes.radioGroup}
      >
        {options?.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio checked={value === option.value} disabled={disabled} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}