const monthMap = {
  '1': 'Jan',
  '2': 'Fev',
  '3': 'Mar',
  '4': 'Abr',
  '5': 'Mai',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Ago',
  '9': 'Set',
  '10': 'Out',
  '11': 'Nov',
  '12': 'Dez',
};

const dateConfig = {
  'date': {
    format: 'DD',
    caption: 'Dia',
    step: 1,
  },
  'month': {
    format: value => monthMap[value.getMonth() + 1],
    caption: 'Mês',
    step: 1,
  },
  'year': {
    format: 'YYYY',
    caption: 'Ano',
    step: 1,
  },
};

export {
  dateConfig,
}