import React, { useEffect } from 'react';
import { Container, Text, Time } from './Components';

const Timer = ({ time, setTime, init, expireMoment, getRemainingTime, translations }) => {

  let ID;

  if (init) {
    ID = setTimeout(() => {
      if (time) {
        const remainingTime = getRemainingTime(expireMoment);
        setTime(remainingTime);
      }
    }, 1000);
  } else {
    clearTimeout(ID);
  }

  useEffect(() => {
    return () => clearTimeout(ID);
  }, [ID]);

  return (
    <Container>
      <Text>
        {init && time
          ? `${translations('EXPIRES_IN')}:`
          : translations('NO_CODE_YET')}
      </Text>
      <Time>
        {init && time && (
          <span>
            {Math.floor(time / 60)}{' '}
            {' ' + translations('MINUTES')},{' '}
            {time - Math.floor(time / 60) * 60}{' '}
            {' ' + translations('SECONDS')}
          </span>
        )}
      </Time>
    </Container>
  );
};

export default Timer;
