import React, {
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import {
  Container,
  Content,
  CenterColumn,
  Title,
  Text,
  RedirectContainer,
} from "./Components.js";
import Header from "../../components/Header/Header";
import SearchInput from "../../components/SearchInput/SearchInput";
import HealthDigitalReccord from "../../components/HealthDigitalReccord/HealthDigitalReccord";
import { RedirectButton } from "../../components/RedirectButton/index.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { request } from '../../utils/request';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { getUser, setUser } from "../../utils/user/index.js";

const DigitalCertificates = () => {
  const { t } = useTranslation('examsPage');
  const user = getUser();

  const userExams = user?.exams;

  const [documents, setDocuments] = useState([]);
  const [hasEnterprise, setHasEnterprise] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');

  const handleChange = (event) => {
    setSearch(event.target.value);
  }

  const userAvailableCertificates = useCallback(() => {
    const checkedDocuments = [];
    const certificatesList = [];

    if (documents?.length < 1) {
      return certificatesList;
    };

    documents.forEach(document => {
      const alreadyHasThisDoc = checkedDocuments.find(checkedDocument => checkedDocument.name === document.name);

      if (!alreadyHasThisDoc) {
        checkedDocuments.push(document);
        return;
      }

      if (moment(document.datetime).isAfter(alreadyHasThisDoc.datetime)) {
        const index = checkedDocuments.indexOf(alreadyHasThisDoc);
        checkedDocuments[index] = document;
      }
    });

    (userExams || []).forEach(exam => {
      const formattedExam = {
        id: exam._id,
        datetime: exam.collectDate,
        fileUrl: exam.file?.url,
        name: exam.exam_name,
        type: 'EXAM',
      };

      checkedDocuments.push(formattedExam);
    });

    checkedDocuments.forEach((document, index) => {

      if (document.currentPasseVerde) {
        return certificatesList.push(
          <HealthDigitalReccord
            codeType="covid_certificate"
            documentId={document.id}
            documentName={`Passe Verde - ${t('CERTIFICATE')}`}
            uploadDate={document.updatedAt}
            key={`Passe Verde - ${t('CERTIFICATE')}`}
          />)
      };

      if (document.type === 'LOCATOR_FORM') {
        return certificatesList.push(
          <HealthDigitalReccord
            codeType="locator_form"
            documentId={document.id}
            documentName={document.name}
            url={document.fileUrl}
            key={`${index} ${document.name}`}
          />)
      };

      if (document.type === 'EXAM') {
        return certificatesList.push(
          <HealthDigitalReccord
            codeType="exam"
            documentId={document.id}
            documentName={document.name}
            uploadDate={document.datetime}
            url={document.fileUrl}
            key={`${index} ${document.name}`}
          />)
      };

      certificatesList.push(
        <HealthDigitalReccord
          codeType="pv_document"
          documentId={document.id}
          documentName={document.name}
          uploadDate={document.datetime}
          url={document.fileUrl}
          key={`${index} ${document.name}`}
        />)
    });

    return certificatesList;
  }, [documents, t, userExams])

  const certificatesToRender = userAvailableCertificates();
  const certificatesFiltered = useMemo(() => {
    if (certificatesToRender.length > 0) {
      const lowerSearch = search.toLowerCase();
      return certificatesToRender
        .filter((document) => document.key.toLowerCase().includes(lowerSearch))
    }
  }, [certificatesToRender, search]);

  useEffect(() => {
    const getUserDocuments = async () => {
      const promises = [
        request.get("/client-app/me"),
        request.get(`/client-app/user-documents`),
        request.get(`/client-app/find-user-enterprises`),
      ];

      Promise.all(promises)
        .then(responses => {
          const [
            user,
            documents,
            userEnterprises,
          ] = responses;

          setUser(user.data);
          setDocuments(documents.data);

          if (!userEnterprises.data?.length) {
            return;
          }

          setHasEnterprise(true);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    };

    getUserDocuments();
  }, []);

  return (
    <Container>
      <Header backgroundStyle="PINK" />
      <Title>
        {t('TITLE')}
      </Title>
      <SearchInput
        placeholder={t('SEARCH_PLACEHOLDER')}
        onChange={handleChange}
        value={search}
        endIcon
      />
      {isLoading ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        <>
          {!certificatesFiltered?.length ? (
            <CenterColumn>
              <Text>
                {t('EXAMS_OR_CERTIFICATES_NOT_FOUND')}
              </Text>
            </CenterColumn>
          ) : (
            <Content>
              {certificatesFiltered}
            </Content>
          )}

          <RedirectContainer>
            <RedirectButton
              title={t('UPLOADED_VACCINATIONS_CARD')}
              titleColor="#FF0066"
              href="/secure/my-health/my-vaccinations-card/consult-vaccinations-card"
            />

            {hasEnterprise && (
              <RedirectButton
                title={t('UPLOADED_MEDICAL_CERTIFICATE')}
                titleColor="#FF0066"
                href="/secure/my-health/medical-certificate/consult-certificate"
              />
            )}
          </RedirectContainer>
        </>
      )}
    </Container>
  );
};

export default DigitalCertificates;
