import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  IconButton,
  makeStyles
} from "@material-ui/core";
import {
  Share as ShareIcon,
  CheckCircle as CheckIcon,
} from "@material-ui/icons";
import {
  CardWrapper,
  DocumentDescription,
  ButtonWrapper
} from "./Components";
import axios from "axios";
import FileDownload from "js-file-download";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  docName: {
    fontSize: 15,
    color: "#000000",
    fontWeight: "bold",
  },

  date: {
    fontSize: 12,
    color: "#A4A4A4",
    marginTop: ".2rem",
  },

  overflow: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  covidCertificateStatusText: {
    fontSize: 15,
    fontWeight: "bold",
  },

  covidCertificateStatusIcon: {
    width: "16px",
    marginLeft: 5
  },
}));

const HealthDigitalReccord = ({
  documentId,
  documentName,
  uploadDate,
  codeType,
  url,
}) => {
  const { t } = useTranslation('examsPage');
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const colormap = {
    APPROVED: "#00A859",
  };

  const history = useHistory();

  const handleShareDocument = (documentId, documentName, codeType) => {
    history.push({
      pathname: "/secure/share-document",
      state: { id: documentId, name: documentName, codeType }, // document metadata
    });
  };

  const handleDownloadDocument = async (url, documentName) => {
    setIsLoading(true);
    try {
      const documentInfoArray = url.split('.');
      const documentExtension = documentInfoArray[documentInfoArray.length - 1];

      axios.get(url, {
        responseType: 'blob',
      }).then((res) => {
        FileDownload(res.data, `${documentName}.${documentExtension}`)
      })
    } catch (err) {
      console.log(`[handleDownloadDocument Error]: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CardWrapper>
      {
        codeType === "covid_certificate" &&
        <>
          <img src="/assets/syringe-pink.svg" alt="pink syringe" style={{ marginRight: 10 }} />
          <DocumentDescription>
            <Typography className={classes.docName}>
              {documentName}
            </Typography>
            <Typography className={[classes.date, classes.overflow]}>
              {t('DATE')}:
              {" "}
              {uploadDate}
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.covidCertificateStatusText}
                style={{ color: colormap['APPROVED'] }}
              >
                {
                  t('CERTIFICATION_APPROVED')
                }
              </Typography>

              <CheckIcon
                className={classes.covidCertificateStatusIcon}
                htmlColor={colormap['APPROVED']}
              />
            </div>
          </DocumentDescription>
        </>
      }
      {
        codeType === 'locator_form' &&
        <>
          <img src="/assets/syringe-pink.svg" alt="pink syringe" style={{ marginRight: 10 }} />
          <DocumentDescription>
            <Typography className={classes.docName}>
              {documentName}
            </Typography>
          </DocumentDescription>
        </>
      }
      {
        (codeType === 'pv_document' || codeType === 'exam') &&
        <>
          <img src="/assets/syringe-pink.svg" alt="pink syringe" style={{ marginRight: 10 }} />
          <DocumentDescription>
            <Typography className={classes.docName}>
              {documentName}
            </Typography>
            <Typography className={[classes.date, classes.overflow]}>
              {t('DATE')}:{" "}
              {uploadDate}
            </Typography>
          </DocumentDescription>
        </>
      }

      {codeType === "covid_certificate" && (
        <ButtonWrapper>
          <div>
            <IconButton
              aria-label="share"
              color="primary"
              onClick={() =>
                handleShareDocument(documentId, documentName, codeType)
              }
            >
              <ShareIcon htmlColor="#FF0066" />
            </IconButton>
          </div>
        </ButtonWrapper>
      )}

      {codeType === "exam" && (
        <ButtonWrapper>
          <div>
            <IconButton
              aria-label="download"
              color="primary"
              disabled={isLoading}
              onClick={() => handleDownloadDocument(url, documentName)}
            >
              <img src="/assets/pdf-pink.svg" alt="Pink PDF icon" />
            </IconButton>
          </div>
        </ButtonWrapper>

      )}

      {codeType !== "covid_certificate" && codeType !== "exam" && (
        <ButtonWrapper>
          <IconButton
            aria-label="download"
            color="primary"
            disabled={isLoading}
            onClick={() => handleDownloadDocument(url, documentName)}
          >
            <img src="/assets/pdf-pink.svg" alt="Pink PDF icon" />
          </IconButton>

          <IconButton
            aria-label="share"
            color="primary"
            onClick={() =>
              handleShareDocument(documentId, documentName, codeType)
            }
          >
            <ShareIcon htmlColor="#FF0066" />
          </IconButton>
        </ButtonWrapper>
      )}
    </CardWrapper>
  );
};

export default HealthDigitalReccord;
