import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 4px',
    marginBottom: '20px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    width: '31%'
  },
  text: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center'
  }
}));

export default function VaccinationTimeline({ vaccines }) {
  const { t } = useTranslation('calendarPage');
  const classes = useStyles();

  const getVaccinesByTime = (unit) => {
    const vaccinesByWeek = vaccines?.filter((vac) => vac.timeUnit === unit);
    let items = [];
    vaccinesByWeek.forEach(vac => {
      const timeUnitFound = items.findIndex(timeUnit => vac.timeNumber === timeUnit.number);
      if (timeUnitFound === -1) {
        items.push({
          number: Number(vac.timeNumber),
          vaccines: [
            vac.name,
          ],
          days: vac.days,
          unit: unit.toUpperCase()
        });
      } else {
        items[timeUnitFound].vaccines.push(vac.name);
      }
    });
    return items;
  }

  const renderVaccinesByTimeline = (items, isFinal = false) => {
    return items.map((timeUnit, index) => (
      <TimelineItem key={`${timeUnit.unit}-${index}`}>
        <TimelineOppositeContent>
          <Typography variant="subtitle2" color="textSecondary">
            {
              timeUnit.number} {timeUnit.number >= 2 ?
                `${t(timeUnit.unit + 'S')}`
                : `${t(timeUnit.unit)}`
            }
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <img src="/assets/syringe-orange.svg" alt="Orange Syringe Icon" />
          {(index === (items.length - 1) && isFinal) ? null : <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Paper elevation={3} className={classes.paper}>
            {timeUnit.vaccines.map((vac, vacIndex) => <Typography className={[classes.text, 'lineclamp'].join(' ')} key={`${timeUnit.unit}-${index}-vac-${vacIndex}`}>{vac}</Typography>)}
          </Paper>
        </TimelineContent>
      </TimelineItem>
    ));
  }

  const mergeAndSortVaccines = (weeksVaccine = [], monthsVaccine = [], yearsVaccine = []) => {
    try {
      const mergedVaccines = [...weeksVaccine, ...monthsVaccine, ...yearsVaccine];
      const sortedVaccinesArray = mergedVaccines.sort((a, b) => a.days - b.days)
      return sortedVaccinesArray;
    } catch (e) {
      return []
    }
  }

  const renderTimeLine = () => {

    const weeksVaccine = getVaccinesByTime('week');
    const monthsVaccine = getVaccinesByTime('month');
    const yearsVaccine = getVaccinesByTime('year');

    const finalVaccinesArray = mergeAndSortVaccines(weeksVaccine, monthsVaccine, yearsVaccine)

    return (
      <Timeline align="alternate">
        {renderVaccinesByTimeline(finalVaccinesArray, true)}
      </Timeline>
    )
  }


  return (
    renderTimeLine()
  );
}