import React, { useState } from "react";
import {
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button
} from "@material-ui/core";
import {
  FileCopy as FileIcon,
  CheckCircle as CheckIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Warning as WarningIcon,
  KeyboardArrowDown as ChevronDownIcon,
  GetApp as DownloadIcon,
} from "@material-ui/icons";
import {
  DocumentDescription,
} from "./Components";
import FileDownload from "js-file-download";
import axios from "axios";

const useStyles = makeStyles(() => ({
  accordion: {
    '&.MuiAccordion-root': {
      marginTop: 20,
      borderRadius: 8,
    },

    '&.MuiAccordion-root:before': {
      display: 'none',
    },

    '&.MuiAccordion-root.Mui-expanded:first-child': {
      marginTop: 20,
    }
  },

  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
  },

  fileIcon: {
    fontSize: 38,
    marginRight: 10,
    color: '#2D80C6',
  },

  docName: {
    fontSize: 15,
    color: "#000000",
    fontWeight: "bold",
  },

  docInfo: {
    fontSize: 12,
    color: "#A4A4A4",
    marginTop: ".2rem",
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  statusText: {
    fontSize: 15,
    fontWeight: "bold",
  },

  statusIcon: {
    width: "16px",
    marginLeft: 5
  },

  observationTitle: {
    fontSize: '.8rem',
    fontWeight: 'bold',
    color: "#808080",
    marginBottom: 20,
  },

  observationText: {
    fontSize: '.8rem',
    color: "#808080",
  },

  filesTitle: {
    fontSize: '.9rem',
    fontWeight: 'bold',
    color: "#808080",
    marginBottom: 5,
  },

  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 1px',
    textTransform: 'none',
    marginBottom: 5,
  },

  fileName: {
    color: "#808080",
    fontSize: '.8rem',
  },

  downloadIcon: {
    width: "20px",
    color: "#808080",
    marginLeft: 10,
  },
}));

const colormap = {
  VERIFIED: "#00A859",
  PENDING: "#F69103",
  REDO: "#F69103",
  DENIED: "#F60E31",
  ALERT: "#F60E31",
};

const VaccinationCardAccordion = ({
  documentName,
  uploadDate,
  uploadDateLabel,
  status,
  filesTitle,
  files = [],
  statusText,
  analystObservationLabel,
  analystObservation,
}) => {
  const classes = useStyles();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadDocument = async (url, documentName) => {
    setIsDownloading(true);
    try {
      axios.get(url, {
        responseType: 'blob',
      }).then((res) => {
        FileDownload(res.data, `${documentName}`)
      })
    } catch (err) {
      console.log(`[handleDownloadDocument Error]: ${err}`);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
      >
        <Box className={classes.accordionSummaryContent}>
          <FileIcon className={classes.fileIcon} />
          <DocumentDescription>
            <Typography className={classes.docName}>
              {documentName}
            </Typography>
            <Typography className={classes.docInfo}>
              {uploadDateLabel}: {uploadDate}
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.statusText}
                style={{ color: colormap[status] }}
              >
                {statusText}
              </Typography>

              {
                status === 'PENDING' ?
                  <HourglassEmptyIcon
                    className={classes.statusIcon}
                    htmlColor={colormap[status]}
                  />
                  : status === 'VERIFIED' ?
                    <CheckIcon
                      className={classes.statusIcon}
                      htmlColor={colormap[status]}
                    />
                    : <WarningIcon
                      className={classes.statusIcon}
                      htmlColor={colormap[status]}
                    />
              }
            </div>
          </DocumentDescription>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {status === 'REDO' && (
            <Typography className={classes.observationTitle}>
              {analystObservationLabel}:
              {' '}
              <Typography
                component="span"
                className={classes.observationText}
              >
                {analystObservation}
              </Typography>
            </Typography>
          )}

          <Box>
            <Typography className={classes.filesTitle}>
              {filesTitle}
            </Typography>

            {files.length > 0 && files.map(file => {
              const removedUrl = file.split('.com/')[1];
              const splittedRemovedUrl = removedUrl.split('_');
              const hash = splittedRemovedUrl[splittedRemovedUrl.length - 1].split('.')[0];
              const newFileNameWithoutHash = splittedRemovedUrl
                .join('_')
                .replace(`_${hash}`, '')
                .split('_')
                .join(' ');

              return (
                <Button
                  key={file}
                  disabled={isDownloading}
                  onClick={() => handleDownloadDocument(file, newFileNameWithoutHash)}
                  className={classes.downloadButton}
                >
                  <Typography className={classes.fileName}>
                    {newFileNameWithoutHash}
                  </Typography>
                  <DownloadIcon className={classes.downloadIcon} />
                </Button>
              )
            })}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default VaccinationCardAccordion;
