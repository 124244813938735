import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { StyledLink } from './Components';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '23px',
    padding: '10px 15px',
    boxShadow: '1px 5px 10px 0 rgb(16 36 94 / 8%)',
    minHeight: 45,
  },

  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '0px',
    marginBottom: ' 0px',
  }
}));

export const RedirectButton = ({
  href,
  disabled,
  title,
  titleColor = '#2D80C6',
  backgroundColor = '#FFFFFF',
  marginTop = "0px",
  marginBottom = "0px",
}) => {

  const classes = useStyles();

  return !disabled ? (
    <StyledLink
      to={href}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <Box
        className={classes.container}
        style={{
          backgroundColor: backgroundColor,
          cursor: 'pointer',
        }}
      >
        <Typography
          component="h4"
          className={classes.title}
          style={{ color: titleColor }}
        >
          {title}
        </Typography>
      </Box>
    </StyledLink>
  ) : (
    <Box
      className={classes.container}
      style={{
        marginBottom,
        marginTop,
        backgroundColor: backgroundColor,
        cursor: 'not-allowed'
      }}
    >
      <Typography
        component="h4"
        className={classes.title}
        style={{ color: titleColor }}
      >
        {title}
      </Typography>
    </Box>
  )
};