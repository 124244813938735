import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 5,
  },

  label: {
    cursor: 'pointer',
  },

  labelContent: {
    margin: '12px 0px 8px 0px',
    fontWeight: 'bold',
    color: '#505050',
  },

  input: {
    width: '100%',
    color: '#505050',
    backgroundColor: '#FFFFFF',
    padding: '8px 12px',
    borderRadius: '8px',
    minHeight: '45px',
    boxSizing: 'border-box',
    boxShadow: '0 0px 10px 0px rgb(16 36 94 / 7%)',
  },
}));

const FormEntryText = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  type,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <label htmlFor={id} className={classes.label}>
        <p className={classes.labelContent}>{label}</p>
      </label>
      <input
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        type={type ? type : "text"}
        disabled={disabled}
        placeholder={placeholder}
        className={classes.input}
        style={{ border: error ? '1px solid red' : 'none' }}
      />
    </div>
  )
}

export default FormEntryText;