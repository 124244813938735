import React from 'react';

const AccordionDetails = ({
  children,
  expandedPanel,
  panel,
  height,
  transitionDuration,
  paddingBottom,
}) => {

  const isOpen = expandedPanel === panel;

  return (
    <div
      style={{
        transition: transitionDuration ? transitionDuration : '350ms',
        overflow: 'hidden',
        height: isOpen ? height : 0,
        maxHeight: isOpen ? 4680 : 0,
        visibility: isOpen ? 'visible' : 'hidden',
        boxSizing: isOpen ? 'border-box' : 'none',
        paddingBottom: isOpen ? paddingBottom : 0,
      }}
    >
      {children}
    </div>
  )
}

export default AccordionDetails;