import React from 'react';
import {
  CodeWrapper,
  Digit
} from './Components';

const Code = ({
  code,
  onCodeColor,
}) => {

  const codeArr = (code || 'XXXXXX').split('');

  return (
    <CodeWrapper>
      {codeArr[0] !== 'X' ?
        codeArr.map((digit, index) => {
          return <Digit
            key={`${digit}-${index}`}
            style={{ color: onCodeColor ? onCodeColor : '#6847D8' }}
          >
            {digit}
          </Digit>
        }) :
        codeArr.map((digit, index) => {
          return <Digit
            key={`${digit}-${index}`}
            style={{ color: '#808080' }}
          >
            {digit}
          </Digit>
        })
      }
    </CodeWrapper>
  )

}

export default Code;