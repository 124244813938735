import styled from 'styled-components';


const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`
const Digit = styled.div`
  height: 41px;
  border-radius: 5px;
  color: #808080;
  font-size: 14px;
  font-weight: bold;
  line-height: 41px;
  background-color: #D4D4D4;
  text-align: center;
  width: 100%;
  max-width: 41px;
  margin: 0 2px;
`

export {
  Digit,
  CodeWrapper
}