import React from 'react';

const Loading = () => {
  return (
    <img
      src="/brand.svg"
      alt="image2"
      width="120px"
      class="logo-loader animation-loader-pulse"
    />
  );
};

export default Loading;
