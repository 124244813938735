import React, { useState } from 'react';
import { getUser } from '../../utils/user';
import { request } from '../../utils/request';
import {
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import {
  Container,
  Title,
  CenterColumn,
  ButtonContainer,
  MainButton,
  LoadingContainer,
  LinkContainer,
  Text,
  StyledLink,
} from './Component';
import Header from '../../components/Header/Header';
import FormEntryText from '../../components/FormEntryText/FormEntryText';
import Dialog from "../../components/Dialog/Dialog";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  disabledButton: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    backgroundColor: '#808080',
    border: 'none',
    borderRadius: '15px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
    padding: '10px 20px',
    textTransform: 'uppercase',
    cursor: 'not-allowed',

    '&:hover': {
      backgroundColor: '#808080',
    },
  }
}));


const PrivacyAndSecurity = () => {
  const { t } = useTranslation('privacyAndSecurityPage');
  const classes = useStyles();

  const user = getUser();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [feedback, setFeedback] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const updatePasswordData = (field, value) => {
    const newPassData = { ...passwordData };
    newPassData[field] = value;
    setPasswordData(newPassData);
  };

  const passwordHandler = async () => {
    try {
      if (!passwordData.password) {
        setFeedback(t('VALIDATION_MISSING_PASSWORD_ERROR'));
        setDialogOpen(true);
      } else if (passwordData.password !== passwordData.repeatPassword) {
        setFeedback(t('VALIDATION_PASSWORD_MUST_BE_EQUAL_ERROR'));
        setDialogOpen(true);
      } else {
        setIsSubmitting(true);
        setIsPasswordSubmitting(true);

        await request.post(`/client-app/update-profile?type=password`, {
          id: user.id,
          password: passwordData.password,
        });

        setFeedback(t('SUBMIT_PASSWORD_SUCCESS_RESPONSE'));
        setDialogOpen(true);
      }
    } catch (error) {
      console.log(`[PrivacyAndSecurity passwordHandler Error: ${error}`);
      let message = t('SUBMIT_ERROR_RESPONSE');

      const { isAxiosError } = error;
      if (isAxiosError) {
        message = error.response.data.message;
      }

      setFeedback(message);
      setDialogOpen(true);
    } finally {
      setIsSubmitting(false);
      setIsPasswordSubmitting(false);
    }
  };


  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('TITLE')}</Title>
      <CenterColumn>
        <FormEntryText
          id="password"
          label={t('NEW_PASSWORD')}
          onChange={(event) => updatePasswordData("password", event.target.value)}
          value={passwordData.password || ""}
          type="password"
          disabled={isSubmitting}
        />

        <FormEntryText
          id="repeat-password"
          label={t('CONFIRM_NEW_PASSWORD')}
          onChange={(event) => updatePasswordData("repeatPassword", event.target.value)}
          value={passwordData.repeatPassword || ""}
          type="password"
          disabled={isSubmitting}
        />

        <ButtonContainer marginTop="15px" marginBottom="20px">
          {
            isPasswordSubmitting ?
              <LoadingContainer>
                <LinearProgress />
              </LoadingContainer>
              :
              <MainButton
                onClick={passwordHandler}
                disabled={isSubmitting}
                className={isSubmitting && classes.disabledButton}
              >
                {t('UPDATE_PASSWORD')}
              </MainButton>
          }
        </ButtonContainer>

        {
          isSubmitting ?
            <LinkContainer>
              <Text>
                {t('MY_DATA')}
              </Text>
            </LinkContainer>
            :
            <LinkContainer>
              <StyledLink to="/secure/my-data">
                {t('MY_DATA')}
              </StyledLink>
            </LinkContainer>
        }

        <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
          {feedback}
        </Dialog>
      </CenterColumn>
    </Container>
  );
}

export default PrivacyAndSecurity;
