import React, { useEffect, useState } from "react";
import { ImageContainer } from './Components';
import Header from "../../components/Header/Header";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import { useTranslation } from 'react-i18next';
import JivoButton from "../../components/JivoButton";

const RegisterSpecial = ({ setAuth }) => {
  const { t } = useTranslation('registerPage');
  const [showJivoButton, setShowJivoButton] = useState(true);

  const handleHideJivoButton = () => {
    setShowJivoButton(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <RegistrationForm setAuth={setAuth} country="BR" type="open-special" />      
      <ImageContainer
        style={{
          paddingTop:30
        }}
      >
        <img
          src="/assets/logo-passe.svg"
          alt="logo passe verde"
          width="200"
        />
      </ImageContainer>
      <ImageContainer
        style={{border:0, paddingTop: 10, paddingBottom: 0}}
      >
        <img
          src="https://immunie.s3.eu-west-1.amazonaws.com/editar_blend_f105338473.png?1107853"
          alt="logo passe verde"
          width="240"
        />
      </ImageContainer>

      {showJivoButton && <JivoButton onClickCloseButton={handleHideJivoButton} />}
    </>
  );
};

export default RegisterSpecial;