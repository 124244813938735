import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 150px;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const CenterColumn = styled.div`
  width: calc(100% - 40px);
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
`;

const MainButton = styled.button`
  color: #FFFFFF;
  font-weight: bold;
  background-color: #2D80C6;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: #3297DB;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  margin: 10px 0px;
`

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Text = styled.span`
  color: #808080;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  cursor: not-allowed;
`;

const StyledLink = styled(Link)`
  color: #3297DB;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
`;

export {
  Container,
  Title,
  CenterColumn,
  ButtonContainer,
  MainButton,
  LoadingContainer,
  LinkContainer,
  Text,
  StyledLink,
};