import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 130px;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 10px;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;

const FormContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  margin-bottom: ${props => props.marginBottom ?? 0};
  text-decoration: none;
`;

const PaddingLeft = styled.div`
  margin-top: ${props => props.marginTop ?? 0};
  padding-left: 8px;
`;

const Button = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #2D80C6;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: .2s;
  cursor: pointer;
  align-self: center;
  margin-top: 40px;

  &:hover {
    background-color: #49aad6;
  }
`;

export {
  Container,
  Title,
  CenterColumn,
  FormContainer,
  StyledLink,
  Button,
  PaddingLeft,
};