import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Title,
  FormContainer,
} from './Components';
import Header from '../../components/Header/Header';
import { RedirectButton } from '../../components/RedirectButton';

const MyVaccinationsCard = () => {
  const { t } = useTranslation('vaccinationsCardPage');

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>
        {t('TITLE')}
      </Title>

      <FormContainer>
        <RedirectButton
          title={t('UPLOAD_VACCINATIONS_CARD')}
          href="/secure/my-health/my-vaccinations-card/upload-vaccinations-card"
          marginBottom="20px"
        />

        <RedirectButton
          title={t('CONSULT_VACCINATIONS_CARD')}
          href="/secure/my-health/my-vaccinations-card/consult-vaccinations-card"
        />
      </FormContainer>
    </Container >
  );
}

export default MyVaccinationsCard;