import styled from 'styled-components';

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #FE9201;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
`;

const LogoFrame = styled.div`
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url('${(props) => props.backgroundImage}');
`;

export {
  Container,
  Title,
  CenterColumn,
  LogoFrame,
};
