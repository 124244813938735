import React, { useState } from 'react';
import { getUser } from '../../utils/user';
import { MenuHeader, StyledLink } from './Components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Button,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { VERSION } from '../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  close: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: '#3893D0',
    cursor: 'pointer',
    zIndex: 100,
  },

  list: {
    width: 292,
    paddingTop: 0,
  },

  sideMenuLogo: {
    width: 120,
    height: 120,
  },

  listItemText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#3893D0',
    lineHeight: '55px',
    paddingLeft: '10px'
  },

  subscriptionInfo: {
    margin: '0 auto',
    marginTop: 'auto',
    fontWeight: 'bold',
    fontSize: 16,
    opacity: 0.6,
  },

  subscriptionDate: {
    margin: '0 auto',
    marginBottom: 20,
    fontSize: 12,
    opacity: 0.6,
  },

  bottomInfo: {
    margin: '0 auto',
    marginTop: 'auto',
    marginBottom: 10,
    opacity: 0.6,
  },

  bottomInfoText: {
    textAlign: 'center',
    fontSize: 14,
  }
});

export default function SideMenu() {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [state, setState] = useState(false);
  const user = getUser();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const logoutHandler = () => {
    localStorage.removeItem('__session');
    localStorage.removeItem('__user');
    sessionStorage.removeItem('access_code');
    localStorage.removeItem('__user_passeverde');
    window.location.reload(true);
  };

  return (
    <div>
      <Button onClick={toggleDrawer(true)} style={{ fontSize: 30, marginLeft: -10 }}>
        <MenuIcon htmlColor="#FFF" fontSize="inherit" />
      </Button>
      <Drawer open={state} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className={classes.close}>
            <CloseIcon />
          </div>
          <List className={classes.list}>
            <MenuHeader>
              <img className={classes.sideMenuLogo} alt="immunie logo" src={'/assets/immunie-side-menu-logo.svg'} />
            </MenuHeader>
            <StyledLink to="/secure/profile">
              <ListItem button key="profile">
                <Typography className={classes.listItemText}>
                  {t('SIDEBAR_PROFILE')}
                </Typography>
              </ListItem>
            </StyledLink>
            <StyledLink to="/secure/faq">
              <ListItem button key="faq">
                <Typography className={classes.listItemText}>
                  {t('SIDEBAR_FAQS')}
                </Typography>
              </ListItem>
            </StyledLink>
            <StyledLink to="/secure/terms-and-conditions">
              <ListItem button key="terms-and-conditions">
                <Typography className={classes.listItemText}>
                  {t('SIDEBAR_TOC')}
                </Typography>
              </ListItem>
            </StyledLink>
            <StyledLink to="/secure/privacy-policy">
              <ListItem button key="privacy-policy">
                <Typography className={classes.listItemText}>
                  {t('SIDEBAR_PRIVACY_POLICY')}
                </Typography>
              </ListItem>
            </StyledLink>
            {
              user?.multiAccountAllowed ?
                <StyledLink to="/secure/accounts">
                  <ListItem button key="accounts">
                    <Typography className={classes.listItemText}>
                      Dependentes
                    </Typography>
                  </ListItem>
                </StyledLink> : null
            }
            {
              user &&
              <div onClick={logoutHandler}>
                <ListItem button key="logout">
                  <Typography className={classes.listItemText}>
                    {t('SIDEBAR_LOGOUT')}
                  </Typography>
                </ListItem>
              </div>
            }
          </List>
        </div>
        <div className={classes.bottomInfo}>
          {
            user &&
            <>
              <Typography className={classes.bottomInfoText}>
                {t('SIDEBAR_USER_SINCE')}
              </Typography>
              <Typography
                className={classes.bottomInfoText}
                style={{ marginBottom: 20 }}
              >
                {user?.createdAt}
              </Typography>
            </>
          }
          <Typography className={classes.bottomInfoText}>
            {t('APP_VERSION_TEXT')}: {VERSION}
          </Typography>
        </div>
      </Drawer>
    </div>
  );
}
