import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { getUser } from "../../utils/user";
import { request } from "../../utils/request";
import {
  Container,
  Title,
  Subtitle,
  FormContainer,
  AccordionContainer,
  ButtonContainer,
  MainButton,
  SecondaryButton,
  TextAreaContainer,
  TextArea,
  DeleteAccountTextContainer,
  ModalButton,
} from "./Components";
import Header from "../../components/Header/Header";
import {
  makeStyles,
  Typography,
  Fade,
  Modal,
  Backdrop,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from "@material-ui/core";
import AccordionSummary from "../../components/AccordionSummary/AccordionSummary";
import AccordionDetails from "../../components/AccordionDetails/AccordionDetails";
import MyDataDownloadButton from "../../components/MyDataDownloadButton/MyDataDownloadButton";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  csvLink: {
    width: '100%',
    textDecoration: 'none',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
  },

  rectifyInfoText: {
    fontSize: '15px',
    color: '#808080',
    padding: '20px 10px',
    boxSizing: 'border-box',
  },

  deleteAccountText: {
    color: '#808080',
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  modal: {
    width: '95%',
    maxWidth: '410px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    boxSizing: 'border-box',
  },

  modalPaper: {
    maxHeight: '95%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    boxSizing: 'border-box',
  },

  modalHeader: {
    textAlign: 'center',
    width: 290,
    paddingTop: 10,
    paddingBottom: 20,
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#FF0066',
    textTransform: 'uppercase',
  },

  modalContent: {
    paddingBottom: 20,
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },

  modalFormControlLabel: {
    paddingBottom: 20,
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
    },
  },

  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    paddingBottom: 10,
  },

  modalBackButton: {
    backgroundColor: '#2D80C6',
    color: '#FFFFFF',
    transition: '.2s',
    '&:hover': {
      filter: 'brightness(.9)',
    }
  },
}));

const MyData = () => {
  const [open, setOpen] = useState(false);
  const [hasReadDeleteWarning, setHasReadDeleteWarning] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [downloadProfileData, setDownloadProfileData] = useState();
  const [emailMessage, setEmailMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState(undefined);

  const { t } = useTranslation('myDataPage');
  const classes = useStyles();
  const user = getUser();

  const handleClick = (panel) => {
    if (panel === expandedPanel) {
      setExpandedPanel(undefined);
    } else {
      setExpandedPanel(panel);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = () => {
    setHasReadDeleteWarning(!hasReadDeleteWarning);
  };

  const handleTerminateUser = async () => {
    try {
      setProcessing(true);
      setIsLoading(true);
      await request.post(`/client-app/update-profile?type=terminate`, {
        _id: user.id,
      });
      localStorage.removeItem("__session");
      localStorage.removeItem("__user");
      sessionStorage.removeItem("access_code");
      window.location.reload(true);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
      setIsLoading(false);
      handleClose();
    }
  };

  const contactFormHandler = async () => {
    try {
      setIsLoading(true);
      const body = {
        context: t('RECTIFY_TITLE'),
        userName: `${user.name} ${user.surname}`,
        message: emailMessage,
        email: user.email,
      };

      await request.post(`/client-app/contact`, body);
      setEmailMessage("");
      alert(t('EMAIL_SENT'));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadImage = async (url) => {
    try {
      setIsLoading(true);
      if (!url) {
        throw new Error("Data not available");
      }

      const splitedUrlString = url.split(".");
      const fileName = splitedUrlString[splitedUrlString.length - 2];
      const fileExtension = splitedUrlString[splitedUrlString.length - 1];

      const file = await fetch(url + '?not-from-cache').then((r) => r.blob());

      const blob = new Blob([file], { type: "image/**" });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = `${fileName}.${fileExtension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getProfileDownloadData = async () => {
      try {
        setIsLoading(true);
        // Profile Data
        const { data: profileData } = await request.get(
          `/client-app/download-profile-data?id=${user.id}`
        );
        setDownloadProfileData(profileData.user);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getProfileDownloadData();
  }, [user.id]);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('TITLE')}</Title>
      <Subtitle>{t('SUBTITLE')}</Subtitle>

      <FormContainer>
        <AccordionContainer
          id="downloadDataPanel"
          marginBottom="20px"
        >
          <AccordionSummary
            id="panel1a-header"
            onClick={() => handleClick('panel1')}
            title={t('DOWNLOAD_DATA')}
            expandedPanel={expandedPanel}
            panel='panel1'
          />
          <AccordionDetails
            id="panel1a-content"
            expandedPanel={expandedPanel}
            panel='panel1'
            height={250}
          >
            {isLoading ?
              <LinearProgress style={{ marginTop: 40 }} />
              :
              <>
                {downloadProfileData ? (
                  <MyDataDownloadButton marginTop={10}>
                    <CSVLink
                      data={[downloadProfileData]}
                      target="_blank"
                      className={classes.csvLink}
                      filename={"my-immunie-profile-data.csv"}
                    >
                      {t('DOWNLOAD_PROFILE')}
                    </CSVLink>
                  </MyDataDownloadButton>
                ) : (
                  <MyDataDownloadButton
                    disabled={true}
                    marginTop={10}
                    title={t('DOWNLOAD_PROFILE')}
                  />
                )}

                {user?.selfieFile?.url ?
                  <MyDataDownloadButton
                    title={t('DOWNLOAD_SELFIE')}
                    disabled={isLoading}
                    onClick={() => handleDownloadImage(user?.selfieFile?.url)}
                    marginTop={10}
                    fileType="image"
                  />
                  :
                  <MyDataDownloadButton
                    title={t('DOWNLOAD_SELFIE')}
                    disabled={true}
                    marginTop={10}
                    fileType="image"
                  />
                }

                {user.vaccinationCard?.url ?
                  <MyDataDownloadButton
                    title={t('DOWNLOAD_VACCINATION_CARD_IMAGE')}
                    disabled={isLoading}
                    onClick={() => handleDownloadImage(user.vaccinationCard?.url)}
                    marginTop={10}
                    fileType="image"
                  />
                  :
                  <MyDataDownloadButton
                    title={t('DOWNLOAD_VACCINATION_CARD_IMAGE')}
                    disabled={true}
                    marginTop={10}
                    fileType="image"
                  />
                }

                {user.documentFile?.url ?
                  <MyDataDownloadButton
                    title={t('DOWNLOAD_DOCUMENT_ID')}
                    disabled={isLoading}
                    onClick={() => handleDownloadImage(user.documentFile?.url)}
                    marginTop={10}
                    fileType="image"
                  />
                  :
                  <MyDataDownloadButton
                    title={t('DOWNLOAD_DOCUMENT_ID')}
                    disabled={true}
                    marginTop={10}
                    fileType="image"
                  />
                }
              </>
            }
          </AccordionDetails>
        </AccordionContainer>

        <AccordionContainer
          id="rectifyDataPanel"
          marginBottom="20px"
        >
          <AccordionSummary
            id="panel2a-header"
            onClick={() => handleClick('panel2')}
            title={t('RECTIFY_TITLE')}
            expandedPanel={expandedPanel}
            panel='panel2'
          />
          <AccordionDetails
            id="panel2a-content"
            expandedPanel={expandedPanel}
            panel='panel2'
            height={430}
          >
            <Typography className={classes.rectifyInfoText}>
              {t('RECTIFY_SUBTITLE')}
            </Typography>

            {isLoading ? <LinearProgress style={{ marginBottom: 20 }} /> : ""}
            <TextAreaContainer>
              <TextArea
                onChange={(event) => setEmailMessage(event.target.value)}
                value={emailMessage}
                type="text"
                id="clinicAddress"
              />
            </TextAreaContainer>

            <ButtonContainer>
              <MainButton
                onClick={contactFormHandler}
                disabled={isLoading}
              >
                {t('RECTIFY_BUTTON')}
              </MainButton>
            </ButtonContainer>

          </AccordionDetails>
        </AccordionContainer>

        <AccordionContainer
          id="deleteProfilePanel"
        >
          <AccordionSummary
            id="panel3a-header"
            onClick={() => handleClick('panel3')}
            title={t('TERMINATE_ACCOUNT')}
            expandedPanel={expandedPanel}
            panel='panel3'
          />
          <AccordionDetails
            id="panel3a-content"
            expandedPanel={expandedPanel}
            panel='panel3'
            height={230}
          >
            <DeleteAccountTextContainer>
              <Typography className={classes.deleteAccountText}>
                {t('TERMINATE_ACCOUNT_WARNING')}
              </Typography>
            </DeleteAccountTextContainer>

            <ButtonContainer marginTop="20px">
              <SecondaryButton onClick={handleOpen}>
                {t('TERMINATE_ACCOUNT_BUTTON')}
              </SecondaryButton>
            </ButtonContainer>

          </AccordionDetails>
        </AccordionContainer>

      </FormContainer>

      <Modal
        aria-labelledby="delete-account-modal-title"
        aria-describedby="delete-account-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <Typography
              className={classes.modalHeader}
              variant="h6"
            >
              {t('TERMINATE_ACCOUNT_MODAL_TITLE')}
            </Typography>
            <Typography
              id="delete-account-modal-description-text"
              className={classes.modalContent}
            >
              {t('TERMINATE_ACCOUNT_MODAL_DESCRIPTION')}
            </Typography>

            <FormControlLabel
              className={classes.modalFormControlLabel}
              control={
                <Checkbox
                  checked={hasReadDeleteWarning}
                  onChange={handleCheckboxChange}
                  name="readTerms"
                />
              }
              label={
                t('TERMINATE_ACCOUNT_CHECK_CONFIRMATION')
              }
            />

            {!processing ? (
              <div className={classes.modalButtonContainer}>
                <ModalButton
                  style={{
                    backgroundColor: !hasReadDeleteWarning
                      ? "#FFFFFF"
                      : "#FF0066",
                    color: !hasReadDeleteWarning
                      ? "#8D8D8D"
                      : "#FFFFFF",
                    cursor: !hasReadDeleteWarning
                      ? "not-allowed"
                      : "cursor",
                  }}
                  disabled={!hasReadDeleteWarning}
                  onClick={handleTerminateUser}
                >
                  {t('TERMINATE_ACCOUNT_CONFIRM_BUTTON')}
                </ModalButton>
                <ModalButton
                  className={classes.modalBackButton}
                  onClick={handleClose}
                >
                  {t('TERMINATE_ACCOUNT_CANCEL_BUTTON')}
                </ModalButton>
              </div>
            ) : (
              <Typography>
                {t('TERMINATE_ACCOUNT_PROCESS')}
              </Typography>
            )}
          </div>
        </Fade>
      </Modal>
    </Container >
  );
};

export default MyData;
