import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  margin-bottom: ${props => props.marginBottom ?? 0};
  margin-top: ${props => props.marginTop ?? 0};
  text-decoration: none;
`;

export {
  StyledLink,
};