const setHasPasseVerde = (passeverde) => {
  const serializedPasse = JSON.stringify(passeverde);
  localStorage.setItem('__haspasseverde', serializedPasse);
}

const getHasPasseVerde = () => {
  const serializedPasse = localStorage.getItem('__haspasseverde');
  const deserializedPasse = JSON.parse(serializedPasse);
  return deserializedPasse;
}

export {
  setHasPasseVerde,
  getHasPasseVerde,
};