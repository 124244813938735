import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PdfContainer = styled.div`
  background-color: #F6F9FE;
  padding-bottom: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #00A859;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const LoadingContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
`;

const CenterColumn = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ButtonContainer = styled.div`
  background-color: #F6F9FE;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  min-width: 145px;
  font-size: 13px;
  font-weight: bold;
  padding: 6px 20px;
  border: none;
  border-radius: 15px;
  background-color: #00A859;
  color: #FFFFFF;
  letter-spacing: -.5px;
  cursor: pointer;
  transition: .2s;
  text-transform: uppercase;
  box-sizing: border-box;
    :hover {
      background-color: #54b488;
    };
    :disabled {
      background-color: #808080;
      cursor: default;
    }
`;

const RedoButton = styled.button`
  min-width: 145px;
  font-size: 13px;
  font-weight: bold;
  padding: 6px 20px;
  border: none;
  border-radius: 15px;
  background-color: #F69103;
  color: #FFFFFF;
  letter-spacing: -.5px;
  cursor: pointer;
  transition: .2s;
  text-transform: uppercase;
  box-sizing: border-box;
  margin: 20px 0px;
    :hover {
      background-color: #E8B56E;
    };
    :disabled {
      background-color: #808080;
      cursor: default;
    }
`;

const AvatarBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:96px;
  height: 96px;
  position: relative;
  &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:50%; 
    padding:5px; 
    background: linear-gradient(135deg, #00A859, #808080); 
    background: -webkit-linear-gradient(310deg, #00A859, #808080); 
    -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
  }
`;

const AddToWalletButton = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 36px;
  border-radius: 4px;
  margin-top: 3px;
`;

export {
  Container,
  PdfContainer,
  Title,
  LoadingContainer,
  CenterColumn,
  Content,
  ButtonContainer,
  Button,
  RedoButton,
  AvatarBorder,
  AddToWalletButton,
  LoadingIndicatorContainer
};