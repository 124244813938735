import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { Language } from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { AVAILABLE_LANGUAGES } from '../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 3,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'white'
  },
  absoluteContainer: {
    position: 'absolute',
    top: 0,
    right: 12,
    zIndex: 3,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    position: 'relative',
    paddingTop: '16px',
  },
  select: {
    marginTop: '0 !important',
  },
  selectWhite: {
    marginTop: '0 !important',
    borderBottom: '1px solid #FFFFFF',

    '& .MuiSelect-icon': {
      color: 'white'
    },
  },
}));

const SELECTED_LANG = 'immunie-app-selectedLang';

const SelectLangs = ({ notAbsoluteContainer, whiteColor }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const [selectedLang, setSelectedLang] = useState('pt');

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem(SELECTED_LANG, value);
    setSelectedLang(value);
  };

  useEffect(() => {
    const language = localStorage.getItem(SELECTED_LANG);
    if (language) {
      i18n.changeLanguage(language);
      setSelectedLang(language);
    } else {
      localStorage.setItem(SELECTED_LANG, 'pt');
    }
    //eslint-disable-next-line
  }, []);

  return (
    <FormControl className={notAbsoluteContainer ? classes.container : classes.absoluteContainer}>
      <InputLabel id="language-select-label" className={classes.label}>
        <Language style={{ color: whiteColor && '#FFFFFF' }} />
      </InputLabel>
      <Select
        disableUnderline={whiteColor ? true : false}
        className={whiteColor ? classes.selectWhite : classes.select}
        style={{ color: whiteColor && '#FFFFFF' }}
        labelId="language-select-label"
        id="language-select-id"
        value={selectedLang}
        onChange={(event) => changeLanguage(event.target.value)}
      >
        {AVAILABLE_LANGUAGES.map(lang => (
          <MenuItem key={lang} value={lang}>{lang.toUpperCase()}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectLangs;