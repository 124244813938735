import React, { useState, useEffect } from 'react';
import { ModalContainer } from './Components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalContainerActive: {
    display: 'block',
  },
  modalContainerDisabled: {
    display: 'none',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  close: {
    padding: 5,
    marginRight: 20,
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'right',
    backgroundColor: 'transparent',
    border: 'transparent',
    transform: ('translateY(30%)')
  },
  container: {
    display: 'flex',
    boxSizing: 'border-box',
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: 'center'
  },
  img: {
    width: '20px',
    height: '20px',
    marginLeft: 5,
    marginRight: 5
  }
}));

export default function InstallPWAiOSModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      setOpen(true);
    }
  }, [])

  return (
    <ModalContainer
      className={open ? classes.modalContainerActive : classes.modalContainerDisabled}>
      <div className={classes.buttonContainer}>
        <button
          className={classes.close}
          onClick={() => setOpen(false)}
        >
          X
        </button>
      </div>
      <div className={classes.container}>
        <p>Install this webapp on your iOS, tap
          <img
            className={classes.img}
            src="/assets/ios-share-button.png"
            alt="iOS Share button" />
          and then 'Add to Home Screen'
        </p>
      </div>
    </ModalContainer>
  );
}