import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Title,
  Body,
  FaqContainer,
  FaqTitle,
  FaqContent,
  RateContainer,
  Rate,
  ButtonContainer
} from "./Components.js";
import SearchInput from "../../components/SearchInput/SearchInput";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  rateText: {
    fontSize: 15,
    color: '#5D5D5D',
    margin: '15px 0px'
  },

  button: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#5D5D5D',
    backgroundColor: '#C8C8C8',
    minWidth: 123,
    height: 28,
    borderRadius: 15,
  }
}))


const FaqSelectedQuestion = (props) => {
  const { t } = useTranslation('faqsPage');

  const classes = useStyles();
  let { id } = useParams();
  let history = useHistory();

  const handleClick = () => {
    history.goBack();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('TITLE')}</Title>
      <SearchInput
        startIcon
        endIcon
        onClick={handleClick}
        placeholder={t('SEARCH_PLACEHOLDER_BACK')}
      />
      <Body>
        <FaqContainer>
          <FaqTitle>{t(`QUESTION_${id}`)}</FaqTitle>
          <FaqContent>{t(`ANSWER_${id}`)}</FaqContent>
        </FaqContainer>

        <RateContainer>
          <Divider />
          <Rate>
            <Typography className={classes.rateText}>
              {t('ARTICLE_RATING_TITLE')}
            </Typography>
            <ButtonContainer>
              <Button className={classes.button}>
                {t('ARTICLE_RATING_YES')}
              </Button>
              <Button className={classes.button}>
                {t('ARTICLE_RATING_NO')}
              </Button>
            </ButtonContainer>
          </Rate>
        </RateContainer>
      </Body>
    </Container>
  );
}

export default FaqSelectedQuestion;
