import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { getUser } from "../../utils/user/index.js";
import { setEvent } from "../../utils/event/index.js";
import {
  Container,
  Content,
  CenterColumn,
  Title,
  Text,
  Button,
} from "./Components.js";
import { makeStyles, CircularProgress } from "@material-ui/core";
import Header from "../../components/Header/Header";
import SearchInput from "../../components/SearchInput/SearchInput";
import BottomSlider from "../../components/BottomSlider/BottomSlider.jsx";
import PasseVerdeItem from "../../components/PasseVerdeItem/PasseVerdeItem.jsx";
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: '#54b488',
    },
  },

  buttonDisabled: {
    cursor: 'not-allowed',
    backgroundColor: '#808080',
    '&:hover': {
      backgroundColor: '#808080',
    },
  },
}));

const NewPasseVerde = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('passeVerdePages');

  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [hideTravelEvents, setHideTravelEvents] = useState(true);

  const disableButton = selectedOption === '';

  const verifyUserTravelCondition = () => {
    const user = getUser();
    const nextTripDate = user.nextTripDate;

    if (nextTripDate) {
      if (moment(nextTripDate).isSameOrAfter(moment().subtract({ days: 1 }))) {
        setHideTravelEvents(false);
      }
    }
  }

  const redirect = (to) => {
    history.push(to);
  };

  const orderEventsAlphabetically = (a, b) => {
    return a.eventName.toUpperCase() > b.eventName.toUpperCase()
      ? 1
      : a.eventName.toUpperCase() < b.eventName.toUpperCase() ? -1
        : 0
  };


  const orderValidationRuleAlphabetically = (a, b) => {
    return a.toUpperCase() > b.toUpperCase()
      ? 1
      : a.toUpperCase() < b.toUpperCase() ? -1
        : 0
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    setSelectedOption('');
  };

  const handleChangeOption = (event) => {
    const id = event.target.value
    const selectedEvent = events.find(event => event.eventId === id);

    setEvent(selectedEvent);
    setSelectedOption(id);
  };

  const handleClick = () => {
    redirect(`new/${selectedOption}`)
  };

  const createEventsList = () => {
    const eventsList = [];

    if (events.length < 1) {
      return eventsList;
    }

    events.forEach((event, index) => {
      let validationRuleList = [];

      if (hideTravelEvents && event.contract === "TRAVEL") {
        return null
      }
      let date = moment(event.datetime).format('DD/MM/YYYY');

      if (event.contract === "TRAVEL") {
        date = 'travel'
      }

      if (!!Object.keys(event.protocols).length) {
        event.protocols.validationRules.map(rule => {
          return validationRuleList.push(rule.ruleName);
        });

        validationRuleList.sort(orderValidationRuleAlphabetically);
      }

      eventsList.push(
        <PasseVerdeItem
          key={`${event.eventId} ${event.eventName}`}
          eventName={event.eventName}
          eventDate={date}
          eventPlace={event.protocols?.location}
          availableProtocols={validationRuleList}
          index={index + 1}
          size="small"
          url={event.image}
          value={event.eventId}
          checked={selectedOption === event.eventId}
          onChange={handleChangeOption}
        />
      )
    });

    return eventsList;
  };

  const eventsToRender = createEventsList();

  const eventsFiltered = useMemo(() => {
    const lowerSearch = search.toLowerCase();
    return eventsToRender
      .filter((event) => event.key.toLowerCase().includes(lowerSearch));
  }, [eventsToRender, search]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        sessionStorage.removeItem('__event');

        verifyUserTravelCondition();

        const travelEvents = [];
        const otherEvents = [];

        const jwt = localStorage.getItem('__session');
        const formatedJwt = (jwt || '').replace(/['"]+/g, '');
        const { data: result } = await axios.get(`${process.env.REACT_APP_API_URL}/available-events`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${formatedJwt}`,
          }
        });

        result.map((event) => {
          if (event.contract === "TRAVEL") {
            return travelEvents.push(event);
          }
          return otherEvents.push(event);
        });

        const concatEvents = travelEvents.concat(otherEvents);
        const eventsToSet = concatEvents.sort(orderEventsAlphabetically);
        setEvents(eventsToSet);
      } catch (error) {
        console.log(`[NewPasseVerde getData Error]: ${error}`)
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  return (
    <Container>
      <Header backgroundStyle="GREEN" />
      <Title>
        {t('NEW_PASSE_VERDE')}
      </Title>
      <SearchInput
        placeholder={t('NEW_PASSE_VERDE_SEARCH')}
        onChange={handleChangeSearch}
        value={search}
        endIcon
      />
      {isLoading ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        eventsFiltered.length > 0 ?
          <>
            <Content>
              {eventsFiltered}
            </Content>

            <Button
              className={disableButton ? classes.buttonDisabled : classes.button}
              disabled={disableButton}
              onClick={handleClick}
            >
              {t('NEW_PASSE_VERDE_BUTTON_CONTINUE')}
            </Button>

            <BottomSlider />
          </>
          :
          <CenterColumn>
            <Text>
              {t('NEW_PASSE_VERDE_NO_EVENTS_FOUND')}
            </Text>
          </CenterColumn>
      )}
    </Container>
  );
};

export default NewPasseVerde;
