import styled from "styled-components";

const CardWrapper = styled.div`
  background-color: white;
  color: #4267b2;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0px;
  box-sizing: border-box;
`;

const DocumentDescription = styled.div`
  display: flex;
  width: 150px;
  margin-left: 0;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
`

const ButtonWrapper = styled.div`
  margin: 0;
  width: 6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`


export {
  CardWrapper,
  DocumentDescription,
  ButtonWrapper
};