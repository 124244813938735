import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },

    '& .MuiInput-underline:before': {
      border: 'none',
    },

    '& .MuiInput-underline:after': {
      border: 'none',
    },

    '& .MuiInputBase-root': {
      backgroundColor: '#f0f0f0',
      height: 40,
    },

    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 10,
    },

    '& .MuiSelect-icon': {
      right: 5,
    }
  },
}));

const SelectRegion = ({ options, onChange, value, translations }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        id="select-province"
        value={value}
        label="Province"
        onChange={onChange}
      >
        {
          options.map(region => (
            <MenuItem
              key={region.value}
              value={region.value}>
              {translations(region.name)}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default SelectRegion;
