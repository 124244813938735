import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: 41,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    backgroundColor: '#2D80C6',
    border: 'none',
    borderRadius: '15px',
    padding: '6px 15px',
    boxShadow: '0 0px 10px 0px rgb(16 36 94 / 9%)',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: '.2s',

    '&:hover': {
      backgroundColor: '#3297DB'
    },
  },

  disabledContainer: {
    width: '100%',
    minHeight: 41,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    backgroundColor: '#808080',
    border: 'none',
    borderRadius: '15px',
    padding: '6px 15px',
    boxShadow: '0 0px 10px 0px rgb(16 36 94 / 9%)',
    boxSizing: 'border-box',
    cursor: 'not-allowed',
  },

  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginTop: '0px',
    marginBottom: ' 0px',
  }
}));

const MyDataDownloadButton = ({
  title,
  onClick,
  children,
  disabled,
  marginTop,
  fileType,
}) => {

  const classes = useStyles();

  return (
    <button
      className={!disabled ? classes.container : classes.disabledContainer}
      onClick={onClick}
      disabled={disabled}
      style={{ marginTop: marginTop ? marginTop : 0 }}
    >
      {
        children ?
          children
          : <h4 className={classes.title}>{title}</h4>
      }
      {
        fileType === 'image' ?
          <img src="/assets/jpeg-file-icon.svg" alt="JPEG File Icon" />
          :
          <img src="/assets/csv-file-icon.svg" alt="CSV File Icon" />
      }
    </button>
  )
}

export default MyDataDownloadButton;