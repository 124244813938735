import React, {
  useRef,
  useState
} from "react";
import {
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import {
  CheckCircle as CheckIcon,
  Delete as TrashIcon,
} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 50px)',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 10,
  },

  title: {
    fontSize: 22,
    color: '#00A859',
    margin: 0,
  },

  titleIcon: {
    width: '22px',
    color: '#00A859',
    transform: 'translateY(7%)',
    marginLeft: 5
  },

  uploadContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
  },

  frame: {
    width: '100%',
    height: '100%',
    maxWidth: '85px',
    minHeight: '85px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  img: {
    width: '85px',
    height: '85px',
  },

  fileName: {
    fontSize: 12,
    textAlign: 'center',
    color: '#808080',
    marginBottom: 5,
  },

  sendStatus: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#00A859',
    marginBottom: 5,
  },

  button: {
    width: 210,
    fontSize: 13,
    fontWeight: 'bold',
    padding: '6px 20px',
    border: 'none',
    borderRadius: '15px',
    backgroundColor: '#00A859',
    color: '#FFFFFF',
    letterSpacing: '-.5px',
    cursor: 'pointer',
    transition: '.2s',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#54b488',
    },
    '&:disabled': {
      backgroundColor: '#808080',
      cursor: 'not-allowed',
    }
  },

  buttonOutlined: {
    width: 225,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '6px 10px',
    border: '1px solid #00A859',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    color: '#00A859',
    cursor: 'pointer',
    transition: '.2s',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    '&:hover': {
      color: '#0ccf78',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    }
  },

  blueTitle: {
    fontSize: 22,
    color: '#2D80C6',
    margin: 0,
  },

  blueTitleIcon: {
    width: '22px',
    color: '#2D80C6',
    transform: 'translateY(7%)',
    marginLeft: 5
  },

  blueSendStatus: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#2D80C6',
    marginBottom: 5,
  },

  blueButton: {
    width: 190,
    fontSize: 13,
    fontWeight: 'bold',
    padding: '6px 20px',
    border: 'none',
    borderRadius: '15px',
    backgroundColor: '#2D80C6',
    color: '#FFFFFF',
    letterSpacing: '-.5px',
    cursor: 'pointer',
    transition: '.2s',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#49aad6',
    },
    '&:disabled': {
      backgroundColor: '#808080',
      cursor: 'not-allowed',
    }
  },

  blueButtonOutlined: {
    width: 190,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '6px 10px',
    border: '1px solid #2D80C6',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    color: '#2D80C6',
    cursor: 'pointer',
    transition: '.2s',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    '&:hover': {
      color: '#49aad6',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    }
  },

  trashIconContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'right',
  },

  iconButton: {
    padding: 5,
  },

  trashIcon: {
    color: '#F60E31'
  },
}));

const BoxUploadFile = ({
  id,
  dateComponent,
  componentType,
  title,
  onChange,
  onlyPdf,
  onlyImage,
  displayOnly,
  isLoading,
  isSubmitting,
  documentUploadedText,
  buttonText,
  buttonTextWhenDocumentUploaded,
  reUpload,
  marginTop,
  showTrashIcon,
  onClickTrashIcon,
  secondaryColor,
}) => {
  const input = useRef(null);
  const [fileName, setFileName] = useState("");

  let image = '';
  let imageAfterUpload = '';

  if (secondaryColor) {
    if (componentType === 'photo') {
      image = '/assets/rounded-grey-send-selfie.svg'
      imageAfterUpload = '/assets/rounded-blue-send-selfie.svg';
    } else {
      image = '/assets/rounded-grey-pdf.svg'
      imageAfterUpload = '/assets/rounded-blue-pdf.svg';
    }
  } else {
    if (componentType === 'photo') {
      image = '/assets/rounded-grey-send-selfie.svg'
      imageAfterUpload = '/assets/rounded-green-send-selfie.svg';
    } else {
      image = '/assets/rounded-grey-pdf.svg'
      imageAfterUpload = '/assets/rounded-green-pdf.svg';
    }
  }

  const classes = useStyles();

  const onClick = () => {
    try {
      const { current } = input;
      current.click();
    } catch (err) {
      console.log(err)
    }
  };

  const handleChange = () => {
    if (id) {
      const target = document.getElementById(id);
      const file = target.files[0];
      setFileName(file?.name);
      if (onChange) onChange(file);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer} style={{ marginTop: marginTop }}>
        {secondaryColor ?
          <>
            <h6 className={classes.blueTitle}>{title}</h6>
            {title && displayOnly && <CheckIcon className={classes.blueTitleIcon} />}
          </>
          :
          <>
            <h6 className={classes.title}>{title}</h6>
            {title && displayOnly && <CheckIcon className={classes.titleIcon} />}
          </>
        }
      </div>

      {dateComponent}

      <div className={classes.uploadContainer}>
        <div className={classes.frame}>
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <img
              src={displayOnly ? imageAfterUpload : image}
              alt="Rounded Content"
              className={classes.img} />
          )}
        </div>

        <div>
          {displayOnly && (
            reUpload ? (
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "#db993b",
                  textAlign: "center",
                }}
              >
                Re-upload file
              </Typography>
            ) : (
              <>
                <Typography className={classes.fileName}>
                  {fileName}
                </Typography>
                {
                  secondaryColor ?
                    <Typography className={classes.blueSendStatus}>
                      {documentUploadedText}
                    </Typography>
                    :
                    <Typography className={classes.sendStatus}>
                      {documentUploadedText}
                    </Typography>
                }
              </>
            ))}
          {
            secondaryColor ?
              <button
                type="button"
                onClick={onClick}
                className={displayOnly ? classes.blueButtonOutlined : classes.blueButton}
                disabled={isLoading || isSubmitting}
              >
                {
                  displayOnly
                    ? buttonTextWhenDocumentUploaded
                    : buttonText
                }
              </button>
              :
              <button
                type="button"
                onClick={onClick}
                className={displayOnly ? classes.buttonOutlined : classes.button}
                disabled={isLoading || isSubmitting}
              >
                {
                  displayOnly
                    ? buttonTextWhenDocumentUploaded
                    : buttonText
                }
              </button>
          }
        </div>
      </div>

      {
        showTrashIcon &&
        <div className={classes.trashIconContainer}>
          <IconButton
            onClick={onClickTrashIcon}
            className={classes.iconButton}
          >
            <TrashIcon className={classes.trashIcon} />
          </IconButton>
        </div>
      }

      <input
        type={"file"}
        style={{ display: 'none' }}
        ref={input}
        onChange={handleChange}
        accept={onlyPdf ? ".pdf" : onlyImage ? "image/*" : "image/*,.pdf"}
        id={id}
      />
    </div>
  );
};

export default BoxUploadFile;