import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const CenterColumn = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const FormContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AccordionContainer = styled.div`
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
`;

const MainButton = styled.button`
  color: white;
  font-weight: bold;
  background-color: #2D80C6;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: .2s;


  &:hover {
    background-color: #3297DB;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  margin: 10px 0px; 
`
const AvatarBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:146px;
  height:146px;
  position: relative;
  &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:50%; 
    padding:5px; 
    background: linear-gradient(135deg, #2D80C6, #808080); 
    background: -webkit-linear-gradient(310deg, #2D80C6, #808080); 
    -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
  }
`

export {
  Container,
  Title,
  FormContainer,
  AccordionContainer,
  CenterColumn,
  ButtonContainer,
  MainButton,
  LoadingContainer,
  AvatarBorder,
};
