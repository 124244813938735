import styled from "styled-components";

const Container = styled.div`
  background-image: linear-gradient(#F6F9FE, #FFFFFF);
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Button = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #2D80C6;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: .2s;
  cursor: pointer;
  align-self: center;
  margin-top: 90px;

  &:hover {
    background-color: #49aad6;
  };

  &:disabled {
    background-color: #CCCCCC;
    cursor: not-allowed;
  };
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
`;

const RoundedCard = styled.div`
  background-image: linear-gradient(${props => props.startColor}, ${props => props.endColor});
  display: flex;
  width: 165px;
  height: 150px;
  flex-direction: column;
  color: white;
  border-radius: 35px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MainContent = styled.div`
 width: 100%; 
 display: flex; 
 align-items: center; 
 flex-direction: column;
`;

const SubscriptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const Card = styled.div`
  position: relative;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 165px;
  height: 170px;
  flex-direction: column;
  color: #999999;
  border-radius: 35px 35px 0 0;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
  cursor: pointer;
`;

const CardSubscription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  height: 220px;
  flex-direction: column;
  background-color: #FFF;
  color: #5D5D5D;
  border-radius: 13px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
  padding: 10px;
  cursor: pointer;
`
const HistoryContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  box-sizing: border-box;
`;

const HistoryCard = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  max-width: 380px;
  flex-direction: column;
  background-color: #FFF;
  color: #5D5D5D;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
  margin-top: 20px;
  margin-bottom: 30px;
`

const HistoryRow = styled.div`
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  border-radius: 6px;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;


export {
  Container,
  CenterColumn,
  CardsContainer,
  RoundedCard,
  MainContent,
  SubscriptionContainer,
  Card,
  CardSubscription,
  HistoryContainer,
  HistoryCard,
  Button,
  HistoryRow,
};
