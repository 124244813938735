import React from 'react';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const AccordionIcon = ({
  expandedPanel,
  panel,
}) => {
  return (
    <ExpandMoreIcon
      htmlColor="#808080"
      style={{
        transition: '0.25s',
        transform: expandedPanel === panel ? 'rotate(180deg)' : 'none'
      }}
    />
  )
}

export default AccordionIcon;