const DOCTOR_TYPE_OPTIONS = [
  {
    value: 'ALLOPATHIC',
    name: 'HEALTH_DATA_DOCTOR_TYPE_ALLOPATHIC'
  },
  {
    value: 'HOMEOPATHIC',
    name: 'HEALTH_DATA_DOCTOR_TYPE_HOMEOPATHIC'
  }
];

const SPECIAL_NEEDS_OPTIONS = [
  {
    value: 'Não Possuo',
    name: 'DO_NOT_HAVE'
  },
  {
    value: 'Pessoa com Deficiência visual Total (Cegas)',
    name: 'BLIND',
  },
  {
    value: 'Pessoa com Baixa Visão',
    name: 'LOW_VISION',
  },
  {
    value: 'Pessoa com Visão Monocular',
    name: 'MONOCULAR_VISION',
  },
  {
    value: 'Pessoa com perda da audição bilateral',
    name: 'BILATERAL_HEARING_LOSS',
  },
  {
    value: 'Pessoa com perda da audição parcial',
    name: 'PARTIAL_HEARING_LOSS',
  },
  {
    value: 'Pessoa com perda da audição total',
    name: 'TOTAL_HEARING_LOSS',
  },
  {
    value: 'Paraplegia',
    name: 'PARAPLEGIA',
  },
  {
    value: 'Paraparesia',
    name: 'PARAPARESIS',
  },
  {
    value: 'Monoplegia',
    name: 'MONOPLEGIA',
  },
  {
    value: 'Monoparesia',
    name: 'MONOPARESIS',
  },
  {
    value: 'Tetraplegia',
    name: 'TETRAPLEGIA',
  },
  {
    value: 'Tetraparesia',
    name: 'TETRAPARESIS',
  },
  {
    value: 'Triplegia',
    name: 'TRIPLEGIA',
  },
  {
    value: 'Triparesia',
    name: 'TRIPESIA',
  },
  {
    value: 'Hemiplegia',
    name: 'HEMIPLEGIA',
  },
  {
    value: 'Hemiparesia',
    name: 'HEMIPARESIS',
  },
  {
    value: 'Ostomia',
    name: 'OSTOMY',
  },
  {
    value: 'Ausência de um membro',
    name: 'ABSENCE_OF_A_MEMBER',
  },
  {
    value: 'Nanismo',
    name: 'DWARFISM',
  },
  {
    value: 'Paralisia cerebral',
    name: 'CEREBRAL_PALSY',
  },
  {
    value: 'Síndrome do X frágil',
    name: 'FRAGILE_X_SYNDROME',
  },
  {
    value: 'Síndrome de down',
    name: 'DOWNS_SYNDROME',
  },
  {
    value: 'Síndrome de Rett',
    name: 'RETT_SYNDROME',
  },
  {
    value: 'Discalculia',
    name: 'DYSCALCULIA',
  },
  {
    value: 'Síndrome do álcool fetal',
    name: 'FETAL_ALCOHOL_SYNDROME',
  },
  {
    value: 'Erros inatos do metabolismo',
    name: 'INBORN_ERRORS_OF_METABOLISM',
  },
  {
    value: 'Transtorno do Espectro Autista',
    name: 'AUTISTIC_SPECTRUM_DISORDER',
  },
  {
    value: 'Mobilidade reduzida',
    name: 'REDUCED_MOBILITY',
  },
];

const ALLERGIES_OPTIONS = [
  {
    value: 'Não Possuo',
    name: 'DO_NOT_HAVE'
  },
  {
    value: 'Alergia a medicamentos anti-inflamatórios',
    name: 'Alergia a medicamentos anti-inflamatórios',
  },
  {
    value: 'Alergia a medicamentos antibióticos',
    name: 'Alergia a medicamentos antibióticos',
  },
  {
    value: 'Alergia a medicamentos anticonvulsivantes',
    name: 'Alergia a medicamentos anticonvulsivantes',
  },
  {
    value: 'Alergia a medicamentos antiepilépticos',
    name: 'Alergia a medicamentos antiepilépticos',
  },
  {
    value: 'Alergia a medicamentos contraste de iodo',
    name: 'Alergia a medicamentos contraste de iodo',
  },
  {
    value: 'Alergia a medicamentos do tipo aspirina',
    name: 'Alergia a medicamentos do tipo aspirina',
  },
  {
    value: 'Alergia a medicamentos medicamentos para quimioterapia',
    name: 'Alergia a medicamentos medicamentos para quimioterapia',
  },
  {
    value: 'Alergia a medicamentos relaxantes musculares',
    name: 'Alergia a medicamentos relaxantes musculares',
  },
  {
    value: 'Alergia a medicamentos remédios antivirais',
    name: 'Alergia a medicamentos remédios antivirais',
  },
  {
    value: 'Alergia a pelos de animais',
    name: 'Alergia a pelos de animais',
  },
  {
    value: 'Alergia alimentar a amendoim',
    name: 'Alergia alimentar a amendoim',
  },
  {
    value: 'Alergia alimentar a frutas secas',
    name: 'Alergia alimentar a frutas secas',
  },
  {
    value: 'Alergia alimentar a frutos do mar',
    name: 'Alergia alimentar a frutos do mar',
  },
  {
    value: 'Alergia alimentar a leite',
    name: 'Alergia alimentar a leite',
  },
  {
    value: 'Alergia alimentar a nozes',
    name: 'Alergia alimentar a nozes',
  },
  {
    value: 'Alergia alimentar a ovos',
    name: 'Alergia alimentar a ovos',
  },
  {
    value: 'Alergia alimentar a trigo',
    name: 'Alergia alimentar a trigo',
  },
  {
    value: 'Alergia cutânea a abelhas',
    name: 'Alergia cutânea a abelhas',
  },
  {
    value: 'Alergia cutânea a carrapatos',
    name: 'Alergia cutânea a carrapatos',
  },
  {
    value: 'Alergia cutânea a formigas',
    name: 'Alergia cutânea a formigas',
  },
  {
    value: 'Alergia cutânea a mosquitos borrachudos',
    name: 'Alergia cutânea a mosquitos borrachudos',
  },
  {
    value: 'Alergia cutânea a mosquitos e borrachudos',
    name: 'Alergia cutânea a mosquitos e borrachudos',
  },
  {
    value: 'Alergia cutânea a pernilongos',
    name: 'Alergia cutânea a pernilongos',
  },
  {
    value: 'Alergia cutânea a pulgas',
    name: 'Alergia cutânea a pulgas',
  },
  {
    value: 'Alergia respiratória a ácaros',
    name: 'Alergia respiratória a ácaros',
  },
  {
    value: 'Alergia respiratória a fumaça',
    name: 'Alergia respiratória a fumaça',
  },
  {
    value: 'Alergia respiratória a mofo',
    name: 'Alergia respiratória a mofo',
  },
  {
    value: 'Alergia respiratória a poeira',
    name: 'Alergia respiratória a poeira',
  },
  {
    value: 'Alergia respiratória a pólen das plantas',
    name: 'Alergia respiratória a pólen das plantas',
  },
];

const BLOOD_TYPE_OPTIONS = [
  {
    value: 'O',
    name: 'HEALTH_DATA_BLOOD_TYPE_O'
  },
  {
    value: 'A',
    name: 'HEALTH_DATA_BLOOD_TYPE_A'
  },
  {
    value: 'B',
    name: 'HEALTH_DATA_BLOOD_TYPE_B'
  },
  {
    value: 'AB',
    name: 'HEALTH_DATA_BLOOD_TYPE_AB'
  }
];

const RH_FACTOR_OPTIONS = [
  {
    value: 'positive',
    name: 'HEALTH_DATA_RH_FACTOR_POSITIVE'
  },
  {
    value: 'negative',
    name: 'HEALTH_DATA_RH_FACTOR_NEGATIVE'
  }
];

export {
  DOCTOR_TYPE_OPTIONS,
  SPECIAL_NEEDS_OPTIONS,
  ALLERGIES_OPTIONS,
  BLOOD_TYPE_OPTIONS,
  RH_FACTOR_OPTIONS,
};