export const availableRegions = [
  {
    name: 'PRIVACY_POLICY_PAGE_REGION_1',
    value: 'region1',
  },
  {
    name: 'PRIVACY_POLICY_PAGE_REGION_2',
    value: 'region2',
  },
];

export const privacyPolicyRegion1 = [
  {
    title: "PRIVACY_POLICY_PAGE_REGION_1_TITLE_1",
    text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_1"],
    content: [
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_2",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_2"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_3",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_3"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_4",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_4"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_5",
        text: [
          "PRIVACY_POLICY_PAGE_REGION_1_TXT_5",
          "PRIVACY_POLICY_PAGE_REGION_1_TXT_5_1",
          "PRIVACY_POLICY_PAGE_REGION_1_TXT_5_2",
        ],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_6",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_6"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_7",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_7"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_8",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_8"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_9",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_9"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_10",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_10"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_11",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_11"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_12",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_12"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_13",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_13"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_14",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_14"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_15",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_15"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_16",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_16"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_17",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_17"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18"],
        headerContent: [
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_1",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_1"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_2",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_2"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_3",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_3"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_4",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_4"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_5",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_5"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_6",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_6"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_7",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_7"],
          },
          {
            subheader: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_18_8",
            text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_18_8"],
          },
        ],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_19",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_19"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_20",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_20"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_1_HEADER_21",
        text: ["PRIVACY_POLICY_PAGE_REGION_1_TXT_21"],
      },
    ],
  },
];

export const privacyPolicyRegion2 = [
  {
    title: "PRIVACY_POLICY_PAGE_REGION_2_TITLE_1",
    text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_1"],
    content: [
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_2",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_2"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_3",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_3"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_4",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_4"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_5",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_5"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_6",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_6"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_7",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_7"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_8",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_8"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_9",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_9"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_10",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_10"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_11",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_11"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_12",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_12"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_13",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_13"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_14",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_14"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_15",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_15"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_16",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_16"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_17",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_17"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_18",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_18"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_19",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_19"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_20",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_20"],
      },
      {
        header: "PRIVACY_POLICY_PAGE_REGION_2_HEADER_21",
        text: ["PRIVACY_POLICY_PAGE_REGION_2_TXT_21"],
      },
    ],
  },
];