import styled from 'styled-components';

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.div`
  background-image: url('/assets/travel_information.svg');
  width: 100%;
  height: 200px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -50px;
  position: relative;
`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 0px;
`;

export { Container, Image, Title };
