import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 150px;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const Subtitle = styled.h6`
  width: calc(100% - 40px);
  color: #808080;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const FormContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AccordionContainer = styled.div`
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
`;

const TextAreaContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
`;

const TextArea = styled.textarea`
  min-height: 240px;
  width: 95%;
  background-color: #FFFFFF;
  border: 0px;
  padding: 8px;
  border-radius: 8px;
  resize: none;
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0 0px 10px 0 rgba(16, 36, 94, 0.05);
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
`;

const MainButton = styled.button`
  color: white;
  font-weight: bold;
  background-color: #2D80C6;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: #3297DB;
  }
`;

const SecondaryButton = styled.button`
  color: white;
  font-size: 10px;
  font-weight: bold;
  background-color: #808080;
  border: none;
  border-radius: 15px;
  padding: 7px 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: #A6A6A6;
  }
`;

const DeleteAccountTextContainer = styled.div`
border: 1px solid #FF0066; 
padding: 20px 15px; 
margin-top: 20px; 
box-sizing: border-box;
`;

const ModalButton = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #00A859;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: .2s;
  cursor: pointer;
`;

export {
  Container,
  Title,
  Subtitle,
  FormContainer,
  AccordionContainer,
  ButtonContainer,
  MainButton,
  SecondaryButton,
  TextAreaContainer,
  TextArea,
  DeleteAccountTextContainer,
  ModalButton,
};
