import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
`;

const Title = styled.h3`
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-left: 20px;
  margin-top: -15px;
  margin-bottom: 0;
`;

const Body = styled.div`
  width: calc(100% - 40px);
  margin: 20px auto;
  border-radius: 10px;
  box-sizing: border-box;
`

export {
  Container,
  Title,
  Body,
};