import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { setUser } from "../../utils/user";
import {
  FormContainer,
  Title,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  CenterColumn
} from "./Components";
import {
  IconButton,
  TextField,
  FormControl,
  makeStyles,
  CircularProgress,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import Dialog from "../Dialog/Dialog";
import {
  AccountCircle,
  Event,
  Language,
  AssignmentInd,
  FlightTakeoff,
  EventNote,
} from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import pt from "date-fns/locale/pt";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "react-mobile-datepicker";
import ReactInputMask from "react-input-mask";
import moment from "moment";
import { dateConfig } from "../../utils/dateConfig";

const useStyles = makeStyles(() => ({
  input: {
    width: '95%',
  },

  keyboardDatePicker: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 14,
    '& .MuiIconButton-root': {
      display: 'none'
    },
  },

  datepickerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  datepicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    '& .datepicker': {
      position: 'static',
      borderRadius: 10,
      padding: 15,
    },
    '& .datepicker.default .datepicker-header': {
      fontSize: 20
    },
    '& .datepicker.default .datepicker-navbar-btn': {
      fontSize: 18
    }
  },

  select: {
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  menuItemImg: {
    width: 20,
    borderRadius: 25,
  },
}));

export const VoegolRegisterUpdateForm = ({
  setAuth,
  temporaryJwt,
  countryDestination,
  nextTripDate,
}) => {
  const { t } = useTranslation('voeGolRegisterUpdatePage');
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [logged, setLogged] = useState(false);
  const [openBirthDatePicker, setOpenBirthDatePicker] = useState(false);
  const [openTravelDatePicker, setOpenTravelDatePicker] = useState(false);
  const [isPassport, setIsPassport] = useState(false);

  // Fields
  const [name, setName] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [birthday, setBirthday] = useState({ date: new Date("01/01/2000"), touched: false });
  const [flightNumber, setFlightNumber] = useState("");
  const [flightOptions, setFlightOptions] = useState([]);
  const [travelDate, setTravelDate] = useState(nextTripDate ? new Date(nextTripDate) : new Date());

  const handleSwitchDocType = () => {
    setIsPassport(!isPassport);
    setDocNumber("");
  }

  const changeBirthday = (value) => {
    const newBirthday = { ...birthday };
    newBirthday.date = value;
    newBirthday.touched = true;

    setBirthday(newBirthday);
  };

  const onSubmit = async (event) => {
    try {
      if (!name || !docNumber || !flightNumber || !travelDate) {
        setFeedback(t('VALIDATION_COMPLETE_ALL_FIELDS_ERROR'));
        setDialogOpen(true);
        return;
      }

      if (!birthday.touched) {
        setFeedback(t('VALIDATION_BIRTHDATE_ERROR'));
        setDialogOpen(true);
        return;
      }

      if (!isPassport) {
        // eslint-disable-next-line
        if (!/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(docNumber)) {
          setFeedback(t('CPF_DATA_ERROR'));
          setDialogOpen(true);
          return;
        }
      }

      if (!temporaryJwt) {
        throw new Error('Missing token');
      };

      event.preventDefault();
      setLoading(true);

      const selectedFlight = flightOptions
        .find(flight => flight.flightNr === flightNumber);
      const stringifyTravelDate = moment(travelDate).format('YYYY-MM-DD');
      const time = selectedFlight.departureTime;

      const flightDatetime = `${stringifyTravelDate}T${time}Z`;

      const body = {
        name,
        birthday: moment(birthday.date).format("DD/MM/YYYY"),
        documentType: isPassport ? 'passport' : 'cpf',
        documentNumber: docNumber,
        updatedTravelData: {
          dateOfArrival: flightDatetime,
          flightNumber,
          destination: countryDestination,
        }
      };

      const config = {
        headers: {
          Authorization: `Bearer ${temporaryJwt}`,
        }
      };

      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}/client-app/user-data`,
        body,
        config
      );

      localStorage.setItem('__session', result.data.jwt);
      setUser(result.data.user);
      setAuth(true);
      setLogged(true);
    } catch (error) {
      if (error.message === 'Missing token') {
        setFeedback(t('MISSING_TOKEN_ERROR'));
        setDialogOpen(true);

        console.error(`[VoeGolRegisterUpdateForm handleSubmit] ${error}`);
        return;
      };

      setFeedback(t('SUBMIT_ERROR'));
      setDialogOpen(true);
      console.error(`[VoeGolRegisterUpdateForm handleSubmit] ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getFlightList = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${temporaryJwt}`,
          }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/client-app/flight-list`, config);

        const availableFlightsByCountryDestination = data
          .find(flight => flight.country === countryDestination);

        if (!availableFlightsByCountryDestination) {
          const allFlights = [];

          data.forEach((travel) => {
            travel.flights.forEach(flight => allFlights.push(flight))
          });

          setFlightOptions(allFlights);
          return;
        };

        setFlightOptions(availableFlightsByCountryDestination.flights);
      } catch (error) {
        setFeedback(t('GET_FLIGHT_DATA_ERROR'));
        setDialogOpen(true);

        console.error(`[getFlightList] ${error}`)
      }
    };

    getFlightList();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      {logged && <Redirect to={'/secure'} />}
      <FormContainer>
        <Title>
          {t('UPDATE_YOUR_DATA')}
        </Title>

        <FieldContainer>
          <AccountCircle fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <TextField
                label={t('NAME')}
                value={name}
                onChange={(event) => setName(event.target.value)}
                disabled={loading}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        <FieldContainer>
          <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
            <Event fontSize="large" htmlColor="#5D5D5D" />
            <KeyboardDatePicker
              className={classes.keyboardDatePicker}
              margin="normal"
              fullWidth
              defaultValue={null}
              disableFuture
              id="date-picker-dialog"
              onClick={() => setOpenBirthDatePicker(true)}
              label={t('BIRTHDATE')}
              format="dd/MM/yyyy"
              disabled={loading}
              value={birthday.date}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </FieldContainer>

        <FieldContainer>
          <IconButton onClick={handleSwitchDocType} style={{ padding: 0 }}>
            {isPassport ?
              <Language fontSize="large" htmlColor="#3a95d1" />
              :
              <AssignmentInd fontSize="large" htmlColor="#3a95d1" />
            }
          </IconButton>
          {
            isPassport ?
              <FormControl
                className={classes.input}
                style={{ padding: "0px 14px 8px 8px" }}
                value={docNumber}
                onChange={(event) => setDocNumber(event.target.value)}
              >
                <TextField
                  label={t('PASSPORT')}
                  disabled={loading} />
              </FormControl>
              :
              <ReactInputMask
                mask="999.999.999-99"
                value={docNumber}
                onChange={(event) => setDocNumber(event.target.value)}
              >
                {() => (
                  <FormControl
                    className={classes.input}
                    style={{ padding: "0px 14px 8px 8px" }}
                  >
                    <TextField
                      label={t('DOCUMENT_TYPE')}
                      disabled={loading}
                    />
                  </FormControl>
                )}
              </ReactInputMask>
          }
        </FieldContainer>

        <FieldContainer>
          <FlightTakeoff fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel id="select-flight-number-label">
                {t('FLIGHT_NUMBER')}
              </InputLabel>
              <Select
                labelId="select-flight-number-label"
                value={flightNumber}
                onChange={(event) => setFlightNumber(event.target.value)}
                disabled={loading}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {flightOptions.map((flightOption, index) => (
                  <MenuItem
                    key={flightOption.flightNr + index}
                    value={flightOption.flightNr}
                  >
                    {flightOption.flightNr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        <FieldContainer>
          <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
            <EventNote fontSize="large" htmlColor="#5D5D5D" />
            <KeyboardDatePicker
              className={classes.keyboardDatePicker}
              margin="normal"
              fullWidth
              defaultValue={null}
              minDate={new Date()}
              id="date-picker-dialog-flight-date"
              onClick={() => setOpenTravelDatePicker(true)}
              label={t('FLIGHT_DATE')}
              format="dd/MM/yyyy"
              disabled={loading}
              value={travelDate}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </FieldContainer>

        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <ButtonsContainer>
            <MainButton onClick={onSubmit}>
              <Typography style={{ fontWeight: "bold" }}>
                {t('SUBMIT')}
              </Typography>
            </MainButton>
          </ButtonsContainer>
        )}
      </FormContainer>

      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.datepickerModal}
        open={openBirthDatePicker}
        onClose={() => setOpenBirthDatePicker(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBirthDatePicker}>
          <div className={classes.datepicker}>
            <DatePicker
              value={birthday.date}
              onSelect={(time) => {
                changeBirthday(time);
                setOpenBirthDatePicker(false);
              }}
              onCancel={() => setOpenBirthDatePicker(false)}
              isPopup={false}
              showHeader={true}
              headerFormat="DD/MM/YYYY"
              dateConfig={dateConfig}
              min={new Date(1910, 0, 1)}
              max={moment()._d}
              confirmText={t('BIRTHDATE_MODAL_CONFIRM_BUTTON')}
              cancelText={t('BIRTHDATE_MODAL_CANCEL_BUTTON')}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.datepickerModal}
        open={openTravelDatePicker}
        onClose={() => setOpenTravelDatePicker(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openTravelDatePicker}>
          <div className={classes.datepicker}>
            <DatePicker
              value={travelDate}
              onSelect={(date) => {
                setTravelDate(date);
                setOpenTravelDatePicker(false);
              }}
              onCancel={() => setOpenTravelDatePicker(false)}
              isPopup={false}
              showHeader={true}
              headerFormat="DD/MM/YYYY"
              dateConfig={dateConfig}
              min={new Date()}
              confirmText={t('BIRTHDATE_MODAL_CONFIRM_BUTTON')}
              cancelText={t('BIRTHDATE_MODAL_CANCEL_BUTTON')}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};