import styled from "styled-components";

const FormContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
`;

const Title = styled.h4`
  color: #5D5D5D;
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;  
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'};
`;

const TextFieldContainer = styled.div`
  margin-bottom: 15px;
  margin-left: 10px;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const MainButton = styled.button`
  background-color: #4AB6FF;
  border: 1px solid #3893d0;
  border-radius: 23px;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  transition: all 350ms cubic-bezier(.175, .885, .32, 1.275);
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #4AA6FF;
  }
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export {
  FormContainer,
  Title,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  CenterColumn
};