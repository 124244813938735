import React, { useState } from 'react';
import { request } from '../../utils/request';
import { LinearProgress } from "@material-ui/core";
import {
  ButtonContainer,
  MainButton,
  LoadingContainer
} from "./Components"
import FormEntryText from "../FormEntryText/FormEntryText";
import FormEntryTextarea from "../FormEntryTextarea/FormEntryTextarea";
import Dialog from "../../components/Dialog/Dialog";
import { useTranslation } from 'react-i18next';

const HealthEmergencyInfoForm = ({
  userId,
  userHealthForm,
}) => {
  const { t } = useTranslation('healthPage');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [healthInsuranceName, setHealthInsuranceName] = useState(userHealthForm?.emergencyData?.healthInsuranceName);
  const [healthInsuranceNumber, setHealthInsuranceNumber] = useState(userHealthForm?.emergencyData?.healthInsuranceNumber);
  const [healthInsuranceCategory, setHealthInsuranceCategory] = useState(userHealthForm?.emergencyData?.healthInsuranceCategory);
  const [preferredEmergencyHospital, setPreferredEmergencyHospital] = useState(userHealthForm?.emergencyData?.preferredEmergencyHospital);
  const [preferredEmergencyHospitalPhone, setPreferredEmergencyHospitalPhone] = useState(userHealthForm?.emergencyData?.preferredEmergencyHospitalPhone);
  const [emergencyContacts, setEmergencyContacts] = useState(userHealthForm?.emergencyData?.emergencyContacts);
  const [feedback, setFeedback] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const confirmHandler = async () => {
    try {
      const data = {
        userId: userId,
        healthFormId: userHealthForm?._id,
        emergencyData: {
          healthInsuranceName,
          healthInsuranceNumber,
          healthInsuranceCategory,
          preferredEmergencyHospital,
          preferredEmergencyHospitalPhone,
          emergencyContacts
        }
      }
      setIsSubmitting(true);
      await request.post("/client-app/update-health-form", data);
      setFeedback(t('EMERGENCY_INFORMATION_SUBMIT_SUCCESS_DESCRIPTION'));
      setDialogOpen(true);

    } catch (error) {
      console.log(`[HealthEmergencyInfoForm confirmHandler Error]: ${error}`);
      let message = t('SUBMIT_ERROR_RESPONSE');

      const { isAxiosError } = error;
      if (isAxiosError) {
        message = error.response.data.message;
      }

      setFeedback(message)
      setDialogOpen(true);

    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div>
      <FormEntryText
        id="healthInsuranceName"
        label={t('EMERGENCY_INFORMATION_INSURANCE_NAME')}
        onChange={(event) => setHealthInsuranceName(event.target.value)}
        value={healthInsuranceName}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="healthInsuranceNumber"
        label={t('EMERGENCY_INFORMATION_INSURANCE_TELEPHONE')}
        onChange={(event) => setHealthInsuranceNumber(event.target.value)}
        value={healthInsuranceNumber}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="healthInsuranceCategory"
        label={t('EMERGENCY_INFORMATION_INSURANCE_CATEGORY')}
        onChange={(event) => setHealthInsuranceCategory(event.target.value)}
        value={healthInsuranceCategory}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="preferredEmergencyHospital"
        label={t('EMERGENCY_INFORMATION_FAVORITE_HOSPITAL')}
        onChange={(event) => setPreferredEmergencyHospital(event.target.value)}
        value={preferredEmergencyHospital}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="preferredEmergencyHospitalPhone"
        label={t('EMERGENCY_INFORMATION_FAVORITE_HOSPITAL_TELEPHONE')}
        onChange={(event) => setPreferredEmergencyHospitalPhone(event.target.value)}
        value={preferredEmergencyHospitalPhone}
        disabled={isSubmitting}
      />

      <FormEntryTextarea
        id="emergencyContacts"
        label={t('EMERGENCY_INFORMATION_EMERGENCY_PHONES_NUMBER')}
        onChange={(event) => setEmergencyContacts(event.target.value)}
        value={emergencyContacts}
        disabled={isSubmitting}
      />

      <ButtonContainer marginTop="20px">
        {
          isSubmitting ?
            <LoadingContainer>
              <LinearProgress />
            </LoadingContainer>
            :
            <MainButton onClick={confirmHandler} disabled={isSubmitting}>
              {t('EMERGENCY_INFORMATION_SUBMIT_BUTTON')}
            </MainButton>
        }
      </ButtonContainer>

      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>
    </div>
  );
}

export default HealthEmergencyInfoForm;