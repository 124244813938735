import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chapterContainer: {
    marginBottom: 40,
  },

  sectionContainer: {
    marginTop: 15,
    marginBottom: 30,
  },

  subheaderContainer: {
    marginBottom: 20,
  },

  h4: {
    fontWeight: 'bold',
    fontSize: 24,
    color: '#2D80C6',
  },

  h5: {
    fontWeight: 'bold',
    fontSize: 22,
    color: '#2D80C6',
  },

  h6: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    color: '#2D80C6',
  },

  paragraph: {
    fontSize: 14,
    marginTop: 5,
    textAlign: 'justify',
    color: '#808080',
  },
}));

const GenerateRegionContent = ({ content, translations }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {
        content?.map(item => {
          return (
            <Box
              className={classes.chapterContainer}
              key={item.title}
            >
              <Typography
                component="h4"
                className={classes.h4}
              >
                {translations(item.title)}
              </Typography>
              {item?.text?.map(text => (
                <Typography
                  key={text}
                  component="p"
                  className={classes.paragraph}
                >
                  {translations(text)}
                </Typography>
              ))}
              {item.content.map(content => {
                return (
                  <Box
                    className={classes.sectionContainer}
                    key={content?.header}
                  >
                    <Typography
                      component="h5"
                      className={classes.h5}
                    >
                      {translations(content?.header)}
                    </Typography>

                    {content?.text?.map(description => (
                      <Typography
                        key={description}
                        component="p"
                        className={classes.paragraph}
                      >
                        {translations(description)}
                      </Typography>
                    ))}
                    {
                      content?.headerContent?.map(content => {
                        return (
                          <Box
                            className={classes.subheaderContainer}
                            key={content?.subheader}
                          >
                            <Typography
                              component="h6"
                              className={classes.h6}
                            >
                              {translations(content?.subheader)}
                            </Typography>
                            {
                              content?.text?.map(description => (
                                <Typography
                                  key={description}
                                  component="p"
                                  className={classes.paragraph}
                                >
                                  {translations(description)}
                                </Typography>
                              ))}
                          </Box>
                        )
                      })}
                  </Box>
                )
              })}
            </Box>
          )
        })
      }
    </Grid>
  );
};

export default GenerateRegionContent;
