import React from 'react';
import { makeStyles, Box, Link } from '@material-ui/core';
import {
  Cancel
} from '@material-ui/icons';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: 84,
    right: 20,
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: '#159343',
    transition: '0.2s',
    '&:hover': {
      opacity: 0.8,
    },
  },

  closeButton: {
    position: 'absolute',
    right: 54,
    bottom: -12,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

const JivoButton = ({ onClickCloseButton }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Link
        target="_blank"
        rel="noopener"
        href="https://jivo.chat/GKp7E4V7HM"
        className={classes.link}
      >
        <img
          src="/assets/jivochat.svg"
          alt="Jivo Button"
        />
      </Link>

      <button
        className={classes.closeButton}
        type="button"
        title="Hide Jivo Button"
        onClick={onClickCloseButton}>
        <Cancel htmlColor='#a2a2a2' />
      </button>
    </Box>
  )
}

export default JivoButton;