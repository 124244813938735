import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";
import { request } from "./utils/request";
import { setUser } from "./utils/user";
import './i18n';

// Components
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

// Containers
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import Index from "./containers/Index/Index";
import NotFound from "./containers/NotFound/NotFound";
import Secure from "./containers/Secure/Secure";
import Loading from "./containers/Loading/Loading";
import ConfirmNewPassword from "./containers/ConfirmNewPassword/ConfirmNewPassword";
import Register from "./containers/Register/Register";
import RegisterSpecial from "./containers/RegisterBlendway/Register";
import RegisterSchools from "./containers/RegisterSchools/Register";
import ResetLoginData from "./containers/ResetLoginData/ResetLoginData";
import VoegolRegisterUpdate from "./containers/VoegolRegisterUpdate/VoegolRegisterUpdate";

const Wrapper = styled.section`
  width: 100%;
  margin: 0;

  @media (min-width: 450px) {
    width: 450px;
    margin: 0 auto;
  }
`;

//teste
const Website = ({ location: { pathname }, ...props }) => {
  const [isAuth, setAuth] = useState(undefined);

  useEffect(() => {
    const getUserFromAPI = async () => {
      try {
        const { data: user } = await request.get("/client-app/me");
        setUser(user);
        setAuth(true);
      } catch (error) {
        setAuth(false);
        console.log("[Website] Error:", error);
      }
    };
    getUserFromAPI();
  }, []);

  let securePath = pathname.includes("secure") ? pathname : "/secure";

  return (
    <Wrapper>
      <Switch>
        <Route
          exact
          path={"/"}
          render={(props) => {
            if (isAuth === undefined) {
              return <Loading />;
            } else if (isAuth === false) {
              return <Index setAuth={setAuth} {...props} />;
            } else {
              return <Redirect to={securePath} />;
            }
          }}
        />
        <Route
          path={"/secure"}
          render={(props) => (
            <ProtectedRoute
              auth={isAuth}
              protectedComponent={() => <Secure {...props} />}
            />
          )}
        />
        <Route
          path="/reset-login-data"
          render={(props) => <ResetLoginData setAuth={setAuth} {...props} />}
        />
        <Route
          path="/voegol-register-update"
          render={(props) => <VoegolRegisterUpdate setAuth={setAuth} {...props} />}
        />        
        <Route
          exact
          path={"/registro/e8de34922717e2a92fb66add142b12ae3b0d2f5290e328dc382b0ec1dd6c043cb94d95892c324cca736b7f0d3f1007d7e8de8c1ddb6eb85c5be9298fcf19f323"}
          render={(props) => <RegisterSpecial setAuth={setAuth} {...props} />}
        />
        <Route
          exact
          path={"/registro/escolas"}
          render={(props) => <RegisterSchools setAuth={setAuth} {...props} />}
        />
        <Route
          exact
          path={"/registro/escola"}
          render={(props) => <RegisterSchools setAuth={setAuth} {...props} />}
        />
        <Route
          path={"/registro"}
          render={(props) => <Register setAuth={setAuth} {...props} />}
        />
        <Route
          path={"/forgot-password"}
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path={"/confirm-new-password"}
          render={(props) => <ConfirmNewPassword {...props} />}
        />
        <Route render={(props) => <NotFound {...props} />} />
      </Switch>
    </Wrapper>
  );
};

export default Website;
