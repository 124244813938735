import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import pt from "date-fns/locale/pt";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 5,
  },

  label: {
    cursor: 'pointer',
  },

  labelContent: {
    margin: '12px 0px 8px 0px',
    fontWeight: 'bold',
    color: '#505050',
  },

  datePicker: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(23px, 14px) scale(1)',
      color: '#000000',
      fontSize: 13,
      display: 'none'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -11px) scale(.75)',
      display: 'none'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#f44336',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      color: '#000000',
    },
  },

  input: {
    backgroundColor: '#FFFFFF',
    color: '#505050',
    fontSize: 13,
    padding: '8px 12px',
    borderRadius: '8px',
    minHeight: '45px',
    boxSizing: 'border-box',
    boxShadow: '0 0px 10px 0px rgb(16 36 94 / 7%)',
    cursor: 'pointer',

    '& fieldset': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
}));

const FormEntryDate = ({
  id,
  onClick,
  value,
  label,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <label htmlFor={id} className={classes.label}>
        <p className={classes.labelContent}>{label}</p>
      </label>
      <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.datePicker}
          fullWidth
          defaultValue={null}
          disableFuture
          disabled={disabled}
          id={id}
          format="dd/MM/yyyy"
          value={value}
          inputVariant="outlined"
          onClick={onClick}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputProps={{
            className: classes.input,
            readOnly: true
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default FormEntryDate;