import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { PasseVerdeProvider } from "../../context/passeverde";

// Containers Imports
import Home from "../Home/Home";
import MyCard from "../MyCard/MyCard";
import ShareDocumentInformation from "../ShareDocumentInformation/ShareDocumentInformation";
import PasseVerde from "../PasseVerde/PasseVerde";
import NewPasseVerde from "../NewPasseVerde/NewPasseVerde";
import PasseVerdeUpload from "../PasseVerdeUpload/PasseVerdeUpload";
import DigitalCertificates from "../DigitalCertificates/DigitalCertificates";
import Profile from "../Profile/Profile";
import MyData from "../MyData/MyData";
import PrivacyAndSecurity from "../PrivacyAndSecurity/PrivacyAndSecurity";
import Faq from "../Faq/Faq";
import FaqSelectedQuestion from "../FaqSelectedQuestion/FaqSelectedQuestion";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Calendar from "../Calendar/Calendar";
import MyHealth from "../MyHealth/MyHealth";
import MyVaccinationsCard from "../MyVaccinationsCard/MyVaccinationsCard";
import UploadVaccinationsCard from "../UploadVaccinationsCard/UploadVaccinationsCard";
import ConsultVaccinationsCardStatus from "../ConsultVaccinationsCardStatus/ConsultVaccinationsCardStatus";
import MedicalCertificate from "../MedicalCertificate/MedicalCertificate";
import UploadMedicalCertificate from "../UploadMedicalCertificate/UploadMedicalCertificate";
import ConsultMedicalCertificate from "../ConsultMedicalCertificate/ConsultMedicalCertificate";
import SiteMap from "../SiteMap/SiteMap";

// Components Imports
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import Checkout from "../Checkout/Checkout";
import CheckoutSub from "../CheckoutSub/CheckoutSub";
import AccountsManager from "../AccountsManager/AccountsManager";
import CreateSubAccount from "../AccountsManager/CreateSubAccount";

const Secure = (props) => {

  let { path } = useRouteMatch();

  const { location: { pathname } } = props;

  const BottomFixedSection = styled.section`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;

    @media(min-width: 450px) {
      width: 450px;
    }
  `;

  return (

    <PasseVerdeProvider>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <Home {...props} />}
        />
        <Route
          path={`${path}/sitemap`}
          render={(props) => <SiteMap {...props} />}
        />
        <Route
          path={`${path}/my-card`}
          render={(props) => <MyCard {...props} />}
        />
        <Route
          path={`${path}/travel-information`}
          render={(props) => <ShareDocumentInformation {...props} />}
        />
        <Route
          exact
          path={`${path}/passe-verde`}
          render={(props) => <PasseVerde {...props} />}
        />
        <Route
          exact
          path={`${path}/passe-verde/new`}
          render={(props) => <NewPasseVerde {...props} />}
        />
        <Route
          path={`${path}/passe-verde/new/:id`}
          render={(props) => <PasseVerdeUpload {...props} />}
        />
        <Route
          path={`${path}/digital-certificates`}
          render={(props) => <DigitalCertificates {...props} />}
        />
        <Route
          path={`${path}/share-document`}
          render={(props) => <ShareDocumentInformation {...props} />}
        />
        <Route
          path={`${path}/profile`}
          render={(props) => <Profile {...props} />}
        />
        <Route
          path={`${path}/privacy-and-security`}
          render={(props) => <PrivacyAndSecurity {...props} />}
        />
        <Route
          path={`${path}/my-data`}
          render={(props) => <MyData {...props} />}
        />
        <Route
          exact
          path={`${path}/faq`}
          render={(props) => <Faq {...props} />}
        />
        <Route
          path={`${path}/faq/question/:id`}
          render={(props) => <FaqSelectedQuestion {...props} />}
        />
        <Route
          path={`${path}/terms-and-conditions`}
          render={(props) => <TermsAndConditions {...props} />}
        />
        <Route
          path={`${path}/accounts`}
          render={(props) => <AccountsManager {...props} />}
        />
        <Route
          path={`${path}/create-sub-account`}
          render={(props) => <CreateSubAccount {...props} />}
        />
        <Route
          path={`${path}/privacy-policy`}
          render={(props) => <PrivacyPolicy {...props} />}
        />
        <Route
          path={`${path}/calendar`}
          render={(props) => <Calendar {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health`}
          render={(props) => <MyHealth {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health/my-vaccinations-card`}
          render={(props) => <MyVaccinationsCard {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health/my-vaccinations-card/upload-vaccinations-card`}
          render={(props) => <UploadVaccinationsCard {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health/my-vaccinations-card/consult-vaccinations-card`}
          render={(props) => <ConsultVaccinationsCardStatus {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health/medical-certificate`}
          render={(props) => <MedicalCertificate {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health/medical-certificate/upload-certificate`}
          render={(props) => <UploadMedicalCertificate {...props} />}
        />
        <Route
          exact
          path={`${path}/my-health/medical-certificate/consult-certificate`}
          render={(props) => <ConsultMedicalCertificate {...props} />}
        />
        <Route
          path={`${path}/checkout`}
          render={(props) => <Checkout {...props} />}
        />
        <Route
          path={`${path}/checkout-sub`}
          render={(props) => <CheckoutSub {...props} />}
        />
      </Switch>
      <BottomFixedSection>
        <BottomMenu pathname={pathname} />
      </BottomFixedSection>
    </PasseVerdeProvider>
  );
}

export default Secure;
