import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next'
import {
  Container,
  Title,
  CenterColumn,
  FormContainer,
  PaddingLeft,
  Button,
} from './Components';
import Header from '../../components/Header/Header';
import FormEntrySelect from "../../components/FormEntrySelect/FormEntrySelect";
import FormEntryText from "../../components/FormEntryText/FormEntryText";
import FormEntryDate from '../../components/FormEntryDate/FormEntryDate';
import { FormEntryAutocomplete } from '../../components/FormEntryAutocomplete';
import ChooseOption from "../../components/ChooseOption/ChooseOption";
import BoxUploadFile from '../../components/BoxUploadFile/BoxUploadFile';
import { FeedbackModal } from '../../components/FeedbackModal/FeedbackModal';
import {
  makeStyles,
  FormControlLabel,
  Checkbox,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import DatePicker from "react-mobile-datepicker";

import { request } from '../../utils/request';
import { dateConfig } from '../../utils/dateConfig';
import debounce from 'lodash.debounce';
import { TOCModal } from '../../components/TOCModal';

const useStyles = makeStyles(() => ({
  datepickerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  datepicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    '& .datepicker': {
      position: 'static',
      borderRadius: 10,
      padding: 15,
    },
    '& .datepicker.default .datepicker-header': {
      fontSize: 20
    },
    '& .datepicker.default .datepicker-navbar-btn': {
      fontSize: 18
    }
  },
}));

const DEPENDANT_TYPE_OPTIONS = [
  {
    name: 'UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE_UNDER_AGE',
    value: 'Menor Dependente',
  },
  {
    name: 'UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE_OVER_AGE',
    value: 'Idoso',
  },
  {
    name: 'UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE_PCD',
    value: 'PCD',
  },
];

const UploadMedicalCertificate = () => {
  const classes = useStyles();
  const { t } = useTranslation(['medicalCertificatePage', 'common']);

  const [isOpenTOCModal, setIsOpenTOCModal] = useState(false);
  const [isTOCChecked, setIsTOCChecked] = useState(false);

  const [enterpriseList, setEnterpriseList] = useState([]);

  const [medicalCertificateData, setMedicalCertificateData] = useState({
    enterprise: '',
    file: '',
    doctorName: '',
    date: undefined,
    doctorRegistry: '',
    intDiseaseClassification: '',
    clinicName: '',
    clinicPhone: '',
    daysOff: 0,
  });

  const [dependantInformation, setDependantInformation] = useState({
    dependantCompanion: false,
    dependantCompanionType: '',
    dependantName: '',
    dependantDegreeOfKinship: '',
    dependantTaxRelated: 'no',
  });

  const [isLoadingUserEnterprises, setIsLoadingUserEnterprises] = useState(false);
  const [isSubmittingMedicalCertificateData, setIsSubmittingMedicalCertificateData] = useState(false);
  const [isUploadingMedicalCertificate, setIsUploadingMedicalCertificate] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingIcds, setIsLoadingIcds] = useState(false);
  const [isBRFUser, setIsBRFUser] = useState(false);
  const [enterpriseClinics, setEnterpriseClinics] = useState([]);
  const [icdsOptions, setIcdsOptions] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isOpenNoEnterpriseFoundModal, setIsOpenNoEnterpriseFoundModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenSuccessSubmitModal, setIsOpenSuccessSubmitModal] = useState(false);

  const isAllFieldsFilled = () => {
    if (dependantInformation.dependantCompanion) {
      if (!dependantInformation.dependantCompanionType ||
        dependantInformation.dependantName.trim().length === 0 ||
        dependantInformation.dependantDegreeOfKinship.trim().length === 0) {
        alert(t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_ERROR'))
        return false
      }
    };

    if (!medicalCertificateData.enterprise ||
      !medicalCertificateData.date ||
      !medicalCertificateData.file ||
      !medicalCertificateData.doctorName.trim().length === 0 ||
      !medicalCertificateData.doctorRegistry.trim().length === 0 ||
      !medicalCertificateData.clinicName.trim().length === 0 ||
      !medicalCertificateData.clinicPhone.trim().length === 0) {
      alert(t('UPLOAD_MEDICAL_CERTIFICATE_MISSING_FIELD_ERROR'));
      return false
    }

    return true;
  };

  const updateMedicalCertificateData = (field, value) => {
    if (field === 'daysOff') {
      if (value < 0) {
        alert(t('UPLOAD_MEDICAL_CERTIFICATE_DAYS_OFF_ERROR'))
        return;
      }
    };

    const updatedMedicalCertificateData = { ...medicalCertificateData };
    updatedMedicalCertificateData[field] = value;
    setMedicalCertificateData(updatedMedicalCertificateData);
  };

  const updateDependantInformation = (field, value) => {
    const updatedDependantInformation = { ...dependantInformation };
    updatedDependantInformation[field] = value;
    setDependantInformation(updatedDependantInformation);
  };

  const uploadMedicalCertificateFile = async (file) => {
    try {
      setIsUploadingMedicalCertificate(true);
      const formData = new FormData();
      formData.append(`file.medicalCertificate`, file);
      const imageResult = await request.post(
        `/client-app/upload-covid-document?documentType=medicalCertificate`,
        formData
      );

      const updatedMedicalCertificateData = { ...medicalCertificateData };
      updatedMedicalCertificateData['file'] = imageResult?.data?.fileId;
      setMedicalCertificateData(updatedMedicalCertificateData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploadingMedicalCertificate(false);
    }
  };

  const onChangeIcd = (_, value) => {
    const updatedMedicalCertificateData = { ...medicalCertificateData };
    updatedMedicalCertificateData.intDiseaseClassification = value;
    setMedicalCertificateData(updatedMedicalCertificateData);
  };

  const onIcdInputChange = (_, value) => {
    setSearchQuery(value);
  };

  const renderIcdOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          {`${option.code} - ${option.description['PT']}`}
        </Grid>
      </Grid>
    );
  };

  const filterIcdsOptions = (options) => options;

  const getIcdsOptionLabel = (option) => {
    if (!option) {
      return ''
    }

    return `${option.code} - ${option.description['PT']}`
  };

  const getIcdsOptionsAsync = async (query) => {
    try {
      if (query === '') {
        return [];
      }

      const { data: queryResult } = await request.get(
        `/client-app/find-icds?code=${query.toLowerCase()}`
      );

      return queryResult;
    } catch (err) {
      console.error(`[getIcdsOptionsAsync] ${err}`);
      return [];
    }
  };

  const getIcdsOptionsDelayed = useCallback(
    debounce((query, callback) => {
      setIcdsOptions([]);
      getIcdsOptionsAsync(query).then(callback);
    }, 300),
    []
  );

  const handleSubmitMedicalCertificateData = async () => {
    try {
      setIsSubmittingMedicalCertificateData(true);
      if (!isAllFieldsFilled()) {
        return;
      };

      let body = {
        ...medicalCertificateData,
        intDiseaseClassification: medicalCertificateData?.intDiseaseClassification?.code,
        date: new Date(medicalCertificateData?.date),
      };

      if (dependantInformation.dependantCompanion) {
        body = {
          ...body,
          ...dependantInformation,
          dependantTaxRelated: dependantInformation?.dependantTaxRelated === 'no'
            ? false
            : true,
        }
      }

      await request.post("/client-app/sick-note", body);
      setIsOpenSuccessSubmitModal(true);
    } catch (error) {
      console.error(error)
      setIsOpenErrorModal(true);
    } finally {
      setIsSubmittingMedicalCertificateData(false);
    }
  };

  useEffect(() => {
    setIsLoadingIcds(true);

    getIcdsOptionsDelayed(searchQuery, (options) => {
      setIcdsOptions(options);

      setIsLoadingIcds(false);
    });
  }, [searchQuery, getIcdsOptionsDelayed]);


  useEffect(() => {
    const getUserEnterprises = async () => {
      setIsLoadingUserEnterprises(true);
      try {
        const { data: resultEnterprises } = await request.get(
          `/client-app/find-user-enterprises`
        );

        // Check if user have brf enterprise
        for (const data of resultEnterprises) {
          if (data._id === "64b9554f8e8a6700131d6613") { // VERIFICAR SE O USER FAZ PARTE DA BRF
            setIsBRFUser(true)
            const sortedClinics = data.clinics.sort((a, b) => a.commercialName > b.commercialName)
            sortedClinics.push({ _id: "999999999", commercialName: "Outra não listada" })
            setEnterpriseClinics(sortedClinics)
          }
        }

        if (resultEnterprises?.length) {
          setEnterpriseList(resultEnterprises.map((enterprise) => ({
            name: enterprise.name,
            value: enterprise._id
          })));
        } else {
          setIsOpenNoEnterpriseFoundModal(true);
          return;
        }

        if (!isTOCChecked) {
          setIsOpenTOCModal(true);
        };
      } catch (error) {
        console.log(`[UploadMedicalCertificate getUserEnterprises] ${error}`);
      } finally {
        setIsLoadingUserEnterprises(false);
      }
    };

    getUserEnterprises();

    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>
        {t('UPLOAD_MEDICAL_CERTIFICATE')}
      </Title>
      {
        isLoadingUserEnterprises ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <FormContainer>
            <FormEntrySelect
              id="enterprise"
              onChange={(event) => updateMedicalCertificateData("enterprise", event.target.value)}
              label={t('UPLOAD_MEDICAL_CERTIFICATE_ENTERPRISE')}
              value={medicalCertificateData?.enterprise}
              disabled={isSubmittingMedicalCertificateData}
            >
              <option value="">
                {t('SELECT_AN_OPTION', { ns: 'common' })}
              </option>
              {enterpriseList?.map(enterprise => (
                <option key={enterprise.value} value={enterprise.value}>
                  {enterprise.name}
                </option>
              ))}
            </FormEntrySelect>

            <FormEntryDate
              id="date"
              label={t('UPLOAD_MEDICAL_CERTIFICATE_DATE')}
              value={medicalCertificateData?.date ?? null}
              onClick={() => setOpenDatePicker(true)}
              disabled={isSubmittingMedicalCertificateData}
            />

            <FormEntryText
              id="doctorName"
              label={t('UPLOAD_MEDICAL_CERTIFICATE_DOCTOR_NAME')}
              onChange={(event) => updateMedicalCertificateData("doctorName", event.target.value)}
              value={medicalCertificateData?.doctorName}
              disabled={isSubmittingMedicalCertificateData}
            />

            <FormEntryText
              id="doctorRegistry"
              label={t('UPLOAD_MEDICAL_CERTIFICATE_DOCTOR_REGISTRY')}
              onChange={(event) => updateMedicalCertificateData("doctorRegistry", event.target.value)}
              value={medicalCertificateData?.doctorRegistry}
              disabled={isSubmittingMedicalCertificateData}
            />

            <FormEntryAutocomplete
              id="intDiseaseClassification"
              label={t('UPLOAD_MEDICAL_CERTIFICATE_DISEASE_CLASSIFICATION')}
              options={icdsOptions}
              value={medicalCertificateData?.intDiseaseClassification}
              onChange={onChangeIcd}
              onInputChange={onIcdInputChange}
              getOptionLabel={getIcdsOptionLabel}
              filterOptions={filterIcdsOptions}
              loading={isLoadingIcds}
              loadingText={t('UPLOAD_MEDICAL_CERTIFICATE_LOADING')}
              noOptionsText={
                searchQuery === ''
                  ? t('UPLOAD_MEDICAL_CERTIFICATE_TYPE_DISEASE_CLASSIFICATION_CODE')
                  : t('UPLOAD_MEDICAL_CERTIFICATE_OPTION_NOT_FOUND')
              }
              renderOption={renderIcdOption}
              disabled={isSubmittingMedicalCertificateData}
            />

            {
              isBRFUser ?
                <>
                  <FormEntrySelect
                    id="clinicName"
                    onChange={(event) => updateMedicalCertificateData("clinicName", event.target.value)}
                    label={t('UPLOAD_MEDICAL_CERTIFICATE_CLINIC_NAME')}
                    value={medicalCertificateData?.clinicName}
                    disabled={isSubmittingMedicalCertificateData}
                  >
                    <option value="">
                      {t('SELECT_AN_OPTION', { ns: 'common' })}
                    </option>
                    {enterpriseClinics?.map(clinic => (
                      <option key={clinic._id} value={clinic.commercialName}>
                        {clinic.commercialName}
                      </option>
                    ))}
                  </FormEntrySelect>
                </> :
                <FormEntryText
                  id="clinicName"
                  label={t('UPLOAD_MEDICAL_CERTIFICATE_CLINIC_NAME')}
                  onChange={(event) => updateMedicalCertificateData("clinicName", event.target.value)}
                  value={medicalCertificateData?.clinicName}
                  disabled={isSubmittingMedicalCertificateData}
                />
            }


            <FormEntryText
              id="clinicPhone"
              label={t('UPLOAD_MEDICAL_CERTIFICATE_CLINIC_PHONE')}
              onChange={(event) => updateMedicalCertificateData("clinicPhone", event.target.value)}
              value={medicalCertificateData?.clinicPhone}
              disabled={isSubmittingMedicalCertificateData}
            />

            <FormEntryText
              id="daysOff"
              label={t('UPLOAD_MEDICAL_CERTIFICATE_DAYS_OFF')}
              onChange={(event) => updateMedicalCertificateData("daysOff", event.target.value)}
              value={medicalCertificateData?.daysOff}
              type="number"
              disabled={isSubmittingMedicalCertificateData}
            />

            <PaddingLeft marginTop="12px">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDependantInformation({
                        ...dependantInformation,
                        dependantCompanion: !dependantInformation?.dependantCompanion
                      })
                    }}
                    name='dependantCompanion'
                    checked={dependantInformation?.dependantCompanion}
                    disabled={isSubmittingMedicalCertificateData}
                  />}
                label={t('UPLOAD_MEDICAL_CERTIFICATE_IS_DEPENDANT_COMPANION')}
              />
            </PaddingLeft>

            {
              dependantInformation?.dependantCompanion &&
              <>
                <FormEntrySelect
                  id="dependantCompanionType"
                  label={t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE')}
                  onChange={(event) => updateDependantInformation("dependantCompanionType", event.target.value)}
                  value={dependantInformation?.dependantCompanionType}
                  disabled={isSubmittingMedicalCertificateData}
                >
                  <option value="">
                    {t('SELECT_AN_OPTION', { ns: 'common' })}
                  </option>
                  {DEPENDANT_TYPE_OPTIONS?.map(dependantType => (
                    <option key={dependantType.value} value={dependantType.value}>
                      {t(dependantType.name)}
                    </option>
                  ))}
                </FormEntrySelect>

                <FormEntryText
                  id="dependantName"
                  label={t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_NAME')}
                  onChange={(event) => updateDependantInformation("dependantName", event.target.value)}
                  value={dependantInformation?.dependantName}
                  disabled={isSubmittingMedicalCertificateData}
                />

                <FormEntryText
                  id="dependantDegreeOfKinship"
                  label={t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_DEGREE_OF_KINSHIP')}
                  onChange={(event) => updateDependantInformation("dependantDegreeOfKinship", event.target.value)}
                  value={dependantInformation?.dependantDegreeOfKinship}
                  disabled={isSubmittingMedicalCertificateData}
                />

                <ChooseOption
                  title={t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TAX_RELATED')}
                  name="dependantTaxRelated"
                  toCheckData={dependantInformation?.dependantTaxRelated}
                  onChange={(event) => updateDependantInformation("dependantTaxRelated", event.target.value)}
                  firstOptionId="yes"
                  firstOptionValue="yes"
                  firstOptionLabel={t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_YES')}
                  secondOptionId="no"
                  secondOptionValue="no"
                  secondOptionLabel={t('UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_NO')}
                  disabled={isSubmittingMedicalCertificateData}
                />
              </>
            }

            <PaddingLeft>
              <BoxUploadFile
                marginTop={15}
                id="document-file-uploader"
                title={t('UPLOAD_MEDICAL_CERTIFICATE_CERTIFICATE')}
                buttonText={t('UPLOAD_MEDICAL_CERTIFICATE_UPLOAD_FILE')}
                buttonTextWhenDocumentUploaded={t('UPLOAD_MEDICAL_CERTIFICATE_UPLOADED_FILE_BUTTON_TEXT')}
                documentUploadedText={t('UPLOAD_MEDICAL_CERTIFICATE_UPLOADED_FILE_TEXT')}
                isLoading={isUploadingMedicalCertificate}
                displayOnly={medicalCertificateData?.["file"]}
                componentType="anyFile"
                secondaryColor
                onChange={(file) => uploadMedicalCertificateFile(file)}
              />
            </PaddingLeft>

            <Button onClick={handleSubmitMedicalCertificateData}>
              {t('UPLOAD_MEDICAL_CERTIFICATE_BUTTON_TITLE')}
            </Button>
          </FormContainer>
        )
      }

      <Modal
        aria-labelledby="date-modal-title"
        aria-describedby="date-modal-description"
        className={classes.datepickerModal}
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDatePicker}>
          <div className={classes.datepicker}>
            <DatePicker
              value={medicalCertificateData?.date}
              onSelect={(date) => {
                updateMedicalCertificateData("date", date);
                setOpenDatePicker(false);
              }}
              onCancel={() => setOpenDatePicker(false)}
              isPopup={false}
              showHeader={true}
              headerFormat="DD/MM/YYYY"
              dateConfig={dateConfig}
              min={new Date(1910, 0, 1)}
              max={new Date()}
              confirmText={t('UPLOAD_MEDICAL_CERTIFICATE_DATE_MODAL_CONFIRM')}
              cancelText={t('UPLOAD_MEDICAL_CERTIFICATE_DATE_MODAL_CANCEL')}
            />
          </div>
        </Fade>
      </Modal>

      <TOCModal
        id="toc-modal-medical-certificate"
        open={isOpenTOCModal}
        content={{
          title: t('UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_TITLE'),
          description: t('UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_DESCRIPTION'),
          checkboxLabel: t('UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_CONFIRMATION_TEXT'),
          backButtonTitle: t('UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_CANCEL'),
          confirmButtonTitle: t('UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_CONFIRM'),
        }}
        checked={isTOCChecked}
        onClick={() => setIsOpenTOCModal(false)}
        onChange={() => setIsTOCChecked(!isTOCChecked)}
      />

      <FeedbackModal
        id="no-enterprise-found"
        title={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_MODAL_TITLE')
        }}
        description={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_NO_ENTERPRISE_MODAL_CONTENT')
        }}
        button={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_MODAL_BUTTON')
        }}
        open={isOpenNoEnterpriseFoundModal}
        onClick={() => {
          setIsOpenNoEnterpriseFoundModal(false);
          window.location.replace("/secure");
        }}
      />

      <FeedbackModal
        id="error"
        title={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_MODAL_TITLE')
        }}
        description={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_ERROR_MODAL_CONTENT')
        }}
        button={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_MODAL_BUTTON')
        }}
        open={isOpenErrorModal}
        onClick={() => {
          setIsOpenErrorModal(false);
          window.location.replace("/secure");
        }}
      />

      <FeedbackModal
        id="submit-success"
        title={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_MODAL_TITLE')
        }}
        description={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_SUCCESS_MODAL_CONTENT')
        }}
        button={{
          text: t('UPLOAD_MEDICAL_CERTIFICATE_MODAL_BUTTON'),
          backgroundColor: '#00A859'
        }}
        open={isOpenSuccessSubmitModal}
        onClick={() => {
          setIsOpenSuccessSubmitModal(false);
          window.location.replace("/secure/my-health/medical-certificate/consult-certificate");
        }}
      />
    </Container >
  );
}

export default UploadMedicalCertificate;
