import React from 'react';
import Header from '../../components/Header/Header';
import CodeContainer from '../../components/CodeContainer/CodeContainer';
import { Container, Image, Title } from './Components.js';
import { useTranslation } from 'react-i18next';

const ShareDocumentInformation = (props) => {
  const { t } = useTranslation('shareDocumentPage');

  const documentData = props.history.location?.state;

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Image />
      <Title>{t('TITLE')}</Title>
      <Title>{documentData.name}</Title>

      <CodeContainer
        documentMetadata={documentData}
        explanatoryTextOffCode={t('EXPLANATORY_OFF_CODE_TEXT')}
        explanatoryTextOnCode={t('EXPLANATORY_ON_CODE_TEXT')}
        generateCodeText={t('GENERATE_CODE')}
        terminateCodeText={t('TERMINATE_CODE')}
        translations={t}
        btnColor="#F9B934"
      />
    </Container>
  );
};

export default ShareDocumentInformation;
