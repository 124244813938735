import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 130px;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

export {
  Container,
  Title,
  FormContainer,
};