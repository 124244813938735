import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  sliderContainer: {
    width: '50%',
    height: '4px',
    borderRadius: '4px',
    background: '#808080',
    margin: '0 1.5rem',
    marginTop: '30px',
    marginBottom: '70px',
    position: 'relative',
  },

  slider: {
    height: '4px',
    borderRadius: '4px',
    background: '#00A859',
  },

  sliderIconsContainer: {
    width: '130%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transform: 'translateX(-8%)',
    left: 0,
    right: 0,
    top: -20,
  },

  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imgText: {
    fontSize: 8,
    color: '#808080',
    marginTop: 5,
  },

  imgTextGreen: {
    fontSize: 8,
    color: '#00A859',
    marginTop: 5,
  },
}));

const BottomSlider = () => {
  const { t } = useTranslation('passeVerdePages');
  const classes = useStyles();

  return (
    <div className={classes.sliderContainer}>
      <div className={classes.slider} style={{ width: `52%` }} />
      <div className={classes.sliderIconsContainer}>
        <div className={classes.imgContainer}>
          <img src="/assets/circle-hand-green.svg" alt="Green Circle with Hand" />
          <span className={classes.imgTextGreen}>
            {t('NEW_PASSE_VERDE_BOTTOM_SLIDER_1')}
          </span>
        </div>
        <div className={classes.imgContainer}>
          <img
            src="/assets/document-pre-upload.svg"
            alt="PDF Document" />
          <span className={classes.imgText}>
            {t('NEW_PASSE_VERDE_BOTTOM_SLIDER_2')}
          </span>
        </div>
      </div>
    </div >
  )
}

export default BottomSlider;