import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const CenterColumn = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Button = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #2D80C6;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: .2s;
  cursor: pointer;
  align-self: center;
  margin-top: 90px;

  &:hover {
    background-color: #49aad6;
  };

  &:disabled {
    background-color: #CCCCCC;
    cursor: not-allowed;
  };
`;

const AddButtonContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: right;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid #2D80C6;
  border-radius: 23px;
  background-color: #FFFFFF;
  color: #2D80C6;
  cursor: pointer;
  transition: .2s;
  text-transform: uppercase;
  box-sizing: border-box;
  margin-top: 30px;
  &:hover {
    color: '#49aad6';
  };
`;

const AddButtonText = styled.span`
  font-size: 11px;
  font-weight: bold;
  margin-right: 5px;
`;

export {
  Button,
  Container,
  Title,
  CenterColumn,
  AddButtonContainer,
  AddButton,
  AddButtonText,
};