import styled from "styled-components";

const CardWrapper = styled.div`
  background-color: white;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px 16px;
  margin-top: 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const DocumentDescription = styled.div`
  display: flex;
  max-width: 76%;
  margin-left: 0;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
`

const ButtonWrapper = styled.div`
  margin: 0;
  margin-right: -12px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`

export {
  CardWrapper,
  DocumentDescription,
  ButtonWrapper
};