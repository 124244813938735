import React from "react";
import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ auth, protectedComponent }) => {
  if ((typeof protectedComponent === 'function') && auth === true) {
    const Component = protectedComponent;
    return <Component />
  } else if (auth === false) {
    return <Redirect to="/" />
  } else {
    return null;
  }
}

export default ProtectedRoute;