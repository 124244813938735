import React, { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { getUser } from "../../utils/user";
import { request } from "../../utils/request";
import {
  Container,
  CenterColumn,
  CardsContainer,
  RoundedCard,
  MainContent,
  SubscriptionContainer,
  Card,
  CardSubscription,
  HistoryContainer,
  HistoryCard,
  HistoryRow,
} from "./Components.js";
import Header from "../../components/Header/Header";
import JivoButton from "../../components/JivoButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Divider, Modal, Backdrop, Fade } from "@material-ui/core";
import { PasseVerdeContext } from "../../context/passeverde";
import { AcceptanceModal } from "../../components/AcceptanceModal";
import { FamilyModal } from "./FamilyModal";

const useStyles = makeStyles((theme) => ({
  roundedCardText: {
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
  },

  modal: {
    width: '95%',
    maxWidth: '410px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    boxSizing: 'border-box',
  },

  modalPaper: {
    maxHeight: '95%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    boxSizing: 'border-box',
  },

  modalHeader: {
    textAlign: 'center',
    width: 290,
    paddingTop: 10,
    paddingBottom: 20,
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#FF0066',
    textTransform: 'uppercase',
  },

  modalContent: {
    paddingBottom: 20,
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },

  cardText: {
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: "5px",
  },

  cardTextPlus: {
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "5px",
  },

  cardContent: {
    position: "absolute",
    top: "-15px",
    display: "flex",
    alignItems: "center",
  },

  middleSeparator: {
    height: "65px",
    width: "100%",
    marginTop: "45px",
  },

  divider: {
    width: "95%",
    height: "2px",
    color: "#CCCCCC",
  },

  addContainer: {
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
  },

  subscriptionTitle: {
    fontSize: "23px",
    fontWeight: "bold",
  },

  subscriptionSubtitle: {
    fontSize: "17px",
  },

  subscriptionValue: {
    fontSize: "30px",
  },

  subscriptionButton: {
    borderRadius: "35px",
    fontWeight: "bold",
  },

  historyCardTitleContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FE9201",
    borderRadius: "5px 5px 0px 0px",
    color: "#FFF",
    boxSizing: "border-box",
  },

  historyCardTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    padding: "2px",
    marginLeft: "10px",
  },

  historyCardSeeAllText: {
    fontSize: "12px",
    fontWeight: "bold",
    padding: "2px",
    marginRight: "10px",
    cursor: "pointer",
  },

  historyContent: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
  },

  historyContentNoVaccine: {
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  historyContentNoVaccineText: {
    color: "#B1B1B1",
    fontSize: "12px",
    fontWeight: "bold",
  },

  noPlansAvailableText: {
    color: "#808080",
    fontWeight: "bold",
    fontSize: "14px",
    width: "250px",
    textAlign: "center",
    marginBottom: 30,
    marginTop: 20,
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['homePage', 'common']);
  const { loadingPasse, passeVerde } = useContext(PasseVerdeContext);

  const history = useHistory();

  const redirect = (to) => {
    history.push(to);
  };

  const [isOpenAcceptanceModal, setIsOpenAcceptanceModal] = useState(false);
  const [isOpenFamilyModal, setIsOpenFamilyModal] = useState(false);
  const [isSubmittingAcceptance, setIsSubmittingAcceptance] = useState(false);

  const [openPvUpdateModal, setOpenPvUpdateModal] = useState(false);
  const [vaccinesHistory, setVaccinesHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPaymentData, setLoadingPaymentData] = useState(false);
  const [loadingAllVaccines, setLoadingAllVaccines] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [userHasPlan, setUserHasPlan] = useState(false);
  const [availablePlansToBuy, setAvailablePlansToBuy] = useState([]);
  const [showJivoButton, setShowJivoButton] = useState(true);

  const user = getUser();
  
  const handleHideJivoButton = () => {
    setShowJivoButton(false);
  };

  const handleOpenModal = () => {
    setOpenPvUpdateModal(true);
  };

  const handleCloseModal = () => {
    setOpenPvUpdateModal(false);
  };

  const handleClickNewPasseVerde = () => {
    if (!userHasPlan) {
      alert(t('MISSING_PLAN'));
      return;
    }

    if (passeVerde?.status === 'ALERT' || passeVerde?.status === 'PENDING') {
      alert(t('USER_ALREADY_HAVE_A_PASSE_VERDE_ACTIVE_ERROR_DESCRIPTION'));
      return;
    }

    if (passeVerde?.status === 'APPROVED') {
      if (moment(passeVerde?.validUntil).isAfter()) {
        alert(t('USER_ALREADY_HAVE_A_PASSE_VERDE_ACTIVE_ERROR_DESCRIPTION'));
        return;
      }

      if (user?.plan?.allowMultiplePv) {
        handleOpenModal()
        return;
      } else {
        alert(t('USER_ALREADY_HAVE_A_PASSE_VERDE_ACTIVE_ERROR_DESCRIPTION'));
        return;
      }
    }

    redirect.bind(this, "/secure/passe-verde/new")();
  };

  const getAllUserHistory = async (limit) => {
    try {
      setSeeAll(true);
      setLoadingAllVaccines(true);
      const { data: result } = await request.get(
        `/client-app/history?id=${user.id}`
      );
      setVaccinesHistory(result);
      scrollToRef(myRef);
    } catch (error) {
      console.log(`[Home getAllUserHistory Error]: ${error}`);
    } finally {
      setLoadingAllVaccines(false);
    }
  };

  const disableCurrentPvAndCreateNew = async () => {
    // REDIRECT - se o user criar um PV ele automaticamente desativa todos os outros PVs
    redirect.bind(this, "/secure/passe-verde/new")()
  };

  const createCheckout = async (plan) => {
    setLoadingPaymentData(true);

    try {
      const { data: result } = await request.post(
        `/client-app/create-checkout-session`,
        {
          stripePriceId: plan.stripePriceID,
        }
      );
      window.location.replace(result.checkoutUrl);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPaymentData(false);
    }
  };

  const handleSubmitAcceptance = async () => {
    try {
      setIsSubmittingAcceptance(true);
      await request.patch(
        `/client-app/privacy-toc-read`
      );

      window.location.reload();
      setIsOpenAcceptanceModal(false);
    } catch (err) {
      console.error(err);
      alert(t('ACCEPTANCE_MODAL_ERROR_DESCRIPTION', { ns: 'common' }))
    } finally {
      setIsSubmittingAcceptance(false);
    }
  };

  const handleCloseFamilyModal = async () => {
    try {
      setIsSubmittingAcceptance(true);
      await request.patch(
        `/client-app/family-modal-read`
      );
      window.location.reload();
      setIsOpenFamilyModal(false);
    } catch (err) {
      console.error(err);
      alert(t('ACCEPTANCE_MODAL_ERROR_DESCRIPTION', { ns: 'common' }))
    } finally {
      setIsSubmittingAcceptance(false);
    }
  };

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user.plan?.familyPlan && !user.hasReadFamilyModal && user.hasReadTOCandPolicy && !user.isDependant) {
      setIsOpenFamilyModal(true)
    }
    //eslint-disable-next-line
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    const getUserHistory = async () => {
      try {
        const user = getUser();
        if (user.plan || user?.planStatus?.dueDate) {
          setUserHasPlan(true);
        } else {
          // Get all available plans to buy
          let countryId = user.country;
          if (typeof countryId === "object") {
            countryId = countryId._id;
          }
          const { data: result } = await request.get(
            `/client-app/country-plans/${countryId}`
          );
          setAvailablePlansToBuy((currentPlans) => result.plans);
        }
        const { data: result } = await request.get(
          `/client-app/history?id=${user.id}&limit=3`
        );

        setVaccinesHistory(result);
      } catch (error) {
        console.log(`[Home getUserHistory Error]: ${error}`);
      } finally {
        setLoading(false);
      }
    };
    getUserHistory();

    if (!user.hasReadTOCandPolicy) {
      setIsOpenAcceptanceModal(true);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header
        user={user}
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      {loading || loadingPasse ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        <>
          <CardsContainer>
            <RoundedCard
              startColor="#6EB1E9"
              endColor="#2D80C6"
              onClick={redirect.bind(this, "/secure/my-health/my-vaccinations-card")}
            >
              <img
                style={{ marginTop: 20 }}
                src="/assets/circle-vaccine.svg"
                alt="Vaccine"
              />
              <Typography className={classes.roundedCardText}>
                {t('VACCINES')}
              </Typography>
            </RoundedCard>

            <RoundedCard
              startColor="#73C385"
              endColor="#00A859"
              onClick={() => handleClickNewPasseVerde()}
            >
              <img
                style={{ marginTop: 20 }}
                src="/assets/passeverde-white-leaf.svg"
                alt="Passe Verde"
              />
              <Typography className={classes.roundedCardText}>
                {t('PASSE_VERDE')}
              </Typography>
            </RoundedCard>
          </CardsContainer>

          <CardsContainer style={{ marginTop: 40 }}>
            <Card onClick={redirect.bind(this, "/secure/calendar")}>
              <div className={classes.cardContent}>
                <img
                  src="/assets/circle-calendar.svg"
                  alt="Calendar Icon"
                  width="60px"
                />
                <Typography className={classes.cardText}>
                  {t('CALENDARS')}
                </Typography>
              </div>
              <div className={classes.middleSeparator}>
                <Typography
                  className={classes.cardText}
                  style={{ marginLeft: 10 }}
                >
                  37 {t('COUNTRIES')}
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.addContainer}>
                <Typography className={classes.cardText}>
                  {t('CONSULT')}
                </Typography>
              </div>
            </Card>

            <Card onClick={redirect.bind(this, "/secure/my-health")}>
              <div className={classes.cardContent}>
                <img
                  src="/assets/circle-healthcare.svg"
                  alt="Healthcare Icon"
                  width="60px"
                />
                <Typography className={classes.cardText}>
                  {t('HEALTH')}
                </Typography>
              </div >
              <div className={classes.middleSeparator}></div>
              <Divider className={classes.divider} />
              <div className={classes.addContainer}>
                <Typography className={classes.cardText}>
                  {t('UPDATE')}
                </Typography>
                <Typography className={classes.cardTextPlus}>
                  +
                </Typography>
              </div>
            </Card>
          </CardsContainer>

          <Modal
            aria-labelledby="update-pv-modal-title"
            aria-describedby="update-pv-modal-description"
            className={classes.modal}
            open={openPvUpdateModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openPvUpdateModal}>
              <div className={classes.modalPaper}>
                <Typography className={classes.modalHeader} variant="h6">
                  {t("MODAL_UPDATE_PASSE_VERDE_TITLE")}
                </Typography>
                <Typography
                  id="update-pv-modal-description-text"
                  className={classes.modalContent}
                >
                  {t("MODAL_UPDATE_PASSE_VERDE_DESCRIPTION")}
                </Typography>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                  }}
                >
                  <Button onClick={handleCloseModal}>
                    {t("MODAL_UPDATE_PASSE_VERDE_BACK_BUTTON")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={disableCurrentPvAndCreateNew}
                    style={{ backgroundColor: '#00A859' }}>
                    {t("MODAL_UPDATE_PASSE_VERDE_CONFIRM_BUTTON")}
                  </Button>
                </div>

              </div>
            </Fade>
          </Modal>

          <MainContent ref={myRef}>
            {userHasPlan ? (
              ""
            ) : availablePlansToBuy.length > 0 ? (
              availablePlansToBuy.map((plan) => (
                <SubscriptionContainer key={plan.id}>
                  <CardSubscription>
                    <Typography
                      variant="h6"
                      className={classes.subscriptionTitle}
                    >
                      {plan.displayName}
                    </Typography>
                    <Typography className={classes.subscriptionHeaderSubtitle}>
                      {plan.description || t('UNAVAILABLE_PLAN_DESCRIPTION')}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Typography className={classes.subscriptionValue}>
                      {`${plan.currency} ${plan.price}`}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      size="large"
                      disabled={loadingPaymentData}
                      onClick={() => createCheckout(plan)}
                      className={classes.subscriptionButton}
                    >
                      {t('BUY_NOW')}
                    </Button>
                  </CardSubscription>
                </SubscriptionContainer>
              ))
            ) : (
              <Typography className={classes.noPlansAvailableText}>
                {t('UNAVAILABLE_PLANS')}
              </Typography>
            )}

            {(vaccinesHistory || []).length !== 0 ? (
              <HistoryContainer>
                <HistoryCard>
                  <div className={classes.historyCardTitleContainer}>
                    <Typography variant="h6" className={classes.historyCardTitle}>
                      {t('HISTORY')}
                    </Typography>
                    {(vaccinesHistory || []).length >= 3 && !seeAll && (
                      <Typography
                        variant="h6"
                        className={classes.historyCardSeeAllText}
                        onClick={() => getAllUserHistory()}
                      >
                        {t('SEE_ALL')}
                      </Typography>
                    )}
                    {loadingAllVaccines && (
                      <div style={{ marginRight: 10 }}>
                        <CircularProgress size={12} />
                      </div>
                    )}
                  </div>
                  <div className={classes.historyContent}>
                    {(vaccinesHistory || []).map((vac, index) => {
                      if (vac) {
                        const tmp = vac.applicationDate
                          ? moment(vac.applicationDate).format("DD/MM/YYYY")
                          : moment(vac.date).format("DD/MM/YYYY");
                        return (
                          <HistoryRow key={`vac.vaccine ${index}`}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src="/assets/syringe_menu.svg"
                                alt="vaccine"
                                width="18px"
                                height="18px"
                              />
                              <p
                                style={{
                                  margin: "0px 12px 0px 20px",
                                  fontSize: "12px",
                                }}
                              >
                                {vac.type === "vaccine"
                                  ? t('VACCINE')
                                  : t('ANTIBODY')}{" "}
                                <b>{vac.vaccine}</b>
                              </p>
                            </div>
                            <p
                              style={{ fontSize: "11px", textAlign: "center" }}
                            >
                              <span>{tmp.split("T")[0]}</span> <br />
                              <span>{tmp.split("T")[1]}</span>
                            </p>
                          </HistoryRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </HistoryCard>
              </HistoryContainer >
            ) : (
              <HistoryContainer>
                <HistoryCard>
                  <div className={classes.historyCardTitleContainer}>
                    <Typography variant="h6" className={classes.historyCardTitle}>
                      {t('HISTORY')}
                    </Typography>
                  </div>
                  <div className={classes.historyContentNoVaccine}>
                    <Typography className={classes.historyContentNoVaccineText}>
                      {t('NO_HISTORY_AVAILABLE')}
                    </Typography>
                  </div>
                </HistoryCard>
              </HistoryContainer>
            )}
          </MainContent>

          <FamilyModal
            id="plan-error"
            open={isOpenFamilyModal}
            disabled={isSubmittingAcceptance}
            title={{
              text: "Bem Vindo",
            }}
            description={{
              text: "Você pode começar a adicionar as contas dos seus dependentes agora, clicando abaixo."
            }}
            button={{
              text: "ACESSAR ÁREA DA FAMÍLIA"
            }}
            extraButton="Sair"
            onClick={ async () => {
              await handleCloseFamilyModal();
              window.location.replace("/secure/accounts");
            }}
            onClickExtraBtn={handleCloseFamilyModal}
          />

          <AcceptanceModal
            id="acceptance"
            onClick={handleSubmitAcceptance}
            open={isOpenAcceptanceModal}
            isSubmitting={isSubmittingAcceptance}
          />

          {
            !loading && !loadingPasse && showJivoButton &&
            <JivoButton onClickCloseButton={handleHideJivoButton} />
          }
        </>
      )}
    </Container>
  );
};

export default Home;
