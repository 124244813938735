import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import { request } from "../../utils/request";
import {
  Grid,
  LinearProgress
} from "@material-ui/core";
import {
  ButtonContainer,
  MainButton,
  LoadingContainer
} from "./Components"
import FormEntrySelect from "../FormEntrySelect/FormEntrySelect";
import FormEntryText from "../FormEntryText/FormEntryText";
import FormEntryTextarea from "../FormEntryTextarea/FormEntryTextarea";
import FormEntryCheckbox from "../FormEntryCheckbox/FormEntryCheckbox";
import { FormEntryAutocomplete } from "../FormEntryAutocomplete";
import Dialog from "../../components/Dialog/Dialog";
import {
  DOCTOR_TYPE_OPTIONS,
  SPECIAL_NEEDS_OPTIONS,
  ALLERGIES_OPTIONS,
  BLOOD_TYPE_OPTIONS,
  RH_FACTOR_OPTIONS,
} from "./data";
import { useTranslation } from 'react-i18next';
import debounce from "lodash.debounce";

const HealthForm = ({
  userId,
  userHealthForm,
  countryDiseases,
}) => {
  const { t } = useTranslation(['healthPage', 'common']);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [doctorType, setDoctorType] = useState(userHealthForm?.healthData?.doctorType);
  const [doctorName, setDoctorName] = useState(userHealthForm?.healthData?.doctorName);
  const [doctorMobile, setDoctorMobile] = useState(userHealthForm?.healthData?.doctorMobile);
  const [clinicAddress, setClinicAddress] = useState(userHealthForm?.healthData?.clinicAddress);
  const [clinicPhone, setClinicPhone] = useState(userHealthForm?.healthData?.clinicPhone);
  const [specialNeeds, setSpecialNeeds] = useState(userHealthForm?.healthData?.specialNeeds);
  const [allergicTo, setAllergicTo] = useState(userHealthForm?.healthData?.allergicTo);
  const [feverAllowedMedication, setFeverAllowedMedication] = useState(userHealthForm?.healthData?.feverAllowedMedication);
  const [congenitalDisease, setCongenitalDisease] = useState('');
  const [ongoingTreatments, setOngoingTreatments] = useState(userHealthForm?.healthData?.ongoingTreatments);
  const [specificMedication, setSpecificMedication] = useState(userHealthForm?.healthData?.specificMedication);
  const [diseases, setDiseases] = useState(userHealthForm?.healthData?.diseases.map(disease => disease._id) || []);
  const [bloodType, setBloodType] = useState(userHealthForm?.healthData?.bloodType);
  const [rhFactor, setRhFactor] = useState(userHealthForm?.healthData?.rhFactor);
  const [height, setHeight] = useState(userHealthForm?.healthData?.height);
  const [weight, setWeight] = useState(userHealthForm?.healthData?.weight);

  const [cndSearchQuery, setCndSearchQuery] = useState('');
  const [isLoadingCnd, setIsLoadingCnd] = useState(false);
  const [cndOptions, setCndOptions] = useState([]);

  const [feedback, setFeedback] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDiseaseChange = (e) => {
    if (e.target.checked) {
      setDiseases([...diseases, e.target.name])
    } else {
      const updatedDiseases = diseases.filter((item) => item !== e.target.name);
      setDiseases(updatedDiseases)
    }
  };

  const onChangeCnd = (_, value) => {
    setCongenitalDisease(value);
  };

  const onCndInputChange = (_, value) => {
    setCndSearchQuery(value);
  };

  const renderCndOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          {`${option?.code} - ${option?.description?.['PT']}`}
        </Grid>
      </Grid>
    );
  };

  const filterCndsOptions = (options) => options;

  const getCndsOptionLabel = (option) => {
    if (!option) {
      return ''
    }

    return `${option?.code} - ${option?.description?.['PT']}`
  };

  const getCndsOptionsAsync = async (query) => {
    try {
      const loweredQuery = query.toLowerCase();

      if (loweredQuery === '' || !loweredQuery.startsWith('q')) {
        return [];
      }

      const { data: queryResult } = await request.get(
        `/client-app/find-icds?code=${loweredQuery}`
      );

      return queryResult;
    } catch (err) {
      console.error(`[getCndsOptionsAsync] ${err}`);
      return [];
    }
  };

  const getCndsOptionsDelayed = useCallback(
    debounce((query, callback) => {
      setCndOptions([]);
      getCndsOptionsAsync(query).then(callback);
    }, 300),
    []
  );

  const confirmHandler = async () => {
    try {
      const data = {
        userId,
        healthFormId: userHealthForm?._id,
        healthData: {
          doctorType,
          doctorName,
          doctorMobile,
          clinicAddress,
          clinicPhone,
          specialNeeds,
          allergicTo,
          feverAllowedMedication,
          congenitalDisease: congenitalDisease?.code,
          ongoingTreatments,
          specificMedication,
          diseases,
          bloodType,
          rhFactor,
          height,
          weight
        }
      }
      setIsSubmitting(true);
      await request.post("/client-app/update-health-form", data);
      setFeedback(t('HEALTH_DATA_SUBMIT_SUCCESS_DESCRIPTION'));
      setDialogOpen(true);

    } catch (error) {
      console.log(`[HealthForm confirmHandler Error]: ${error}`);
      let message = t('SUBMIT_ERROR_RESPONSE');

      const { isAxiosError } = error;
      if (isAxiosError) {
        message = error.response.data.message;
      }

      setFeedback(message)
      setDialogOpen(true);

    } finally {
      setIsSubmitting(false)
    }
  };

  useEffect(() => {
    setIsLoadingCnd(true);

    getCndsOptionsDelayed(cndSearchQuery, (options) => {
      setCndOptions(options);

      setIsLoadingCnd(false);
    });

  }, [cndSearchQuery, getCndsOptionsDelayed]);

  useEffect(() => {
    if (!userHealthForm?.healthData?.congenitalDisease) return;
    setIsLoadingCnd(true);

    getCndsOptionsDelayed(userHealthForm?.healthData?.congenitalDisease, (options) => {
      setCndOptions(options);
      setCongenitalDisease(options?.[0] || '');
      setIsLoadingCnd(false);
    });

    // eslint-disable-next-line
  }, [userHealthForm?.healthData?.congenitalDisease]);

  return (
    <div>
      <FormEntrySelect
        id="doctorType"
        onChange={(event) => setDoctorType(event.target.value)}
        label={t('HEALTH_DATA_DOCTOR_TYPE')}
        value={doctorType}
        disabled={isSubmitting}
      >
        <option value="">
          {t('SELECT_AN_OPTION', { ns: 'common' })}
        </option>
        {DOCTOR_TYPE_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {t(option.name)}
          </option>
        ))}
      </FormEntrySelect>

      <FormEntryText
        id="doctorName"
        label={t('HEALTH_DATA_DOCTOR_NAME')}
        onChange={(event) => setDoctorName(event.target.value)}
        value={doctorName}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="doctorMobile"
        label={t('HEALTH_DATA_DOCTOR_MOBILE')}
        onChange={(event) => setDoctorMobile(event.target.value)}
        value={doctorMobile}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="clinicAddress"
        label={t('HEALTH_DATA_CLINIC_ADDRESS')}
        onChange={(event) => setClinicAddress(event.target.value)}
        value={clinicAddress}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="clinicPhone"
        label={t('HEALTH_DATA_CLINIC_TELEPHONE')}
        onChange={(event) => setClinicPhone(event.target.value)}
        value={clinicPhone}
        disabled={isSubmitting}
      />

      <FormEntrySelect
        id="specialNeeds"
        label={t('HEALTH_DATA_SPECIAL_NEEDS')}
        onChange={(event) => setSpecialNeeds(event.target.value)}
        value={specialNeeds}
        disabled={isSubmitting}
      >
        <option value="">
          {t('SELECT_AN_OPTION', { ns: 'common' })}
        </option>
        {SPECIAL_NEEDS_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {t(option.name)}
          </option>
        ))}
      </FormEntrySelect>

      <FormEntrySelect
        id="allergicTo"
        label={t('HEALTH_DATA_ALLERGIC_TO')}
        onChange={(event) => setAllergicTo(event.target.value)}
        value={allergicTo}
        disabled={isSubmitting}
      >
        <option value="">
          {t('SELECT_AN_OPTION', { ns: 'common' })}
        </option>
        {ALLERGIES_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {t(option.name)}
          </option>
        ))}
      </FormEntrySelect>

      <FormEntryTextarea
        id="feverAllowedMedication"
        label={t('HEALTH_DATA_FEVER_MEDICATION')}
        onChange={(event) => setFeverAllowedMedication(event.target.value)}
        value={feverAllowedMedication}
        disabled={isSubmitting}
      />

      <FormEntryAutocomplete
        id="congenitalDisease"
        label={t('HEALTH_DATA_CONGENITAL_DISEASE')}
        options={cndOptions}
        value={congenitalDisease}
        onChange={onChangeCnd}
        onInputChange={onCndInputChange}
        getOptionLabel={getCndsOptionLabel}
        filterOptions={filterCndsOptions}
        loading={isLoadingCnd}
        loadingText={t('HEALTH_DATA_LOADING')}
        noOptionsText={cndSearchQuery === '' ? t('HEALTH_DATA_TYPE_DISEASE_CODE') : t('HEALTH_DATA_OPTION_NOT_FOUND')}
        renderOption={renderCndOption}
        disabled={isSubmitting}
      />

      <FormEntryCheckbox
        id="diseases"
        label={t('HEALTH_DATA_DISEASES')}
        subtext={t('HEALTH_DATA_DISEASES_DESCRIPTION')}
        onChange={handleDiseaseChange}
        options={countryDiseases}
        selectedOptions={diseases}
        translations={t}
        disabled={isSubmitting}
      />

      <FormEntryTextarea
        id="ongoingTreatments"
        label={t('HEALTH_DATA_ONGOING_TREATMENTS')}
        onChange={(event) => setOngoingTreatments(event.target.value)}
        value={ongoingTreatments}
        disabled={isSubmitting}
      />

      <FormEntryTextarea
        id="specificMedication"
        label={t('HEALTH_DATA_SPECIFIC_MEDICATION')}
        onChange={(event) => setSpecificMedication(event.target.value)}
        value={specificMedication}
        disabled={isSubmitting}
      />

      <FormEntrySelect
        id="bloodType"
        label={t('HEALTH_DATA_BLOOD_TYPE')}
        onChange={(event) => setBloodType(event.target.value)}
        value={bloodType}
        disabled={isSubmitting}
      >
        <option value="">
          {t('SELECT_AN_OPTION', { ns: 'common' })}
        </option>
        {BLOOD_TYPE_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {t(option.name)}
          </option>
        ))}
      </FormEntrySelect>

      <FormEntrySelect
        id="rhFactor"
        label={t('HEALTH_DATA_RH_FACTOR')}
        onChange={(event) => setRhFactor(event.target.value)}
        value={rhFactor}
        disabled={isSubmitting}
      >
        <option value="">
          {t('SELECT_AN_OPTION', { ns: 'common' })}
        </option>
        {RH_FACTOR_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {t(option.name)}
          </option>
        ))}
      </FormEntrySelect>

      <FormEntryText
        id="height"
        label={t('HEALTH_DATA_HEIGHT')}
        onChange={(event) => setHeight(event.target.value)}
        value={height}
        disabled={isSubmitting}
      />

      <FormEntryText
        id="weight"
        label={t('HEALTH_DATA_WEIGHT')}
        onChange={(event) => setWeight(event.target.value)}
        value={weight}
        disabled={isSubmitting}
      />

      <ButtonContainer marginTop="20px">
        {
          isSubmitting ?
            <LoadingContainer>
              <LinearProgress />
            </LoadingContainer>
            :
            <MainButton onClick={confirmHandler} disabled={isSubmitting}>
              {t('HEALTH_DATA_SUBMIT_BUTTON')}
            </MainButton>
        }
      </ButtonContainer>

      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>
    </div>
  );
};

export default HealthForm;
