import React, {
  useEffect,
  useState
} from "react";
import { request } from "../../utils/request";
import { useTranslation } from 'react-i18next';
import { CircularProgress, makeStyles, Button as MUIButton, Box } from "@material-ui/core";
import { Add as AddIcon } from '@material-ui/icons';
import { getUser } from '../../utils/user';
import {
  Button,
  Container,
  Title,
  AddButtonContainer,
  AddButton,
  AddButtonText,
  CenterColumn,
} from "./Components.js";
import Header from "../../components/Header/Header";
import BoxUploadFile from '../../components/BoxUploadFile/BoxUploadFile';
import { FeedbackModal } from "../../components/FeedbackModal/FeedbackModal";
import { TOCModal } from "../../components/TOCModal";
import { SpecialCaseModal } from "../../components/SpecialCaseModal";
import Dialog from "../../components/Dialog/Dialog";
import { UserConfirmationModal } from "./UserConfirmationModal";

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },

  specialCaseButtonContainer: {
    marginBottom: 85,
    marginTop: 70,
  },

  specialCase: {
    textTransform: 'none',
    color: '#2D80C6',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const UploadVaccinationsCard = () => {
  const { t } = useTranslation('vaccinationsCardPage');
  const classes = useStyles();

  const [specialCase, setSpecialCase] = useState(undefined);

  const [isOpenTOCModal, setIsOpenTOCModal] = useState(false);
  const [isTOCChecked, setIsTOCChecked] = useState(false);
  const [isOpenSpecialCaseTOCModal, setIsOpenSpecialCaseTOCModal] = useState(false);
  const [isSpecialCaseTOCChecked, setIsSpecialCaseTOCChecked] = useState(false);

  const [isOpenSubmitErrorModal, setIsOpenSubmitErrorModal] = useState(false);
  const [isOpenSubmitSuccessModal, setIsOpenSubmitSuccessModal] = useState(false);
  const [isOpenUploadUnavailableModal, setIsOpenUploadUnavailableModal] = useState(false);
  const [isOpenSpecialCaseModal, setIsOpenSpecialCaseModal] = useState(false);
  const [isOpenUserConfirmationModal, setIsOpenUserConfirmationModal] = useState(false);


  const [isLoadingVaccinationCardStatus, setIsLoadingVaccinationCardStatus] = useState(false);
  const [isUploadingVaccinationCard, setIsUploadingVaccinationCard] = useState(false);
  const [isSubmittingVaccinationsCardFiles, setIsSubmittingVaccinationsCardFiles] = useState(false);

  const [vaccinationCardFiles, setVaccinationCardFiles] = useState(null);
  const [extraUploadBoxQuantity, setExtraUploadBoxQuantity] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const user = getUser();

  const handleOpenSpecialCaseModal = () => {
    setIsOpenSpecialCaseModal(true);
  };

  const handleCloseSpecialCaseModal = () => {
    setIsOpenSpecialCaseModal(false);
  };

  const handleChangeSpecialCase = (type) => {
    setSpecialCase(type);
    setIsOpenSpecialCaseTOCModal(true);
    handleCloseSpecialCaseModal();
    setVaccinationCardFiles(null);
    setExtraUploadBoxQuantity([]);
  };

  const handleRedirectModalButton = (modalName) => {
    if (modalName === 'submitError') {
      setIsOpenSubmitErrorModal(false);
      window.location.replace("/secure");
    };

    if (modalName === 'submitSuccess') {
      setIsOpenSubmitSuccessModal(false);
      window.location.replace("/secure/my-health/my-vaccinations-card/consult-vaccinations-card");
    };

    if (modalName === 'uploadUnavailable') {
      setIsOpenUploadUnavailableModal(false);
      window.location.replace("/secure/my-health/my-vaccinations-card/consult-vaccinations-card");
    };
  };

  const handleUploadFile = async (file, documentType) => {
    try {
      setIsUploadingVaccinationCard(true);
      const formData = new FormData();
      formData.append(`file.${documentType}`, file);
      const imageResult = await request.post(
        `/client-app/upload-covid-document?documentType=${documentType}`,
        formData
      );

      const newVaccinationCard = {
        ...vaccinationCardFiles,
        [documentType]: imageResult?.data?.fileId,
      };

      setVaccinationCardFiles(newVaccinationCard);
    } catch (error) {
      console.error(`[UploadVaccinationsCard handleUpload] ${error}`);
    } finally {
      setIsUploadingVaccinationCard(false);
    }
  };

  const handleClickAddUploadBox = () => {
    setExtraUploadBoxQuantity([...extraUploadBoxQuantity, 'newBox']);
  };

  const handleClickRemoveUploadBox = (position) => {
    const updatedExtraUploadBoxQuantity = [...extraUploadBoxQuantity];
    delete updatedExtraUploadBoxQuantity[position];

    const updatedVaccinationCardFiles = { ...vaccinationCardFiles };
    delete updatedVaccinationCardFiles[`vaccinationCard${position}`];

    setExtraUploadBoxQuantity(updatedExtraUploadBoxQuantity);
    setVaccinationCardFiles(updatedVaccinationCardFiles);
  };

  const handleSubmitVaccinationCardFiles = async () => {
    try {
      setIsSubmittingVaccinationsCardFiles(true);

      if (!vaccinationCardFiles) {
        return;
      }

      const vaccinationRecords = Object.values(vaccinationCardFiles).map(file => ({
        file: file,
        especialCaseType: specialCase
      }));

      if (!vaccinationRecords.length) {
        throw new Error('Missing files');
      };

      const body = {
        vaccinationRecords,
      };

      await request.post(
        `/client-app/vaccination-card-registry`,
        body
      );

      setIsOpenSubmitSuccessModal(true);
    } catch (error) {
      console.error(`[UploadVaccinationsCard handleSubmit] ${error}`);

      if (error.message === 'Missing files') {
        setFeedback(t('UPLOAD_VACCINATIONS_CARD_MISSING_FILE_ERROR_CONTENT'));
        setDialogOpen(true);

        return;
      };

      setIsOpenSubmitErrorModal(true);
    } finally {
      setIsSubmittingVaccinationsCardFiles(false);
    }
  };

  useEffect(() => {
    const getUserVaccinationCardStatus = async () => {
      try {
        setIsLoadingVaccinationCardStatus(true);
        const result = await request.get(`/client-app/vcr-check`);

        if (!result.data?.allowNewUpload) {
          setIsOpenUploadUnavailableModal(true);
          return;
        };

        if (!isTOCChecked) {
          setIsOpenTOCModal(true);
        };
      } catch (error) {
        if (error.response?.data?.statusCode === 401) {
          setIsOpenSubmitErrorModal(true);
        };

        console.error(`[UploadVaccinationsCard getUserVaccinationCardStatus] ${error}`);
      } finally {
        setIsLoadingVaccinationCardStatus(false);
      }
    };

    getUserVaccinationCardStatus();

    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>
        {
          specialCase !== undefined
            ? t('SPECIAL_CASE_UPLOAD_EXCEPTIONS')
            : t('UPLOAD_VACCINATIONS_CARD')
        }
      </Title>

      {
        isLoadingVaccinationCardStatus ?
          <CenterColumn style={{ marginTop: 50 }}>
            <CircularProgress />
          </CenterColumn>
          :
          <>
            <Box className={classes.container}>
              <BoxUploadFile
                marginTop={30}
                id="vaccination-card-file-uploader"
                title={
                  specialCase === 'ANTIBODY'
                    ? t('SPECIAL_CASE_SEND_ANTIBODY')
                    : specialCase === 'MEDICAL_DECLARATION'
                      ? t('SPECIAL_CASE_SEND_MEDICAL_DECLARATION')
                      : t('UPLOAD_VACCINATIONS_CARD_VACCINATION_CARD')
                }
                buttonText={t('UPLOAD_VACCINATIONS_CARD_UPLOAD_FILE')}
                buttonTextWhenDocumentUploaded={t('UPLOAD_VACCINATIONS_CARD_UPLOADED_FILE_BUTTON_TEXT')}
                documentUploadedText={t('UPLOAD_VACCINATIONS_CARD_UPLOADED_FILE_TEXT')}
                isLoading={isUploadingVaccinationCard}
                displayOnly={vaccinationCardFiles?.["vaccinationCard"]}
                componentType="anyFile"
                secondaryColor
                onChange={(file) => handleUploadFile(file, "vaccinationCard")}
              />

              {
                extraUploadBoxQuantity.length > 0 &&
                extraUploadBoxQuantity.map((uploadBox, index) => {
                  if (uploadBox === undefined) {
                    return null;
                  }

                  return (
                    <BoxUploadFile
                      key={`vaccination-upload-box-${index}`}
                      id={`vaccination-card-file-uploader-${index}`}
                      buttonText={t('UPLOAD_VACCINATIONS_CARD_UPLOAD_FILE')}
                      buttonTextWhenDocumentUploaded={t('UPLOAD_VACCINATIONS_CARD_UPLOADED_FILE_BUTTON_TEXT')}
                      documentUploadedText={t('UPLOAD_VACCINATIONS_CARD_UPLOADED_FILE_TEXT')}
                      isLoading={isUploadingVaccinationCard}
                      displayOnly={vaccinationCardFiles?.[`vaccinationCard${index}`]}
                      componentType="anyFile"
                      secondaryColor
                      onChange={(file) => handleUploadFile(file, `vaccinationCard${index}`)}
                      onClickTrashIcon={() => handleClickRemoveUploadBox(index)}
                      showTrashIcon
                      marginTop={30}
                    />
                  )
                })
              }

              <AddButtonContainer>
                <AddButton onClick={handleClickAddUploadBox}>
                  <AddButtonText>
                    {t('UPLOAD_VACCINATIONS_CARD_UPLOAD_ADDITIONAL_FILES')}
                  </AddButtonText>
                  <AddIcon style={{ fontSize: 15 }} />
                </AddButton>
              </AddButtonContainer>

              <Button
                onClick={handleSubmitVaccinationCardFiles}
                disabled={isSubmittingVaccinationsCardFiles || isUploadingVaccinationCard || !vaccinationCardFiles || !Object.values(vaccinationCardFiles).length}
              >
                {
                  isSubmittingVaccinationsCardFiles
                    ? <CircularProgress size={14} />
                    : t('UPLOAD_VACCINATIONS_CARD_BUTTON_TITLE')
                }
              </Button>
            </Box>

            {specialCase === undefined && (
              <Box className={classes.specialCaseButtonContainer}>
                <MUIButton
                  className={classes.specialCase}
                  onClick={handleOpenSpecialCaseModal}
                >
                  {t('SPECIAL_CASE_BUTTON')}
                </MUIButton>
              </Box>
            )}
          </>
      }

      <SpecialCaseModal
        open={isOpenSpecialCaseModal}
        onClose={handleCloseSpecialCaseModal}
        onClick={handleChangeSpecialCase}
      />

      <TOCModal
        id="toc-modal-vaccination-card"
        open={isOpenTOCModal}
        content={{
          title: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_TITLE'),
          description: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_DESCRIPTION'),
          checkboxLabel: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_CONFIRMATION_TEXT'),
          backButtonTitle: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_CANCEL'),
          confirmButtonTitle: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_CONFIRM'),
        }}
        checked={isTOCChecked}
        onClick={() => {setIsOpenTOCModal(false); setIsOpenUserConfirmationModal(true)}}
        onChange={() => setIsTOCChecked(!isTOCChecked)}
      />

      <TOCModal
        id="toc-modal-vaccination-card-special-case"
        open={isOpenSpecialCaseTOCModal}
        content={{
          title: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_TITLE'),
          description: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_DESCRIPTION'),
          checkboxLabel: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_CONFIRMATION_TEXT'),
          backButtonTitle: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_CANCEL'),
          confirmButtonTitle: t('UPLOAD_VACCINATIONS_CARD_TOC_MODAL_CONFIRM'),
        }}
        checked={isSpecialCaseTOCChecked}
        onClick={() => {setIsOpenSpecialCaseTOCModal(false); setIsOpenUserConfirmationModal(true)}}
        onChange={() => setIsSpecialCaseTOCChecked(!isSpecialCaseTOCChecked)}
      />

      <FeedbackModal
        id="upload-unavailable"
        open={isOpenUploadUnavailableModal}
        title={{
          text: t('UPLOAD_VACCINATIONS_CARD_UPLOAD_UNAVAILABLE_MODAL_TITLE'),
        }}
        description={{
          text: t('UPLOAD_VACCINATIONS_CARD_UPLOAD_UNAVAILABLE_MODAL_CONTENT'),
        }}
        button={{
          text: t('UPLOAD_VACCINATIONS_CARD_MODAL_BUTTON'),
        }}
        onClick={() => handleRedirectModalButton('uploadUnavailable')}
      />

      <FeedbackModal
        id="plan-error"
        open={isOpenSubmitErrorModal}
        title={{
          text: t('UPLOAD_VACCINATIONS_CARD_MODAL_TITLE'),
        }}
        description={{
          // text: t('UPLOAD_VACCINATIONS_CARD_ERROR_MODAL_CONTENT'),
          text: "A funcionalidade de envio de cartões de vacinas está disponível apenas para crianças e adolescentes neste momento. Gostaria que esta comodidade também esteja disponível para adultos ?"
        }}
        button={{
          text: "Sim, me avise quando estiver"
        }}
        extraButton="Não, obrigado"
        onClick={() => handleRedirectModalButton('submitError')}
      />

      <UserConfirmationModal
        id="plan-error"
        open={isOpenUserConfirmationModal}
        title={{
          text: "Confirmação",
        }}
        userName={user.name}
        userBirthdate={user.birthday}
        description={{
          text: "Você está enviando informações para atualização do cartão de: "
        }}
        button={{
          text: "Confirmar"
        }}
        extraButton="Alterar"
        onClick={() => setIsOpenUserConfirmationModal(false)}
        onClickExtraBtn={() => {
          setIsOpenSpecialCaseModal(false);
          window.location.replace("/secure/accounts");
        }}
      />
      <FeedbackModal
        id="submit-succeed"
        open={isOpenSubmitSuccessModal}
        title={{
          text: t('UPLOAD_VACCINATIONS_CARD_MODAL_TITLE'),
        }}
        description={{
          text: t('UPLOAD_VACCINATIONS_CARD_SUBMIT_SUCCESS_MODAL_CONTENT'),
        }}
        button={{
          text: t('UPLOAD_VACCINATIONS_CARD_MODAL_BUTTON'),
          backgroundColor: '#00A859'
        }}
        onClick={() => handleRedirectModalButton('submitSuccess')}
      />

      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>
    </Container>
  );
};

export default UploadVaccinationsCard;
