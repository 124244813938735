import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Title,
  FormContainer,
} from './Components';
import Header from '../../components/Header/Header';
import { RedirectButton } from '../../components/RedirectButton';

const MedicalCertificate = () => {
  const { t } = useTranslation('medicalCertificatePage');

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>
        {t('TITLE')}
      </Title>

      <FormContainer>
        <RedirectButton
          title={t('UPLOAD_MEDICAL_CERTIFICATE')}
          href="/secure/my-health/medical-certificate/upload-certificate"
          marginBottom="20px"
        />
        <RedirectButton
          title={t('CONSULT_MEDICAL_CERTIFICATE')}
          href="/secure/my-health/medical-certificate/consult-certificate"
          marginBottom="20px"
        />
      </FormContainer>
    </Container >
  );
}

export default MedicalCertificate;