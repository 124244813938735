import React from "react";
import { Link } from "react-router-dom";

const NotFound = (props) => {
  return (
    <div>
      <h3>Sorry (404)</h3>
      <p>Page not found!</p>
      <Link to="/">Go to Index</Link>
    </div>
  );
}

export default NotFound;
