import styled from "styled-components";

const DocumentDescription = styled.div`
  display: flex;
  flex: 1;
  margin-left: 0;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
`;

export {
  DocumentDescription,
};