const servicesLinks = [
  {
    name: 'SERVICES_PASSE_VERDE',
    url: '/secure/passe-verde',
    icon: '',
    source: '/assets/passeverde-grey.svg',
  },
  {
    name: 'SERVICES_DIGITAL_CARD',
    url: '/secure/my-card',
    icon: '',
    source: '/assets/syringe-grey.svg',
  },
  {
    name: 'SERVICES_CALENDAR',
    url: '/secure/calendar',
    icon: '',
    source: '/assets/calendar-grey.svg',
  },
  {
    name: 'SERVICES_SHARE_DATA',
    url: '/secure/digital-certificates',
    icon: 'Share',
    source: '',
  },
]

const myDataLinks = [
  {
    name: 'MY_DATA_PROFILE',
    url: '/secure/profile',
    icon: 'Avatar',
    source: '',
  },
  {
    name: 'MY_DATA_PRIVACY_AND_SECURITY',
    url: '/secure/privacy-and-security',
    icon: 'Privacy',
    source: '',
  },
  {
    name: 'MY_DATA_HEALTH',
    url: '/secure/my-health',
    icon: '',
    source: '/assets/health-care-grey.svg',
  },
  {
    name: 'MY_DATA_CONTACTS',
    url: '/secure/my-health',
    icon: '',
    source: '/assets/medical-bag-grey.svg',
  },
  {
    name: 'MY_DATA_OTHERS_UPLOADS',
    url: '/secure/my-data',
    icon: 'Backup',
    source: '',
  },
]

const informationLinks = [
  {
    name: 'INFORMATION_FAQ',
    url: '/secure/faq',
    icon: '',
    source: '/assets/quiz-grey.svg',
  },
  {
    name: 'INFORMATION_TOC',
    url: '/secure/terms-and-conditions',
    icon: '',
    source: '/assets/summary-grey.svg',
  },
  {
    name: 'INFORMATION_PRIVACY_POLICY',
    url: '/secure/privacy-policy',
    icon: 'Policy',
    source: '',
  },
]

export {
  servicesLinks,
  myDataLinks,
  informationLinks,
}