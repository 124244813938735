import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  FormContainer,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  Title,
  CenterColumn,
} from './Components';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  input: {
    width: '95%',
  },
}));


const NewPasswordForm = (props) => {
  const { t } = useTranslation('confirmPasswordPage');
  const location = useLocation()

  const data = location.pathname.split('/').slice(-2);

  const token = data[0];
  const email = data[1];

  const [loading, setLoading] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const classes = useStyles();

  // Fields
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Configuration
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (event) => {
    if (!confirmPassword || !password) {
      alert(t('VALIDATION_COMPLETE_ALL_FIELDS_ERROR'));
      return;
    }

    if (confirmPassword !== password) {
      alert(t('VALIDATION_PASSWORD_MUST_BE_EQUAL_ERROR'));
      return;
    }

    if (confirmPassword?.length < 6) {
      alert(t('VALIDATION_PASSWORD_MIN_ERROR'));
      return;
    }

    try {
      event.preventDefault();
      setLoading(true);

      await axios.post(`${process.env.REACT_APP_API_URL}/client-app/confirm-new-password`, {
        email: email,
        password: password,
        token: token,
      });
      setRecovered(true)
    } catch (error) {
      setLoading(false);
      alert(t('SUBMIT_RESPONSE_ERROR'));
      console.log(`[NewPasswordForm Error]: ${error}`);
    }
  };

  return (
    <div>
      {recovered && <Redirect to={'/secure'} />}
      <FormContainer>
        <Title>{t('UPDATE_PASSWORD')}</Title>
        <FieldContainer marginBottom="20px">
          <LockIcon />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-password">
                {t('PASSWORD')}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                autocomplete="off"
                label={t('PASSWORD')}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: 'off',
                  'aria-autocomplete': 'off',
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        <FieldContainer marginBottom="20px">
          <LockIcon />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-confirm-password">
                {t('CONFIRM_PASSWORD')}
              </InputLabel>
              <Input
                id="standard-adornment-confirm-password"
                autocomplete="off"
                label={t('CONFIRM_PASSWORD')}
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: 'off',
                  'aria-autocomplete': 'off',
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <ButtonsContainer>
            <MainButton onClick={onSubmit}>
              {t('SUBMIT')}
            </MainButton>
          </ButtonsContainer>
        )}
      </FormContainer>
    </div>
  );
};

export default NewPasswordForm;
