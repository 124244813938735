import moment from 'moment-timezone';

const setAccessCode = (code, type = 'hcp') => {
  const serializedCode = JSON.stringify(code);
  sessionStorage.setItem(`access_code_${type}`, serializedCode);
};
const setIcvAccessCode = (code) => {
  const serializedCode = JSON.stringify(code);
  sessionStorage.setItem('access_icv_code', serializedCode);
};

const getAccessCode = (type) => {
  const serializedCode = sessionStorage.getItem(`access_code_${type}`);
  const deserializedCode = JSON.parse(serializedCode);
  return deserializedCode;
};

const getIcvAccessCode = () => {
  const serializedCode = sessionStorage.getItem('access_icv_code');
  const deserializedCode = JSON.parse(serializedCode);
  return deserializedCode;
};

const isAccessCodeValid = (codeDetails) => {
  if (!codeDetails) return false;

  const { expireAfterSeconds, createdAt, code: accessCode } = codeDetails;

  if (!accessCode) return false;

  const isExpired = moment
    .tz(createdAt, 'UTC')
    .add(expireAfterSeconds, 'seconds')
    .isBefore(moment.utc());

  const hasSixDigits = accessCode.length === 6;

  return !isExpired && hasSixDigits;
};

const removeAccessCode = () => {
  sessionStorage.removeItem('access_code');
};
const removeIcvAccessCode = () => {
  sessionStorage.removeItem('access_icv_code');
};

export {
  setAccessCode,
  getAccessCode,
  isAccessCodeValid,
  setIcvAccessCode,
  getIcvAccessCode,
  removeAccessCode,
  removeIcvAccessCode,
};
