const setEvent = (event) => {
  const serializedEvent = JSON.stringify(event);
  sessionStorage.setItem('__event', serializedEvent);
}

const getEvent = () => {
  const serializedEvent = sessionStorage.getItem('__event');
  const deserializedEvent = JSON.parse(serializedEvent);
  return deserializedEvent;
}

export {
  setEvent,
  getEvent,
};