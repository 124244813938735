const setUser = (user) => {
  const serializedUser = JSON.stringify(user);
  localStorage.setItem('__user', serializedUser);
}

const getUser = () => {
  const serializedUser = localStorage.getItem('__user');
  const deserializedUser = JSON.parse(serializedUser);
  return deserializedUser;
}

export {
  setUser,
  getUser,
};