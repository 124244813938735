import styled from 'styled-components';

const Button = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #00A859;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: .2s;
  cursor: pointer;
`;

export {
  Button
}