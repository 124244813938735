import React, { useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { setUser } from '../../utils/user';
import {
  FormContainer,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  TextButton,
  Title,
  CenterColumn,
} from './Components';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../Dialog/Dialog';
import InstallPWAiOSModal from '../InstallPWAiOSModal/InstallPWAiOSModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import { Button } from '../AcceptanceModal/Components';

const useStyles = makeStyles(() => ({
  input: {
    width: '95%',
  },
}));

const RELEVANT_EMAIL_DOMAINS = new Set([
  'substituirinterne.com',
  'substituirunimed.com',
  'substituir.com',
]);

const LoginForm = ({ setAuth }) => {
  const { t } = useTranslation('loginPage');
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openRecoveryAccountModal, setOpenRecoveryAccountModal] = useState(false);
  const [userData, setUserData] = useState()

  const [feedback, setFeedback] = useState(null);
  const classes = useStyles();

  // Fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Configuration
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const orderByNewestTravelHistory = (a, b) => {
    return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
      ? - 1
      : new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()
        ? 1
        : 0
  };

  const handleRecovery = async (event) => {
    setOpenRecoveryAccountModal(false)
    try {
      event.preventDefault();
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/client-app/recovery`,
        {
          userId: userData
        }
      );

      setFeedback("Conta foi restabelecida com sucesso, por favor efetuar o login")
      setDialogOpen(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  };

  const onSubmit = async (event) => {
    if (!email || !password) {
      setFeedback(t('VALIDATION_COMPLETE_ALL_FIELDS_ERROR'));
      setDialogOpen(true);
      return;
    }

    try {
      event.preventDefault();
      setLoading(true);

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/client-app/login`,
        {
          email: email,
          password: password,
        }
      );

      const userBirthDate = result.data?.user?.birthday;

      if (!userBirthDate) {
        const descSortedTravelHistory = result.data?.user?.travelHistory
          .sort(orderByNewestTravelHistory);

        const state = {
          temporaryJwt: result.data?.jwt,
          destination: descSortedTravelHistory[0]?.destination,
          nextTripDate: result.data?.user?.nextTripDate,
        };

        history.push('/voegol-register-update', state)
        return;
      }

      const userEmail = result.data?.user?.email;
      const emailDomain = userEmail.split('@')[1];

      const redirectToUpdate = RELEVANT_EMAIL_DOMAINS.has(emailDomain);

      if (redirectToUpdate) {
        const state = {
          temporaryJwt: result.data?.jwt,
        };

        history.push('/reset-login-data', state)
        return;
      }

      localStorage.setItem('__session', result.data.jwt);
      setUser(result.data.user);
      setAuth(true);
      setLogged(true);
    } catch (error) {
      setLoading(false);

      const axiosError = error.response?.data
      if (axiosError.message?.startsWith("user blocked")) {
        // Deal with blocked user
        const infoArray = axiosError.message.split(" - ");
        const dateString = infoArray[1];
        const userId = infoArray[2];
        const userUpdatedAt = moment(dateString)
        const diff = moment().diff(userUpdatedAt, 'days');
        if (diff > 30) {
          setFeedback("Conta excluída pelo usuário há mais de 30 dias");
        } else {
          setUserData(userId)
          setFeedback(`Você solicitou a exclusão de sua conta há ${diff} dias`)
          setOpenRecoveryAccountModal(true)
        }
        return
      }

      setFeedback(t('SUBMIT_VALIDATION_ERROR_RESPONSE'));
      setDialogOpen(true);
      console.log('[LoginForm] Error:', error);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      {logged && <Redirect to={'/secure'} />}
      <FormContainer>
        <InstallPWAiOSModal />
        <Title>{t('INSERT_LOGIN_INFORMATIONS')}</Title>
        <FieldContainer>
          <AccountCircle fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <TextField
                type='email'
                label={t('USERNAME_PLACEHOLDER')}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                disabled={loading}
                inputProps={{
                  autocomplete: 'off',
                  'aria-autocomplete': 'off',
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        <FieldContainer marginBottom="20px">
          <LockIcon fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-password">
                {t('PASSWORD_PLACEHOLDER')}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                autocomplete="off"
                label={t('PASSWORD_PLACEHOLDER')}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: 'off',
                  'aria-autocomplete': 'off',
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>
        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <ButtonsContainer>
            <MainButton onClick={onSubmit}>
              {t('SUBMIT')}
            </MainButton>
            <div style={{ width: '100%' }}>
              <Link to="/forgot-password">
                <TextButton marginTop="20px">
                  {t('FORGET_PASSWORD')}
                </TextButton>
              </Link>
            </div>
          </ButtonsContainer>
        )}
      </FormContainer>
      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>
      <Dialog open={openRecoveryAccountModal} closeDialogHandler={setOpenRecoveryAccountModal}>
        <Box>
          <Typography>{feedback}</Typography>
          <Box style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button style={{ width: 220 }} onClick={handleRecovery}>Restabelecer Acesso</Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default LoginForm;
