import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; 
`

const Text = styled.p`
  font-size: 12px;
`

const Time = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
`

export { Container, Text, Time }