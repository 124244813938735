import React, { useState } from 'react';
import axios from 'axios';
import {
  FormContainer,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  Title,
  Text,
  CenterColumn,
} from './Components';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '95%',
  },
}));

const ForgotPasswordForm = (props) => {
  const { t } = useTranslation('forgetPasswordPage');
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const selectedLang = localStorage.getItem('immunie-app-selectedLang');

  // Fields
  const [email, setEmail] = useState('');

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/client-app/forgot-password?lang=${selectedLang ? selectedLang.toUpperCase() : 'PT'}`,
        {
          email: email,
        }
      );

      console.log(selectedLang);
      setLoading(false);
      setSubmitted(true);
    } catch (error) {
      setLoading(false);
      console.log('[RegistrationForm] Error:', error);
    }
  };

  return (
    <div>
      <FormContainer>
        <Title>
          {t('RECOVERY_PASSWORD')}
        </Title>
        {submitted ? (
          <Text>{t('SUBMIT_SUCCESS_RESPONSE')}</Text>
        ) : (
          <FieldContainer marginBottom="20px">
            <AccountCircle fontSize="large" htmlColor="#5D5D5D" />
            <TextFieldContainer>
              <FormControl className={classes.input}>
                <TextField
                  label={t('EMAIL')}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  disabled={loading}
                />
              </FormControl>
            </TextFieldContainer>
          </FieldContainer>
        )}
        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <ButtonsContainer>
            {!submitted && (
              <MainButton onClick={onSubmit}>
                {t('SUBMIT')}
              </MainButton>
            )}
          </ButtonsContainer>
        )}
      </FormContainer>
    </div>
  );
};

export default ForgotPasswordForm;
