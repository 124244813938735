import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { setUser } from "../../utils/user";
import { useTranslation } from 'react-i18next';
import {
  FormContainer,
  Title,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  CenterColumn
} from "./Components";
import {
  InputLabel,
  IconButton,
  InputAdornment,
  Input,
  TextField,
  FormControl,
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import Dialog from "../Dialog/Dialog";
import {
  AlternateEmail,
} from "@material-ui/icons/";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/Lock";
import { validateEmail } from "../../utils/validator";

const useStyles = makeStyles((theme) => ({
  input: {
    width: '95%',
  },
}));

export const ResetLoginDataForm = ({
  setAuth,
  temporaryJwt,
}) => {
  const { t } = useTranslation('resetLoginDataPage');
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [logged, setLogged] = useState(false);

  // Fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (event) => {
    try {
      if (!email || !password || !confirmPassword) {
        setFeedback(t('VALIDATION_COMPLETE_ALL_FIELDS_ERROR'));
        setDialogOpen(true);
        return;
      }

      if (!validateEmail(email)) {
        setFeedback(t('VALIDATION_EMAIL_ERROR'));
        setDialogOpen(true);
        return;
      }

      if (password !== confirmPassword) {
        setFeedback(t('VALIDATION_PASSWORD_MUST_BE_EQUAL_ERROR'));
        setDialogOpen(true);
        return;
      }

      if (!temporaryJwt) {
        throw new Error('Missing token');
      };

      event.preventDefault();
      setLoading(true);

      const body = {
        email,
        password,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${temporaryJwt}`,
        }
      }

      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}/client-app/enteprise-email`,
        body,
        config
      );

      localStorage.setItem('__session', result.data.jwt);
      setUser(result.data.user);
      setAuth(true);
      setLogged(true);
    } catch (error) {
      if (error.message === 'Missing token') {
        setFeedback(t('MISSING_TOKEN_ERROR'))
        setDialogOpen(true);

        console.error(`[ResetLoginDataForm handleSubmit] ${error}`);
        return;
      };

      setFeedback(t('SUBMIT_ERROR'));
      setDialogOpen(true);

      console.error(`[ResetLoginDataForm handleSubmit] ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {logged && <Redirect to={'/secure'} />}
      <FormContainer>
        <Title>
          {t('RESET_LOGIN_DATA')}
        </Title>

        <FieldContainer>
          <AlternateEmail fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <TextField
                label={t('NEW_EMAIL')}
                value={email}
                onChange={(event) =>
                  setEmail(event.target.value?.toLocaleLowerCase())
                }
                disabled={loading}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        <FieldContainer>
          <LockIcon fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-password">
                {t('NEW_PASSWORD')}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                autocomplete="off"
                label={t('NEW_PASSWORD')}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: "off",
                  "aria-autocomplete": "off",
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        <FieldContainer marginBottom="20px">
          <LockIcon fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-password-c">
                {t('NEW_PASSWORD_CONFIRMATION')}
              </InputLabel>
              <Input
                id="standard-adornment-password-c"
                autocomplete="off"
                label={t('NEW_PASSWORD_CONFIRMATION')}
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: "off",
                  "aria-autocomplete": "off",
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>

        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <ButtonsContainer>
            <MainButton onClick={onSubmit}>
              <Typography style={{ fontWeight: "bold" }}>
                {t('SUBMIT')}
              </Typography>
            </MainButton>
          </ButtonsContainer>
        )}
      </FormContainer>

      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>
    </div>
  );
};