import styled from 'styled-components';

const Container = styled.div`
  width: calc(100% - 40px);
  box-sizing: border-box;
`

const Content = styled.div`
  box-sizing: border-box;
  padding: 0px 32px;
`

const Text = styled.p`
  min-height: 39px;
  font-size: 12px;
  font-weight: bold;
  line-height: 13px;
  color: #808080;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
`

const Button = styled.button`
  background-color: ${props => props.mainColor};
  display: block;
  width: 130px;
  max-width: 150px;
  height: 30px;
  font-size: 10px;
  color: #FFFFFF;
  text-align: center;
  line-height: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border: none;
  border-radius: 30px;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  text-transform: uppercase;
  cursor: pointer;
`

const Img = styled.div`
  width: 210px;
  height: 170px;
  margin: auto;
  margin-bottom: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${props => props.src}');
`

export {
  Container,
  Content,
  Text,
  Button,
  Img
}