import React, { useState } from "react";
import {
  Container,
  Label,
  Title,
  Text,
  ProtocolContainer,
  ProtocolText,
} from "./Components.js";
import {
  Radio,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  radioInput: {
    width: 15,
    height: 15,
    color: '#bebebe',
    '&.Mui-checked': {
      color: '#00A859',
    },
  },

  passeVerdeImg: {
    width: 30,
    height: 30,
    paddingRight: 9,
    paddingLeft: 5,
  },

  accordion: {
    width: '100%',
    boxShadow: 'none',

    '& .MuiAccordionSummary-root': {
      padding: '0 8px'
    },

    '& .MuiIconButton-root': {
      padding: 6,
    },

    '& .MuiIconButton-edgeEnd': {
      marginRight: -5
    },

    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '0',
    },

    '& .MuiCollapse-container': {
      width: '75%',
      marginLeft: '80px',
    }
  },

  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: 0,
    },
  },

  accordionDetails: {
    display: 'flex',
    padding: 0,
  },

  protocolImg: {
    width: 10,
    height: 10,
    marginRight: 5,
  }
}));

const PasseVerdeItem = ({
  index,
  eventName,
  eventDate,
  eventPlace,
  url,
  availableProtocols,
  value,
  checked,
  onChange,
  size,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('passeVerdePages');
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Accordion
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}
          className={classes.accordionSummary}
        >
          <Radio
            className={classes.radioInput}
            size={size}
            id={value}
            checked={checked}
            value={value}
            onChange={onChange}
          />
          <img
            src={url ?? "/assets/circle-passeverde-green.svg"}
            alt="Passe Verde Icon"
            className={classes.passeVerdeImg}
          />
          <Title>{eventName}</Title>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Label htmlFor={value}>
            {eventDate === 'travel' ?
              <Text>{t('NEW_PASSE_VERDE_SCHEDULED_DATE')}</Text>
              :
              <Text>{t('NEW_PASSE_VERDE_DATE')}: {eventDate}</Text>
            }
            {eventPlace && <Text>{t('NEW_PASSE_VERDE_PLACE')}: {eventPlace}</Text>}
            {
              availableProtocols &&
              availableProtocols.length > 0 &&
              availableProtocols.map((protocol, index) => {
                if (protocol === 'Imunização Completa Covid-19') {
                  return (
                    <ProtocolContainer key={`${protocol + index}`}>
                      <img
                        className={classes.protocolImg}
                        src="/assets/check-pink.svg"
                        alt="Circle Pink Check" />
                      <ProtocolText>{protocol}</ProtocolText>
                    </ProtocolContainer>
                  )
                }
                return (
                  <ProtocolContainer key={`${protocol + index}`}>
                    <img
                      className={classes.protocolImg}
                      src="/assets/check-blue.svg"
                      alt="Circle Blue Check" />
                    <ProtocolText>{protocol}</ProtocolText>
                  </ProtocolContainer>
                )
              })
            }
          </Label>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
};

export default PasseVerdeItem;