import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  InputBase,
  IconButton
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ArrowBackIos as ArrowBackIcon
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 40px)',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '23px',
    padding: '5px 20px 5px 11px',
  },

  containerWithoutStartIcon: {
    width: 'calc(100% - 40px)',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '23px',
    padding: '5px 20px 5px 30px',
  }
}));

const SearchInput = ({ startIcon, endIcon, onChange, onClick, value, placeholder }) => {
  const classes = useStyles();

  return (
    <Paper
      component="form"
      className={startIcon ? classes.container : classes.containerWithoutStartIcon}
    >
      <InputBase
        fullWidth
        placeholder={placeholder}
        aria-label="search field"
        onChange={onChange}
        onClick={onClick}
        disabled={onClick ? true : false}
        value={value}
        startAdornment={startIcon &&
          <IconButton
            style={{ marginLeft: -5, padding: 4 }}
          >
            <ArrowBackIcon style={{ color: '#808080', width: 16, height: 16 }} />
          </IconButton>
        }
        endAdornment={
          endIcon &&
          <SearchIcon style={{ color: '#808080' }} />
        }
      />
    </Paper >
  );
}

export default SearchInput;