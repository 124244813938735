import React, { useEffect, useState } from 'react';
import { request } from '../../utils/request';
import { getUser } from '../../utils/user';
import {
  Container,
  Title,
  CenterColumn,
  LogoFrame,
} from './Components';
import Header from '../../components/Header/Header';
import { Modal } from '../../components/Modal/Modal';
import SearchInput from '../../components/SearchInput/SearchInput';
import VaccineTable from '../../components/VaccineTable/VaccineTable';
import { VaccineCodeAccordion } from '../../components/VaccineCodeAccordion';
import moment from 'moment-timezone';
import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  imgLinkIcon: {
    position: 'absolute',
    right: '5px',
    bottom: '0px',
    color: '#7dcef3',
  },

  textUpdatedAt: {
    fontSize: 12,
    color: '#808080',
    marginTop: 10,
  },

  textDate: {
    fontSize: 10,
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 20,
  },
}));

const MyCard = (props) => {
  const { t } = useTranslation('vaccinesPage');
  const classes = useStyles();

  const [userVaccines, setUserVaccines] = useState(null);
  const [userAntibodies, setUserAntibodies] = useState(null);
  const [optionalVaccines, setOptionalVaccines] = useState(null);
  const [lastVaccineDate, setLastVaccineDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState(null);
  const [clinicUrl, setClinicUrl] = useState(null);
  const [birthday, setBirthday] = useState('');
  const [search, setSearch] = useState('');
  const [isOpenNoVacAdministratedModal, setIsOpenNoVacAdministratedModal] = useState(false);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCloseModal = () => {
    setIsOpenNoVacAdministratedModal(false);
  };

  const handleClickConfirmModal = () => {
    setIsOpenNoVacAdministratedModal(false);
    window.location.replace("/secure/my-health/my-vaccinations-card/upload-vaccinations-card");
  };

  useEffect(() => {
    const getUserVaccines = async () => {
      try {
        const user = getUser();
        const { data: result } = await request.get(
          `/client-app/find-user-vaccines?id=${user.id}`
        );

        const officials = (result.vaccines || []).filter(
          (vaccine) => vaccine.optional === false
        );
        const optionals = (result.vaccines || []).filter(
          (vaccine) => vaccine.optional === true
        );

        const hasSomeAdministratedVaccine = result.vaccines
          .find(vaccine => vaccine.administrated === true);

        setUserVaccines(officials);
        setOptionalVaccines(optionals || []);
        setUserAntibodies(result.antibodies);
        const logoUrl =
          (result.userClinic &&
            result.userClinic.logo &&
            result.userClinic.logo.url) ||
          null;
        const clinicUrl = (result.userClinic && result.userClinic.url) || null;
        setLogoUrl(logoUrl);
        setClinicUrl(clinicUrl);
        setBirthday(result.birthday);

        if (!hasSomeAdministratedVaccine) {
          setIsOpenNoVacAdministratedModal(true);
        };
      } catch (error) {
        console.error(`[MyCard getUserVaccines] ${error}`);
      } finally {
        setLoading(false);
      }
    };
    getUserVaccines();
  }, []);

  useEffect(() => {
    const calculateLastUpdateDate = () => {
      if (!userVaccines) return;

      let dates = [];

      userVaccines.forEach((vaccine) => {
        if (vaccine.administrated) {
          dates.push(new Date(vaccine.adminDetails.date));
        }
      });

      const lastDate = new Date(Math.max.apply(null, dates));

      const dateFormated =
        (moment(lastDate).isValid() &&
          moment(lastDate).format('YYYY-MM-DD HH:mm:ss')) ||
        '';

      setLastVaccineDate(dateFormated);
    };
    calculateLastUpdateDate();
  }, [userVaccines]);

  return (
    <Container>
      <Header backgroundStyle="ORANGE" />
      <Title>
        {t('TITLE')}
      </Title>
      {loading ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        <>
          {logoUrl &&
            <LogoFrame backgroundImage={logoUrl}>
              <IconButton
                href={clinicUrl}
                className={classes.imgLinkIcon}
              >
                <LinkIcon />
              </IconButton>
            </LogoFrame>
          }
          {lastVaccineDate &&
            <>
              <Typography className={classes.textUpdatedAt}>
                {t('VACCINATION_CARD_UPDATED_ON')}
              </Typography>
              <Typography className={classes.textDate}>
                {lastVaccineDate}
              </Typography>
            </>
          }

          <Box mb="10px" width="100%" display="flex" justifyContent="center">
            <SearchInput
              placeholder={t('SEARCH_PLACEHOLDER')}
              onChange={handleChange}
              value={search}
              endIcon
            />
          </Box>

          <VaccineCodeAccordion t={t} />

          <VaccineTable
            search={search}
            vaccines={userVaccines}
            birthday={birthday}
            type='vaccine'
          />
          {((userAntibodies || []).length > 0 ||
            (optionalVaccines || []).length > 0) && (
              <VaccineTable
                search={search}
                vaccines={[...optionalVaccines, ...userAntibodies]}
                birthday={birthday}
                type='antibody'
              />
            )}
        </>
      )}

      <Modal
        id="no-administrated-vaccines"
        open={isOpenNoVacAdministratedModal}
        title={t('MODAL_TITLE')}
        description={t('MODAL_DESCRIPTION')}
        leftButtonTitle={t('MODAL_BACK_BUTTON')}
        rightButtonTitle={t('MODAL_CONFIRM_BUTTON')}
        onClickLeftButton={handleCloseModal}
        onClickRightButton={handleClickConfirmModal}
      />
    </Container>
  );
};

export default MyCard;
