import React from 'react';
import {
  makeStyles,
  Backdrop,
  Fade,
  Modal as MUIModal,
  Typography,
  Box
} from "@material-ui/core";
import { SpecialCase } from './SpecialCase';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxWidth: '420px',
    margin: '0 auto',
    boxSizing: 'border-box',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  title: {
    textAlign: "center",
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
    color: '#00A859',
  },

  description: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },
}));

export const SpecialCaseModal = ({ open, onClose, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('vaccinationsCardPage');

  return (
    <MUIModal
      aria-labelledby={`special-case-modal`}
      className={classes.modal}
      open={open}
      closeAfterTransition
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper} id="special-case-modal">
          <Typography
            className={classes.title}
            variant="h5"
          >
            {t('SPECIAL_CASE_MODAL_TITLE')}
          </Typography>
          <Typography className={classes.description}>
            {t('SPECIAL_CASE_MODAL_DESCRIPTION')}
          </Typography>

          <SpecialCase
            title={t('SPECIAL_CASE_MODAL_ANTIBODY_TITLE')}
            description={t('SPECIAL_CASE_MODAL_ANTIBODY_DESCRIPTION')}
            buttonTitle={t('SPECIAL_CASE_MODAL_ANTIBODY_BUTTON')}
            onClick={() => onClick('ANTIBODY')}
          />

          <SpecialCase
            mt={4}
            title={t('SPECIAL_CASE_MODAL_MEDICAL_DECLARATION_TITLE')}
            description={t('SPECIAL_CASE_MODAL_MEDICAL_DECLARATION_DESCRIPTION')}
            buttonTitle={t('SPECIAL_CASE_MODAL_MEDICAL_DECLARATION_BUTTON')}
            onClick={() => onClick('MEDICAL_DECLARATION')}
          />
        </Box>
      </Fade>
    </MUIModal>
  )
};