import React, {
  createContext,
  useState,
  useEffect,
} from 'react';
import { getUser } from '../utils/user';
import { request } from '../utils/request';

export const PasseVerdeContext = createContext();

export const PasseVerdeProvider = ({ children }) => {
  const [passeVerde, setPasseVerde] = useState(null);
  const [loadingPasse, setLoadingPasse] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);

  const user = getUser();

  useEffect(() => {
    const fetchPasseVerde = async () => {
      try {
        setLoadingPasse(true);
        const { data: result } = await request.get(
          `/client-app/passe-verde-certificate?id=${user.id}`
        );
        setPasseVerde(result);
      } catch (error) {
        setIsNewUser(true);
      } finally {
        setLoadingPasse(false);
      }
    };

    fetchPasseVerde();
    //eslint-disable-next-line
  }, []);

  return (
    <PasseVerdeContext.Provider
      value={{
        loadingPasse,
        passeVerde,
        isNewUser,
      }}
    >
      {children}
    </PasseVerdeContext.Provider>
  );
}
