import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
 background-color: #FFFFFF;
 width: calc(100% - 40px);
 height: auto;
 padding: 10px;
 margin: 20px 0px;
 border-radius: 8px;
 box-sizing: border-box;
 box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #00A859;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
`;

const Text = styled.p`
  color: #505050;
  font-weight: bold;
`;

const Button = styled.button`
  width: 140px;
  height: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #00A859;
  letter-spacing: -0.5px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: .2s;
  cursor: pointer;
`;

export {
  Container,
  Content,
  CenterColumn,
  Title,
  Text,
  Button,
};