import React, { useState, useEffect } from "react";
import { request } from "../../utils/request";
import { getUser } from "../../utils/user";
import { useHistory } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import {
  Button,
  Typography,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  Container,
  Image,
  Title,
} from "./Components.js";
import Header from "../../components/Header/Header";
import StripeInput from "../../components/StripeInput/StripeInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from 'react-i18next';


const CheckoutForm = ({ paymentIntent, plan }) => {

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation('checkoutPage');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { error, paymentIntent: payResponse } = await stripe.confirmCardPayment(paymentIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          }
        }
      );

      if (error) throw new Error(error.message);

      if (payResponse.status === "succeeded") {
        const { data: result } = await request.put(`/client-app/update-plan`, { paymentIntentID: payResponse.id });

        if (result.error) {
          alert(`${t('CHECKOUT_AREA_ALERT_PROCESSING_ERROR')} (code: IMN-CLI-5531)`);
        }

        alert(t('CHECKOUT_AREA_ALERT_SUCCEEDED_PAYMENT'));

        history.go(0);
      }

    } catch (err) {
      alert(err.message);
    }

    setLoading(false);
  }

  return <form onSubmit={handleSubmit} style={{
    padding: "1rem",
    margin: "1rem"
  }}>
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={12} style={{ marginBottom: "1.6rem" }}>
        <Divider variant="middle" style={{ height: 2 }} />
        <Typography align="center" variant="h5">
          {`${plan.displayName} - ${plan.currency} ${plan.price?.toFixed(2)}`}
        </Typography>
        <Divider variant="middle" style={{ height: 2 }} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t('CHECKOUT_AREA_FORMS_CREDIT_CARD_LABEL')}
          name="ccnumber"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('CHECKOUT_AREA_FORMS_CREDIT_EXPIRATION_DATE')}
          name="CardExpiryElement"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('CHECKOUT_AREA_FORMS_CREDIT_CARD_CVC')}
          name="CardCvcElement"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement
            },
          }}
        />
      </Grid>
      <Grid item xs={12} >
        <Grid container justify="flex-end">
          {loading ?
            <CircularProgress />
            :
            <Button
              startIcon={<VerifiedUserIcon />}
              variant="contained"
              color="primary"
              type="submit"
              disabled={!stripe}
              style={{ marginLeft: 'auto' }}
            >
              {t('CHECKOUT_AREA_CONFIRM_BUTTON_LABEL')}
            </Button>
          }
        </Grid>
      </Grid>
    </Grid>
  </form>
}

const Checkout = (props) => {

  // Hooks
  const { t } = useTranslation('checkoutPage');
  const history = useHistory();

  const { paymentInt: paymentIntent, plan } = props.history.location.state?.plan;


  useEffect(() => {

    // If user already have a plan back to HOME PAGE
    const user = getUser();
    const userPlan = user?.plan;
    if (userPlan) {
      history.push("/secure")
    }
  }, [history]);

  return (
    <Container>
      <Header />
      <Image />
      <Title >
        {t('CHECKOUT_TITLE')}
      </Title>
      <CheckoutForm paymentIntent={paymentIntent} plan={plan} />
      <Grid container xs={12} spacing={3} justify="center" style={{ marginTop: 10, marginBottom: 20 }}>
        <Grid item xs={6}>
          <img src="/assets/ssl_badge.svg" alt="ssl secure" style={{ width: "90%", marginLeft: 32 }} />
        </Grid>
        <Grid item xs={6}>
          <img src="/assets/gdpr_badge.svg" alt="gdpr" style={{ width: "90%" }} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Checkout;
