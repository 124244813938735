import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    fontWeight: 'bold',
    color: '#2D80C6',
  },

  description: {
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'justify'
  },

  button: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    backgroundColor: '#2D80C6',
    border: 'none',
    borderRadius: 23,
    cursor: 'pointer',
    alignSelf: 'center',
    padding: '6px 16px',

    '&:hover': {
      backgroundColor: '#49aad6',
    },

    '&:disabled': {
      backgroundColor: '#CCCCCC',
      cursor: 'not allowed',
    },
  },
}));

export const SpecialCase = ({
  title,
  description,
  buttonTitle,
  onClick,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} {...rest}>
      <Typography variant="h6" align="center" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>
        {description}
      </Typography>

      <Button
        className={classes.button}
        onClick={onClick}
      >
        {buttonTitle}
      </Button>
    </Box>
  )
};