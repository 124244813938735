import React, {
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { useTranslation } from 'react-i18next';
import {
  Container,
  Content,
  CenterColumn,
  Title,
  Text,
} from "./Components.js";
import Header from "../../components/Header/Header";
import SearchInput from "../../components/SearchInput/SearchInput";
import SickNote from "../../components/SickNote/SickNote";
import SickNoteAccordion from "../../components/SickNoteAccordion/SickNoteAccordion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { request } from '../../utils/request';

const ConsultMedicalCertificate = () => {
  const { t } = useTranslation('medicalCertificatePage');

  const [userSickNotes, setUserSickNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const userAvailableSickNotes = useCallback(() => {
    const sickNotesList = [];

    if (!userSickNotes.length > 0) {
      return sickNotesList;
    };

    userSickNotes.forEach((sickNote, index) => {
      const status = sickNote.status;

      if (sickNote.status === 'REDO' || sickNote.status === 'INCONSISTENT') {
        sickNotesList.push(
          <SickNoteAccordion
            key={`${index} ${sickNote.doctorName}`}
            doctorName={sickNote.doctorName}
            enterpriseNameLabel={t('CONSULT_MEDICAL_CERTIFICATE_ENTERPRISE')}
            enterpriseName={sickNote.enterpriseName}
            daysOff={sickNote.daysOff}
            verifiedDaysOff={sickNote.verifiedDaysOff}
            certificateDate={sickNote.date}
            certificateDateLabel={t('CONSULT_MEDICAL_CERTIFICATE_DATE')}
            status={status}
            statusText={t(`CONSULT_MEDICAL_CERTIFICATE_STATUS_${status}`)}
            analystObservationLabel={t('CONSULT_MEDICAL_CERTIFICATE_ANALYST_MESSAGE')}
            analystObservation={sickNote.observation}
            url={sickNote.file}
          />
        )
      } else {
        sickNotesList.push(
          <SickNote
            key={`${index} ${sickNote.doctorName}`}
            doctorName={sickNote.doctorName}
            enterpriseNameLabel={t('CONSULT_MEDICAL_CERTIFICATE_ENTERPRISE')}
            enterpriseName={sickNote.enterpriseName}
            certificateDate={sickNote.date}
            certificateDateLabel={t('CONSULT_MEDICAL_CERTIFICATE_DATE')}
            status={status}
            daysOff={sickNote.daysOff}
            verifiedDaysOff={sickNote.verifiedDaysOff}
            statusText={t(`CONSULT_MEDICAL_CERTIFICATE_STATUS_${status}`)}
            url={sickNote.file}
          />
        )
      }
    });

    return sickNotesList;
  }, [t, userSickNotes])

  const sickNotesToRender = userAvailableSickNotes();
  const sickNotesFiltered = useMemo(() => {
    if (sickNotesToRender.length > 0) {
      const lowerSearch = search.toLowerCase();
      return sickNotesToRender
        .filter((document) => document.key.toLowerCase().includes(lowerSearch))
    }
  }, [sickNotesToRender, search]);

  useEffect(() => {
    const getUserSickNotes = async () => {
      try {
        const { data } = await request.get(
          `/client-app/find-user-sick-notes`
        );
        const sortedData = data.sort((a,b) => new Date(b.createdAt) > new Date(a.createdAt))
        setUserSickNotes(sortedData);
      } catch (err) {
        console.log(`[ConsultMedicalCertificate getUserSickNotes] ${err}`);
      } finally {
        setIsLoading(false);
      }
    }
    getUserSickNotes();
  }, []);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>
        {t('CONSULT_MEDICAL_CERTIFICATE')}
      </Title>
      <SearchInput
        placeholder={t('CONSULT_MEDICAL_CERTIFICATE_SEARCH')}
        onChange={handleChange}
        value={search}
        endIcon
      />
      {isLoading ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        sickNotesFiltered &&
          sickNotesFiltered.length > 0 ?
          <Content>
            {sickNotesFiltered}
          </Content>
          :
          <CenterColumn>
            <Text>
              {t('CONSULT_MEDICAL_CERTIFICATE_CERTIFICATE_NOT_FOUND')}
            </Text>
          </CenterColumn>
      )}
    </Container>
  );
};

export default ConsultMedicalCertificate;
