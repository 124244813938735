import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import SelectRegion from "../../components/SelectRegion/SelectRegion";
import GenerateRegionContent from "../../components/GenerateRegionContent/GenerateRegionContent";
import {
  Container,
  Title,
  Body,
} from "./Components.js";
import {
  Box,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  availableRegions,
  privacyPolicyRegion1,
  privacyPolicyRegion2,
} from './data';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: 35,
    flexDirection: 'column',
  },

  province: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    marginBottom: 30,
    width: '100%',
  },

  language: {
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    margin: theme.spacing(2, 0),
  },

  regionDescription: {
    textAlign: 'justify',
    fontSize: '14px',
  },

  updatedAt: {
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

const PrivacyPolicy = (props) => {
  const { t } = useTranslation('privacyPolicyPage');
  const classes = useStyles();


  const [province, setProvince] = useState(availableRegions[0].value);
  const [contentToShow, setContentToShow] = useState(privacyPolicyRegion1);

  const handleChange = (event) => {
    setProvince(event.target.value);
  }

  useEffect(() => {
    if (province === 'region1') {
      setContentToShow(privacyPolicyRegion1)
    } else {
      setContentToShow(privacyPolicyRegion2)
    }
  }, [province]);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t('PRIVACY_POLICY_TITLE')}</Title>
      <Body>
        <Box className={classes.container}>
          <Box className={classes.province}>
            <Typography component="span">
              {t('PRIVACY_POLICY_PAGE_SELECT')}
            </Typography>
            <SelectRegion
              options={availableRegions}
              onChange={handleChange}
              value={province}
              translations={t}
            />
          </Box>
          <Box className={classes.language}>
            <Typography component="span">
              {t('PRIVACY_POLICY_LANGUAGE')}
            </Typography>
            <Typography component="span">
              {
                province === 'region1' ?
                  t('PRIVACY_POLICY_PAGE_REGION_1_LANGUAGE')
                  :
                  t('PRIVACY_POLICY_PAGE_REGION_2_LANGUAGE')
              }
            </Typography>
          </Box>
        </Box>

        <Typography
          component="p"
          color="textPrimary"
          className={classes.regionDescription}
        >
          {
            province === 'region1' ?
              t('PRIVACY_POLICY_PAGE_REGION_1_TXT')
              :
              t('PRIVACY_POLICY_PAGE_REGION_2_TXT')
          }
        </Typography>

        <Typography
          component="p"
          color="textPrimary"
          style={{ marginTop: 5 }}
          className={classes.regionDescription}
        >
          {t('PRIVACY_POLICY_PAGE_TXT_REG01_1_1')}
        </Typography>

        <Typography
          component="p"
          color="textSecondary"
          className={classes.updatedAt}
        >
          {
            province === 'region1' ?
              t('PRIVACY_POLICY_PAGE_REGION_1_UPDATED')
              :
              t('PRIVACY_POLICY_PAGE_REGION_2_UPDATED')
          }
        </Typography>

        <Divider className={classes.divider} />

        <GenerateRegionContent
          translations={t}
          content={contentToShow}
        />
      </Body>
    </Container>
  );
}

export default PrivacyPolicy;
