import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import pt from "date-fns/locale/pt";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '-10px',
    marginBottom: 15,
  },

  datePicker: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(23px, 14px) scale(1)',
      color: '#000000',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -11px) scale(.75)',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#f44336',
    },
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiSvgIcon-root': {
      color: '#000000',
    },
  },

  input: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    height: 32,
    padding: '20px',

    '& fieldset': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },

  dialog: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#00A859',
    },

    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#00A859',
    }
  },
}));

const DateInput = ({
  onChange,
  value,
  label,
  isSubmitting,
  width,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ width: width ? width : '100%' }}>
      <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          className={classes.datePicker}
          fullWidth
          disableFuture
          disabled={isSubmitting}
          id="date-picker-dialog"
          label={label}
          format="dd/MM/yyyy"
          value={value}
          inputVariant="outlined"
          onChange={onChange}
          helperText="DD/MM/YYYY"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputProps={{
            className: classes.input
          }}
          DialogProps={{
            className: classes.dialog
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default DateInput;