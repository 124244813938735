import React from 'react';
import {
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CodeContainer from '../CodeContainer/CodeContainer';

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 40px)',
    borderRadius: 8,
    marginTop: 10,
  },

  accordion: {
    border: 'none',
    padding: '2px 8px',
    boxShadow: 'none',
    '&::before': {
      backgroundColor: 'transparent',
    },

    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '.9rem',
    color: '#FE9201',
  },

  details: {
    padding: '0px 32px',
  },

  detailsWithCode: {
    padding: '0px',
  },

  summary: {
    margin: '0px',
  },
}));

export const VaccineCodeAccordion = ({ t }) => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.summary }}
        >
          <Typography className={classes.title}>
            {t('IMMUNIZATION_TEXT')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsWithCode}>
          <Box style={{ marginTop: '-10px', display: 'flex', alignText: 'center', justifyContent: 'center' }}>
            <CodeContainer
              documentMetadata={{ codeType: 'hcp' }}
              explanatoryTextOffCode={t('EXPLANATORY_OFF_CODE_TEXT')}
              explanatoryTextOnCode={t('EXPLANATORY_ON_CODE_TEXT')}
              generateCodeText={t('GENERATE_CODE')}
              terminateCodeText={t('TERMINATE_CODE')}
              marginTop={0}
              btnColor="#FF0066"
              onCodeTextColor="#808080"
              translations={t}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};