const DOCUMENT_TYPE_OPTIONS = [
  {
    name: 'DOCUMENT_TYPE_CPF',
    value: 'cpf',
  },
  {
    name: 'DOCUMENT_TYPE_PASSPORT',
    value: 'passport',
  },
  {
    name: 'DOCUMENT_TYPE_RG',
    value: 'rg',
  },
  {
    name: 'DOCUMENT_TYPE_CNH',
    value: 'cnh',
  },
  {
    name: 'DOCUMENT_TYPE_PROFESSIONAL_IDENTITY',
    value: 'professional-identity',
  },
  {
    name: 'DOCUMENT_TYPE_OTHERS',
    value: 'others',
  },
]

const GENDER_OPTIONS = [
  {
    label: 'GENDER_MALE_OPTION',
    value: 'male',
  },
  {
    label: 'GENDER_FEMALE_OPTION',
    value: 'female',
  },
  {
    label: 'GENDER_PREFER_NOT_TO_INFORM_OPTION',
    value: 'prefer-not-to-inform',
  }
];

export {
  DOCUMENT_TYPE_OPTIONS,
  GENDER_OPTIONS,
}