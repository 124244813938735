import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F9FE;
  min-height: 100vh;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
  color: #2D80C6;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const Body = styled.div`
  width: calc(100% - 40px);
  margin: 20px auto;
  border-radius: 10px;
  box-sizing: border-box;
`;

const FaqContainer = styled.div`
  width: 100%;
`;

const FaqTitle = styled.h4`
  color: #2D80C6;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 20px;
`;

const FaqContent = styled.p`
  color: #808080;
  font-size: 14px;
  font-weight: normal;
  text-align: justify;
  margin-top: 15px;
  margin-bottom: 20px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RateContainer = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const Rate = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export {
  Container,
  Title,
  Body,
  FaqContainer,
  FaqTitle,
  FaqContent,
  RateContainer,
  Rate,
  ButtonContainer
};