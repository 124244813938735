import React, {
  useContext,
  useState,
} from "react";
import { Link } from "react-router-dom";
import {
  Container,
  LoadingContainer,
  CenterColumn,
  Content,
  ButtonContainer,
  Button,
  RedoButton,
  AvatarBorder,
  AddToWalletButton,
  LoadingIndicatorContainer,
} from "./Components.js";
import {
  Avatar,
  Divider,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Link as ExternLink
} from "@material-ui/core";
import {
  HourglassEmpty as HourglassEmptyIcon,
  Replay as ReplayIcon,
  Warning as WarningIcon,
  QuestionAnswer as QuestionAnswerIcon,
  InsertDriveFile as FileIcon
} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/Header/Header";
import { getUser } from "../../utils/user";
import moment from "moment";
import { PasseVerdeContext } from "../../context/passeverde";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { request } from "../../utils/request";

const useStyles = makeStyles(() => ({
  newUserContainer: {
    width: '80%',
    margin: '40px auto',
  },

  newUserText: {
    color: '#808080',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  userProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },

  avatarContainer: {
    width: 96,
    marginRight: 15,
  },

  avatar: {
    width: 80,
    height: 80,
  },

  firstContentContainer: {
    width: 'calc(100% - 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  userNameContainer: {
    width: '100%',
  },

  userName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#808080',
    textAlign: 'left',
  },

  userInfoContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  userInfo: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#808080',
  },

  userInfoSeparator: {
    display: 'inline-block',
    width: 4,
    height: 4,
    backgroundColor: '#808080',
    borderRadius: '50%',
    margin: '1.7px 5px',
  },

  divider: {
    width: 'calc(100% - 40px)',
    height: 2,
    marginTop: 10
  },

  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },

  statusText: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  userInfoSeparatorStatus: {
    display: 'inline-block',
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginTop: 6,
    marginLeft: 7,
    marginRight: 7,
  },

  statusUpdated: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#5D5D5D',
  },

  validateText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#5D5D5D',
  },

  badgeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },

  badge: {
    width: 65,
    height: 65,
  },

  badgeName: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#5D5D5D',
  },

  vaccineInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 30,
  },

  vaccineType: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#5D5D5D',
  },

  vaccineText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#5D5D5D',
    wordBreak: 'break-word',
  },

  observationContainer: {
    width: '90%',
    padding: 30,
    backgroundColor: '#eeeeee',
    boxSizing: 'border-box',
    marginTop: 20,
  },

  observationText: {
    color: '#808080',
    fontWeight: 'bold',
  },

  vaccineInformationContainer: {
    border: '1px solid #d0cccc',
    padding: '10px',
    margin: '10px 0px',
    display: 'flex',
  },

  examsTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#5D5D5D',
    wordBreak: 'break-word',
    marginTop: 10,
  },
}));

const PasseVerde = () => {
  const classes = useStyles();
  const user = getUser();
  const { t, i18n } = useTranslation('passeVerdePages');

  const { loadingPasse, passeVerde, isNewUser } = useContext(PasseVerdeContext);

  const [isLoadingGetGoogleWalletUrl, setIsLoadingGetGoogleWalletUrl] = useState(false);
  const [isLoadingGetAppleWalletPass, setIsLoadingGetAppleWalletPass] = useState(false);

  const redoRedirect = '/secure/passe-verde/new';
  const history = useHistory();

  const userFullName = user?.surname ? `${user.name} ${user.surname}` : user?.name;

  const redirect = (to) => {
    history.push(to);
  };

  const userAgent = window.navigator.userAgent;
  const isIPad = !!userAgent.match(/iPad/i);
  const isIPhone = !!userAgent.match(/iPhone/i);
  const isMac = !!userAgent.match(/Mac/i);
  const isIOS = isIPad || isIPhone || isMac;

  const addToAppleWallet = async () => {
    try {
      setIsLoadingGetAppleWalletPass(true);
      const response = await request.get('/client-app/add-to-apple-wallet', {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });

      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const username = user.name.replace(' ', '_');
      const fileName = `${username}.pkpass`;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (err) {
      console.error(`[PasseVerde - AddToAppleWallet] ${err}`)
    } finally {
      setIsLoadingGetAppleWalletPass(false);
    }
  };

  const addToGoogleWallet = async () => {
    try {
      setIsLoadingGetGoogleWalletUrl(true);
      const { data: result } = await request.get('/client-app/add-to-google-wallet');
      window.open(result.saveToGoogleWalletUrl, '_blank');
    } catch (err) {
      console.error(`[PasseVerde - AddToGoogleWallet] ${err}`)
    } finally {
      setIsLoadingGetGoogleWalletUrl(false);
    }
  };

  const statusData = {
    PENDING: {
      color: '#F69103',
      subColor: '#2F6DE0',
      text: 'PASSE_VERDE_STATUS_PENDING',
      subText: 'PASSE_VERDE_STATUS_PENDING_SUB',
      icon: <HourglassEmptyIcon style={{ fontSize: 60, color: '#F69103', marginBottom: 20, marginTop: 10 }} />
    },
    REDO: {
      color: '#F69103',
      subColor: '#F69103',
      text: 'PASSE_VERDE_STATUS_REDO',
      subText: 'PASSE_VERDE_STATUS_REDO_SUB',
      icon: <ReplayIcon style={{ fontSize: 60, color: '#F69103', marginBottom: 20, marginTop: 10 }} />
    },
    INCONSISTENT: {
      color: '#F69103',
      subColor: '#F69103',
      text: 'PASSE_VERDE_STATUS_INCONSISTENT',
      subText: 'PASSE_VERDE_STATUS_INCONSISTENT_SUB',
      icon: <QuestionAnswerIcon style={{ fontSize: 60, color: '#F69103', marginBottom: 20, marginTop: 10 }} />
    },
    DENIED: {
      color: '#F60E31',
      subColor: '#F60E31',
      text: 'PASSE_VERDE_STATUS_DENIED',
      subText: 'PASSE_VERDE_STATUS_DENIED_SUB',
      icon: <WarningIcon style={{ fontSize: 60, color: '#F60E31', marginBottom: 20, marginTop: 10 }} />
    },
    EXPIRED: {
      color: "#F69103",
      subColor: "#F69103",
      text: "PASSE_VERDE_STATUS_EXPIRED",
      subText: "PASSE_VERDE_STATUS_EXPIRED_SUB",
      icon: <WarningIcon style={{ fontSize: 60, color: '#F69103', marginBottom: 20, marginTop: 10 }} />
    },
    ALERT: {
      color: '#F60E31',
      subColor: '#F60E31',
      text: 'PASSE_VERDE_STATUS_ALERT',
      subText: 'PASSE_VERDE_STATUS_ALERT_SUB',
      icon: <WarningIcon style={{ fontSize: 60, color: '#F60E31', marginBottom: 20, marginTop: 10 }} />
    },
    APPROVED: {
      color: '#00A859',
      text: 'PASSE_VERDE_STATUS_APPROVED',
    },
  };

  const resultToRender = () => {
    return (
      <Content>
        <div className={classes.firstContentContainer}>
          <div className={classes.userProfileContainer}>
            <div className={classes.avatarContainer}>
              <AvatarBorder>
                <Avatar
                  alt="Profile"
                  src={passeVerde?.userSelfie ?
                    passeVerde?.userSelfie.url :
                    '/assets/profile-default.svg'
                  }
                  className={classes.avatar}
                />
              </AvatarBorder>
            </div>
            <div>
              <div className={classes.userNameContainer}>
                <Typography className={classes.userName}>
                  {userFullName}
                </Typography>
              </div>
              <div className={classes.userInfoContainer}>
                <Typography className={classes.userInfo}>
                  {user.birthday}
                </Typography>
                <div className={classes.userInfoSeparator} />
                <Typography className={classes.userInfo}>
                  {t('PASSE_VERDE_DOCUMENT_ID')}: {user.documentNumber}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <Divider className={classes.divider} />

        <CenterColumn>
          {passeVerde?.status === "APPROVED" ?
            <div className={classes.statusContainer}>
              <Typography
                className={classes.statusText}
                component="span"
                style={{ color: statusData[passeVerde?.status]?.color || "black" }}
              >
                {t(statusData[passeVerde?.status]?.text)}
              </Typography>
              <div
                className={classes.userInfoSeparatorStatus}
                style={{ backgroundColor: statusData[passeVerde?.status]?.color || "black" }}
              />
              <ExternLink href={`https://checkpoint.immunie.net/validation/${passeVerde?.checkpointPinCode}`}>
                <Typography
                  className={classes.statusText}
                  component="span"
                  style={{ color: statusData[passeVerde?.status]?.color || "black" }}
                >
                  {passeVerde?.checkpointPinCode}
                </Typography>
              </ExternLink>
            </div>
            :
            passeVerde?.status === "EXPIRED" ?
              <div className={classes.statusContainer}>
                <Typography
                  className={classes.statusText}
                  component="span"
                  style={{ color: statusData[passeVerde?.status]?.color || "black" }}
                >
                  {t(statusData[passeVerde?.status]?.text)}
                </Typography>
                <div
                  className={classes.userInfoSeparatorStatus}
                  style={{ backgroundColor: statusData[passeVerde?.status]?.color || "black" }}
                />
                <Typography
                  className={classes.statusText}
                  component="span"
                  style={{ color: statusData[passeVerde?.status]?.color || "black" }}
                >
                  {passeVerde?.checkpointPinCode}
                </Typography>
              </div>
              :
              <div>
                <Typography
                  style={{ color: statusData[passeVerde?.status]?.color || "black" }}
                >
                  {t(statusData[passeVerde?.status]?.text)}
                </Typography>
              </div>
          }

          <Typography className={classes.statusUpdated}>
            {t('PASSE_VERDE_STATUS_AT')}: {moment(passeVerde?.updatedAt).format('DD/MM/YYYY, h:mm:ss A - UTC')}
          </Typography>

          {(passeVerde?.status === "APPROVED" || passeVerde?.status === "EXPIRED") &&
            <Typography
              className={classes.validateText}
            >
              {t('PASSE_VERDE_VALID')}: {
                passeVerde.validFrom ?
                  `${moment(passeVerde.validFrom).format('DD/MM/YYYY')} ${t('PASSE_VERDE_UNTIL')} ${moment(passeVerde.validUntil).format('DD/MM/YYYY')}`
                  : `${t('PASSE_VERDE_UNTIL')} ${moment(passeVerde.validUntil).format('DD/MM/YYYY')}`}
            </Typography>
          }

          {(passeVerde?.status === "APPROVED" || passeVerde?.status === "EXPIRED") &&
            passeVerde?.badges?.length > 0 &&
            <Grid container spacing={3} style={{ width: '90%' }}>
              {
                passeVerde?.badges?.map((badge, index) => {
                  return (
                    <Grid item xs={6} key={badge.name + index}>
                      <div className={classes.badgeContainer}>
                        <Avatar
                          alt="Profile"
                          src={badge.url}
                          className={classes.badge}
                        />
                        <Typography
                          className={classes.badgeName}
                          component="span"
                        >
                          {badge.name}
                        </Typography>
                      </div>
                    </Grid>
                  )
                })
              }
            </Grid>
          }
        </CenterColumn>

        <Divider className={classes.divider} />

        <CenterColumn style={{ marginTop: 10 }}>
          {
            passeVerde?.status === "APPROVED" &&
            <img crossOrigin="true" alt="qr code" src={passeVerde?.QRCode[0]?.url} />
          }

          {
            passeVerde?.status === "EXPIRED" &&
            <img crossOrigin="true" alt="qr code" src="/assets/expired_pv.png" width="80%" />
          }

          <div className={classes.vaccineInfoContainer}>
            {
              passeVerde?.status === "REDO" &&
              <Link to={redoRedirect}>
                {statusData[passeVerde?.status]?.icon}
              </Link>
            }

            {
              passeVerde?.status !== "APPROVED" && passeVerde?.status !== "REDO"
              && statusData[passeVerde?.status]?.icon
            }

            {
              (passeVerde?.status === "APPROVED" || passeVerde?.status === "EXPIRED") &&
              <div>
                {
                  passeVerde?.approvedMetadata?.protocolName !== undefined &&
                  <Typography className={classes.examsTitle}>
                    {t('PASSE_VERDE_VACCINES_AND_EXAMS')}
                  </Typography>
                }
                {
                  passeVerde?.approvedMetadata?.validations &&
                  //eslint-disable-next-line
                  passeVerde?.approvedMetadata?.validations.map(validation => {
                    if (validation.type === 'VACCINE') {
                      return (
                        <div
                          key={validation.registryId}
                          className={classes.vaccineInformationContainer}
                        >
                          <Link to="/secure/digital-certificates">
                            <IconButton>
                              <FileIcon style={{ color: "#2D80C6" }} />
                            </IconButton>
                          </Link>
                          <div>
                            <Typography className={classes.vaccineText}>
                              {t('PASSE_VERDE_VACCINE')}: {validation.name}
                            </Typography>
                            <Typography className={classes.vaccineText}>
                              {t('PASSE_VERDE_MANUFACTURER')}: {validation.producerName}
                            </Typography>
                            <Typography className={classes.vaccineText}>
                              {t('PASSE_VERDE_DATE')}: {moment(validation.datetime).format('DD/MM/YYYY, h:mm:ss A - UTC')}
                            </Typography>
                          </div>
                        </div>
                      )
                    }
                    if (validation.type === 'ANTIBODIES_AND_TESTS') {
                      return (
                        <div
                          key={validation.registryId}
                          className={classes.vaccineInformationContainer}
                        >
                          <Link to="/secure/digital-certificates">
                            <IconButton>
                              <FileIcon style={{ color: "#2D80C6" }} />
                            </IconButton>
                          </Link>
                          <div>
                            <Typography className={classes.vaccineText}>
                              {t('PASSE_VERDE_TEST_OR_EXAM')}: {validation.name}
                            </Typography>
                            <Typography className={classes.vaccineText}>
                              {t('PASSE_VERDE_CLINIC')}: {validation.clinicName}
                            </Typography>
                            <Typography className={classes.vaccineText}>
                              {t('PASSE_VERDE_DATE')}: {moment(validation.datetime).format('DD/MM/YYYY, h:mm:ss A - UTC')}
                            </Typography>
                          </div>
                        </div>
                      )
                    }
                  })
                }

                {
                  passeVerde?.status === "APPROVED" &&
                  <>
                    {
                      isIOS ?
                        <AddToWalletButton
                          title="Adicionar ao Apple Wallet"
                          type="button"
                          disabled={isLoadingGetGoogleWalletUrl || isLoadingGetAppleWalletPass}
                          onClick={addToAppleWallet}
                          className={classes.addToGoogleWalletButton}
                        >
                          {
                            !isLoadingGetAppleWalletPass ?
                              <img
                                src={i18n.language === 'pt' ? "/assets/apple-wallet-button-pt.svg" : "/assets/apple-wallet-button-en.svg"}
                                alt="Add to Apple Wallet"
                                width="180px"
                              />
                              :
                              <LoadingIndicatorContainer>
                                <CircularProgress size={22} />
                              </LoadingIndicatorContainer>
                          }
                        </AddToWalletButton>
                        :
                        <AddToWalletButton
                          title="Adicionar ao Google Pay"
                          type="button"
                          disabled={isLoadingGetGoogleWalletUrl || isLoadingGetAppleWalletPass}
                          onClick={addToGoogleWallet}
                          className={classes.addToGoogleWalletButton}
                        >
                          {
                            !isLoadingGetGoogleWalletUrl ?
                              <img
                                src={i18n.language === 'pt' ? "/assets/google-wallet-button-pt.svg" : "/assets/google-wallet-button-en.svg"}
                                height="48px"
                                alt="Add to Google Pay"
                              />
                              :
                              <LoadingIndicatorContainer>
                                <CircularProgress size={22} />
                              </LoadingIndicatorContainer>
                          }
                        </AddToWalletButton>
                    }
                  </>
                }
              </div>
            }

            {
              passeVerde?.status === "REDO" &&
              <>
                <Divider className={classes.divider} />
                <div style={{ width: "90%" }}>
                  <Typography
                    style={{
                      marginTop: 10,
                      color: statusData[passeVerde?.status]?.subColor,
                      fontWeight: 'bold'
                    }}
                  >
                    {t(statusData[passeVerde?.status]?.subText)}
                  </Typography>
                </div>
                <div className={classes.observationContainer}>
                  <Typography className={classes.observationText}>
                    {passeVerde?.observation}
                  </Typography>
                </div>
                <RedoButton onClick={redirect.bind(this, redoRedirect)}>
                  {t('PASSE_VERDE_STATUS_REDO')}
                </RedoButton>
              </>
            }

            {
              passeVerde?.status !== "APPROVED" &&
              passeVerde?.status !== "REDO" &&
              <>
                <Divider className={classes.divider} />
                <div style={{ width: "90%" }}>
                  <Typography
                    style={{
                      marginTop: 10,
                      color: statusData[passeVerde?.status]?.subColor,
                      fontWeight: 'bold'
                    }}
                  >
                    {t(statusData[passeVerde?.status]?.subText)}
                  </Typography>
                </div>
              </>
            }
          </div>
        </CenterColumn>
      </Content>
    )
  };

  if (isNewUser) {
    return (
      <Container>
        <Header backgroundStyle="GREEN" />
        <div className={classes.newUserContainer}>
          <Typography className={classes.newUserText}>
            {t('PASSE_VERDE_PAGE_NEW_USER')}
          </Typography>
          <ButtonContainer>
            {
              user?.plan ?
                <Button onClick={redirect.bind(this, "/secure/passe-verde/new")}>
                  {t('PASSE_VERDE_NEW_USER_CREATE_PASSE_VERDE')}
                </Button>
                :
                <Button onClick={redirect.bind(this, "/secure")}>
                  {t('PASSE_VERDE_NEW_USER_WITHOUT_PLAN')}
                </Button>
            }
          </ButtonContainer>
        </div>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <Header backgroundStyle="GREEN" />
        {loadingPasse ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <>
            {resultToRender()}
          </>
        )}
      </Container>
    </>
  );
};

export default PasseVerde;
