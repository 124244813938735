import React from 'react';
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { ButtonContainer } from './Components';
import ItemList from '../../components/ItemList/ItemList';
import {
  informationLinks,
  myDataLinks,
  servicesLinks
} from './data';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    zIndex: 10,
    backgroundImage: 'linear-gradient(#3893D0, #88CEFC)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '7.5%',
  },

  iconButton: {
    width: 50,
    height: 50,
    backgroundColor: '#FFFFFF',
    border: '1px solid #A2A2A2',
    transition: .2,
    '&:hover': {
      backgroundColor: '#f3ecec',
    }
  },

  buttonText: {
    fontWeight: 'bold',
    fontSize: 11,
  },
});

const SiteMap = () => {
  const classes = useStyles();
  let history = useHistory();

  const handleClick = () => {
    history.goBack();
  }

  return (
    <div className={classes.container}>
      <ItemList
        title="SERVICES"
        data={servicesLinks}
      />

      <ItemList
        title="MY_DATA"
        data={myDataLinks}
      />

      <ItemList
        title="INFORMATION"
        data={informationLinks}
      />

      <ButtonContainer>
        <IconButton onClick={handleClick} className={classes.iconButton}>
          <ArrowBack style={{ color: '#070592' }} />
        </IconButton>
      </ButtonContainer>
    </div>
  );

}

export default SiteMap;